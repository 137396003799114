.form-item{
  position: relative;
  margin-bottom: 15px
}
.form-item input{
  display: block;
  width: 400px;
  height: 40px;
  background: #ffffff;
  border: solid 1px #ccc;
  transition: all .3s ease;
  padding: 0 15px
}
.form-item input:focus{
  border-color: blue
}
.form-item label{
  position: absolute;
  cursor: text;
  z-index: 2;
  top: 13px;
  left: 10px;
  font-size: 12px;
  font-weight: bold;
  background: #fff;
  padding: 0 10px;
  color: #999;
  transition: all .3s ease
}
.form-item input:focus + label,
.form-item input:valid + label{
  font-size: 11px;
  top: -5px
}
.form-item input:focus + label{
  color: blue
}
#client-builder{
  background-color:#f7f7f7 !important;  
}
.feild span {
  font-size: 24px;
  padding-bottom: 20px;
  display: inline-block;
  text-transform: capitalize;
  font-weight: 400;
  color: #999;
}
.cb_submit {
  padding: 40px 0px;
}

.logoadjust{
width: 25px;
border-radius:50%;
} 

input[type="file"] {
  display: none;
}

.custom-file-upload {
  border: 1px solid #ccc;
  display: inline-block;
  padding: 6px 12px;
  cursor: pointer;
  width: 100%;
  text-align: center;
  border-radius: 5px;
}


.modal-content{
/* background-image: url('../../assets/img/clientmodal.png');  */
background-size: cover;
background-repeat: no-repeat;
background-position: bottom;
}
.card{
--bs-card-bg: none !important;
background-color: rgba(0,0,0,0) !important;
}
.editclr{
color:#57b056;
}
.sortclr {
color: #999;
font-weight: 100;
font-size: 13px;
}
.back_btn {
background-color: #17a2b8 !important;
outline: none;
border: none !important;
padding: 10px 35px !important;
margin-top: 40px;
opacity: 1 !important;
transition:.3s;
}
.back_btn:hover {
background-color: #37b1c5 !important;
}

.next_btn {
background-color: #17a2b8 !important;
outline: none;
border: none !important;
padding: 10px 35px !important;
margin-top: 40px;
margin-left: 30px;
color: #ffffff !important;
letter-spacing: .5px;
transition:.3s;
}

.next_btn :hover {
background-color: #37b1c5 !important;
}