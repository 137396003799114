 .register {
    height: 100%;
    width: 100%;
    padding: 50px 0px px;
 
    /* background-image: url("../../assets/img/login-register.jpg"); */
    background-size: cover;
    background-repeat: no-repeat;
    background-position: bottom;
    /* background: #43C6AC;   
background: -webkit-linear-gradient(to right, #F8FFAE, #43C6AC);  
background: linear-gradient(to right, #F8FFAE, #43C6AC);  */


/* background: #A1FFCE;   
background: -webkit-linear-gradient(to right, #FAFFD1, #A1FFCE); 
background: linear-gradient(to right, #FAFFD1, #A1FFCE);   */


/* background: #4CA1AF;  
background: -webkit-linear-gradient(to right, #C4E0E5, #4CA1AF);   
background: linear-gradient(to right, #C4E0E5, #4CA1AF);   */


background: #00C9FF;   
background: -webkit-linear-gradient(to right, #92FE9D, #00C9FF);   
background: linear-gradient(to right, #92FE9D, #00C9FF);  
 
}