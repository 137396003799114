.login-register {
  height: 100vh;
  width: 100%;
  padding: 83px 0px 83px;
}

  .login-box {
 
    margin: 50px auto 50px;
    background-color: #ffffff !important;
    border-radius: 10px;
    opacity: .9; 
}
  .login-box .footer {
    width: 100%;
    left: 0px;
    right: 0px; }
  .login-box .social {
    display: block;
    margin-bottom: 30px; }

#recoverform {
  display: none; }

.login-sidebar {
  padding: 0px;
  margin-top: 0px; }
  .login-sidebar .login-box {
    right: 0px;
    position: absolute;
    height: 100%; }
    .social_dev a {
      border: 1px solid #ccc;
      margin: 0px 10px;
      padding: 12px 0px;
      transition: .3s;
      width: 60px;
  }
  
  .social_dev a:hover {
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
  }
  .social_dev a svg {
    font-size: 20px; 
}
.social_dev a svg:hover {
  font-size: 20px; 
 
}

.divsocial1{
  color: #ffffff !important;
  background: #316FF6 !important;
}
.divsocial2{
  color: #ffffff !important;
  background: #db4a39  !important;
}
 .form-group{
  margin-bottom:0px !important;
 }
/* .signin_login {
  font-weight: 300;
  margin-bottom: 30px;
  font-size: 22px;
  text-align: center;
  font-weight: 500;
  margin: 20px 0px;
  text-transform: uppercase;
} */
.logo-width {
  width: 200px;
  margin: 30px 0px;
}

.label-float {
  position: relative;
  padding-top: 13px;
  margin-bottom: 30px;
}

.label-float input{
  border: 1px solid lightgrey;
  border-radius: 5px;
  outline: none;
  width: 100%;
  padding: 15px 20px;
  font-size: 16px;
  transition: all .1s linear;
  -webkit-transition: all .1s linear;
  -moz-transition: all .1s linear;
  -webkit-appearance:none;
}

.label-float input:focus{
  border: 2px solid #17a2b8;
}

.label-float input::placeholder{
  color:transparent;
}

.label-float label {
  pointer-events: none;
  position: absolute;
  top: calc(50% - 10px);
  left: 15px;
  transition: all .1s linear;
  -webkit-transition: all .1s linear;
  -moz-transition: all .1s linear;
  background-color: white;
  padding: 0px 5px;
  box-sizing: border-box;
  line-height: 30px;
  font-size: 13px;
}

.label-float input:required:invalid + label{
  color: red;
}
.label-float input:focus:required:invalid{
  border: 2px solid red;
}
.label-float input:required:invalid + label:before{
  content: '*';
}
.label-float input:focus + label,
.label-float input:not(:placeholder-shown) + label{
  font-size: 13px;
  top: 0;
  color: #3951b2;
}


.eyeopner{
  float: right;
  top: -50px;
  position: relative;
  background-color: rgba(0, 0, 0, 0);
  border: none;
  transition: 3s;
  padding: 0px;
  right: 10px;
}
.eyeopner:hover{
  background-color: rgba(0, 0, 0, 0); 
  border: none;
  box-shadow:none;
}
.eyeopner:focus{
  background-color: rgba(0, 0, 0, 0); 
  border: none;
  box-shadow:none;
}
.eyeopner:active{
  background-color: rgba(0, 0, 0, 0); 
  border: none;
  box-shadow:none;
}

.eyeclr{
  color:#000; 
}