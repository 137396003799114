.topbar {
    background: #2e333f;
    display:none;
}
.dropdown-toggle::after {
 color:#ffffff;
    margin: 0px !important;
    display: none;
}

.topbar .top-navbar .navbar-header .navbar-brand .light-logo {
    display: inline-block;
    color: rgba(255, 255, 255, 0.5);
}
.topbar .navbar-nav .nav-item > a.nav-link {
    color: rgba(255, 255, 255, 0.5);
}
.topbar .navbar-nav .nav-item > a.nav-link:hover,
.topbar .navbar-nav .nav-item > a.nav-link:focus {
    color: #ffffff !important;
}
.topbar .top-navbar .navbar-nav > .nav-item > span {
    color: #ffffff;
}

.logo-center .topbar .navbar-header {
    background: transparent;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.logo-center .topbar .top-navbar .navbar-header .navbar-brand .dark-logo {
    display: none;
}

.logo-center .topbar .top-navbar .navbar-header .navbar-brand .light-logo {
    display: inline-block;
    color: rgba(255, 255, 255, 0.8);
}

/*******************
  /*General Elements
  *******************/
a {
    color: #20aee3;
}

a.link:hover,
a.link:focus {
    color: #20aee3 !important;
}

.bg-theme {
    background-color: #20aee3 !important;
}

.pagination > .active > a,
.pagination > .active > span,
.pagination > .active > a:hover,
.pagination > .active > span:hover,
.pagination > .active > a:focus,
.pagination > .active > span:focus {
    background-color: #20aee3;
    border-color: #20aee3;
}

.right-sidebar .rpanel-title {
    background: #20aee3;
}

.stylish-table tbody tr:hover,
.stylish-table tbody tr.active {
    border-left: 4px solid #20aee3;
}

.text-themecolor {
    color: #20aee3 !important;
}

.profile-tab li a.nav-link.active,
.customtab li a.nav-link.active {
    border-bottom: 2px solid #20aee3;
    color: #20aee3;
}

.profile-tab li a.nav-link:hover,
.customtab li a.nav-link:hover {
    color: #20aee3;
}

/*******************
  /*Buttons
  *******************/
.btn-themecolor,
.btn-themecolor.disabled {
    background: #20aee3;
    color: #ffffff;
    border: 1px solid #20aee3;
}
.btn-themecolor:hover,
.btn-themecolor.disabled:hover {
    background: #20aee3;
    opacity: 0.7;
    border: 1px solid #20aee3;
}
.btn-themecolor.active,
.btn-themecolor:focus,
.btn-themecolor.disabled.active,
.btn-themecolor.disabled:focus {
    background: #028ee1;
}

/*******************
  /*sidebar navigation
  *******************/
.card-no-border .left-sidebar,
.card-no-border .sidebar-nav {
    background: #2e333f;
}

.mini-sidebar .sidebar-nav {
    background: transparent;
    display: inline-flex;
}
.mini-sidebar .sidebar-nav > ul > li > a i {
    color: #9199ae;
}

@media (min-width: 768px) {
    .mini-sidebar .sidebar-nav #sidebarnav > li > ul {
        background: #fff;
    }
    .mini-sidebar .sidebar-nav #sidebarnav > li:hover > a {
        background: rgba(0, 0, 0, 0);
        color: #ffffff;
        text-decoration: none;
    }
    .sidebar-nav #sidebarnav > li > ul {
        background: #263238;
    }
}

.sidebar-nav {
    background: #2e333f;
}

.user-profile .profile-text a {
    color: #767e91 !important;
}

.card-no-border .sidebar-footer {
    background: #fff;
}

.label-themecolor {
    background: #20aee3;
}

.sidebar-nav > ul > li.active > a {
    color: #ffffff;
    border-color: #ffffff;
}
.sidebar-nav > ul > li.active > a i {
    color: #ffffff;
}

.sidebar-nav ul li a {
    color: #ffffff;
}
.sidebar-nav ul li a.active,
.sidebar-nav ul li a:hover {
    color: #20aee3;
}
.sidebar-nav ul li a.active i,
.sidebar-nav ul li a:hover i {
    color: #20aee3;
}

.sidebar-nav ul li.nav-small-cap {
    color: #767e91 !important;
}
.svg_menu {
    padding: 17px 13px;
    color: #ffffff;
} 
.search-box {
    position: absolute;
    top: 56%;
    right: 18%;
    transform: translate(0%, -50%);

    height: 40px;
    border-radius: 40px;
}
.search-box:hover > .search-text {
    width: 240px;
    padding: 0 6px;
    color: #000;
    background-color: #ffffff;
    border-radius: 25px;
}
  .search-box:hover > .search-btn{
    color: black;
    
  }
  
  .search-btn {
    color: #e84118;
    float: right;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.4s;
    cursor: pointer;
    text-decoration: none;
    
  }
  .search-btn > i {
    font-size: 30px;
  }
  
  .search-text {
    border: none;
    background: none;
    outline: none;
    float: left;
    padding: 0;
    color: white;
    font-size: 14px;
    font-weight: normal;
    transition: 0.4s;
    line-height: 40px;
    width: 0px;
    
  /*   width: 250px; */ 
  }



  .upper_fixed {
    width: 100%;
    z-index: 99;
    position: fixed; 
}


  .dropbtn {
    padding: 16px;
    font-size: 16px;
    border: none;
  }
  
  .dropdown {
    position: relative;
    color: black;
float:right;
  }
  
  .dropdown-content {
    display:none;
    position: absolute;
    top: 50px;
    background-color: white;
    color: black;
    max-height: 200px;
    right: 0px;
    overflow: scroll;
    overflow-y: auto;
    overflow-x: hidden;
    padding-left: 0px;
    list-style-type: none;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 200;
}
  
  .color {
    color: black;
  }
  
  .dropdown-content li a {
    padding: 12px 16px;
    text-decoration: none;
    display: block;
  }
  
  .dropdown-content a:hover {
    color: #000;
 
}
  
  .dropdown:hover .dropdown-content {
    display: block;
  }
  
  .dropdown:hover .dropbtn {
    background-color: #2864DC;
  }