@import url('https://fonts.googleapis.com/css2?family=Source+Sans+3:ital,wght@0,200..900;1,200..900&display=swap');

* {
    outline: none;
}
body::-webkit-scrollbar {
    width: 5px;               /* width of the entire scrollbar */
  }
  
  body::-webkit-scrollbar-track {
    background: #2e333f;        /* color of the tracking area */
  }

  body::-webkit-scrollbar-thumb {
    background-color: #f7f7f7 ;    /* color of the scroll thumb */
    border-radius: 20px;       /* roundness of the scroll thumb */
    border: 2px solid #2e333f;  /* creates padding around scroll thumb */
  }
body {
    font-family: "Source Sans 3", sans-serif !important;
    margin: 0;
    overflow-x: hidden;
    color: #67757c;
    font-size: 14px;
    font-weight: 300;
}

html {
    position: relative;
    min-height: 100%;
    background:#f7f7f7 !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

a {
    color: #20aee3;
}

a:hover,
a:focus {
    text-decoration: none;
}

a.link {
    color: #455a64;
}
a.link:hover,
a.link:focus {
    color: #20aee3;
}

.img-responsive {
    width: 100%;
    height: auto;
    display: inline-block;
}

.img-rounded {
    border-radius: 4px;
}

html body .mdi:before,
html body .mdi-set {
    line-height: initial;
}

/*******************
Headings
*******************/
h1,
h2,
h3,
h4,
h5,
h6 {
    color: #455a64;
    font-family: "Source Sans 3", sans-serif !important;
    font-weight: 400;
}

h1 {
    line-height: 40px !important;
    font-size: 36px !important;
}

h2 {
    line-height: 36px !important;
    font-size: 24px !important;
}

h3 {
    line-height: 30px !important;
    font-size: 21px  !important;
}

h4 {
    line-height: 22px !important;
    font-size: 18px !important;
}

h5 {
    line-height: 18px !important;
    font-size: 16px !important;
    font-weight: 400 !important;
}

h6 {
    line-height: 16px !important;
    font-size: 14px !important;
    font-weight: 400 !important;
}

.display-5 {
    font-size: 3rem;
}

.display-6 {
    font-size: 36px;
}

.box {
    border-radius: 4px;
    padding: 10px;
}

html body .dl {
    display: inline-block;
}

html body .db {
    display: block;
}

.no-wrap td,
.no-wrap th {
    white-space: nowrap;
}

/*******************
Blockquote
*******************/
html body blockquote {
    border-left: 5px solid #20aee3;
    border: 1px solid rgba(120, 130, 140, 0.13);
    padding: 15px;
}

.clear {
    clear: both;
}

ol li {
    margin: 5px 0;
}

/*******************
Paddings
*******************/
html body .p-0 {
    padding: 0px;
}

html body .p-10 {
    padding: 10px;
}

html body .p-20 {
    padding: 20px;
}

html body .p-30 {
    padding: 30px;
}

html body .p-l-0 {
    padding-left: 0px;
}

html body .p-l-10 {
    padding-left: 10px;
}

html body .p-l-20 {
    padding-left: 20px;
}

html body .p-r-0 {
    padding-right: 0px;
}

html body .p-r-10 {
    padding-right: 10px;
}

html body .p-r-20 {
    padding-right: 20px;
}

html body .p-r-30 {
    padding-right: 30px;
}

html body .p-r-40 {
    padding-right: 40px;
}

html body .p-t-0 {
    padding-top: 0px;
}

html body .p-t-10 {
    padding-top: 10px;
}

html body .p-t-20 {
    padding-top: 20px;
}

html body .p-t-30 {
    padding-top: 30px;
}

html body .p-b-0 {
    padding-bottom: 0px;
}

html body .p-b-5 {
    padding-bottom: 5px;
}

html body .p-b-10 {
    padding-bottom: 10px;
}

html body .p-b-20 {
    padding-bottom: 20px;
}

html body .p-b-30 {
    padding-bottom: 30px;
}

html body .p-b-40 {
    padding-bottom: 40px;
}

/*******************
Margin
*******************/
html body .m-0 {
    margin: 0px;
}

html body .m-l-5 {
    margin-left: 5px;
}

html body .m-l-10 {
    margin-left: 10px;
}

html body .m-l-15 {
    margin-left: 15px;
}

html body .m-l-20 {
    margin-left: 20px;
}

html body .m-l-30 {
    margin-left: 30px;
}

html body .m-l-40 {
    margin-left: 40px;
}

html body .m-r-5 {
    margin-right: 5px;
}

html body .m-r-10 {
    margin-right: 10px;
}

html body .m-r-15 {
    margin-right: 15px;
}

html body .m-r-20 {
    margin-right: 20px;
}

html body .m-r-30 {
    margin-right: 30px;
}

html body .m-r-40 {
    margin-right: 40px;
}

html body .m-t-0 {
    margin-top: 0px;
}

html body .m-t-5 {
    margin-top: 5px;
}

html body .m-t-10 {
    margin-top: 10px;
}

html body .m-t-15 {
    margin-top: 15px;
}

html body .m-t-20 {
    margin-top: 20px;
}

html body .m-t-30 {
    margin-top: 30px;
}

html body .m-t-40 {
    margin-top: 40px;
}

html body .m-b-0 {
    margin-bottom: 0px;
}

html body .m-b-5 {
    margin-bottom: 5px;
}

html body .m-b-10 {
    margin-bottom: 10px;
}

html body .m-b-15 {
    margin-bottom: 15px;
}

html body .m-b-20 {
    margin-bottom: 20px;
}

html body .m-b-30 {
    margin-bottom: 30px;
}

html body .m-b-40 {
    margin-bottom: 40px;
}

/*******************
vertical alignment
*******************/
html body .vt {
    vertical-align: top;
}

html body .vm {
    vertical-align: middle;
}

html body .vb {
    vertical-align: bottom;
}

/*******************
Opacity
*******************/
.op-5 {
    opacity: 0.5;
}

.op-3 {
    opacity: 0.3;
}

/*******************
font weight
*******************/
html body .font-bold {
    font-weight: 700;
}

html body .font-normal {
    font-weight: normal;
}

html body .font-light {
    font-weight: 300;
}

html body .font-medium {
    font-weight: 500;
}

html body .font-16 {
    font-size: 16px;
}

html body .font-14 {
    font-size: 14px;
}

html body .font-10 {
    font-size: 10px;
}

html body .font-18 {
    font-size: 18px;
}

html body .font-20 {
    font-size: 20px;
}

/*******************
Border
*******************/
html body .b-0 {
    border: none !important;
}

html body .b-r {
    border-right: 1px solid rgba(120, 130, 140, 0.13);
}

html body .b-l {
    border-left: 1px solid rgba(120, 130, 140, 0.13);
}

html body .b-b {
    border-bottom: 1px solid rgba(120, 130, 140, 0.13);
}

html body .b-t {
    border-top: 1px solid rgba(120, 130, 140, 0.13);
}

html body .b-all {
    border: 1px solid rgba(120, 130, 140, 0.13) !important;
}

/*******************
Thumb size
*******************/
.thumb-sm {
    height: 32px;
    width: 32px;
}

.thumb-md {
    height: 48px;
    width: 48px;
}

.thumb-lg {
    height: 88px;
    width: 88px;
}

.hide {
    display: none;
}

.img-circle {
    border-radius: 100%; 
}

.radius {
    border-radius: 4px;
}

/* .modal-dialog-Tower {
    transition: transform .3s ease-out;
    transform: translate(97%, 0%) !important;
} */

.modal.fade:not(.in).right .modal-dialog {
    /* -webkit-transform: translate3d(25%, 0, 0) !important; */
    /* transform: translate3d(95%, 0, 0) !important; */
    display: inline-flex;
    height: 100vh !important;
}

/*******************
Text Colors
*******************/
.text-white {
    color: #ffffff !important;
}

.text-danger {
    color: #ff5c6c !important;
}

.text-muted {
    color: #bcc3d3 !important;
}

.text-warning {
    color: #ff9041 !important;
}

.text-success {
    color: #24d2b5 !important;
}

.text-info {
    color: #20aee3 !important;
}

.text-inverse {
    color: #2f3d4a !important;
}

html body .text-blue {
    color: #02bec9;
}

html body .text-purple {
    color: #7460ee;
}

html body .text-primary {
    color: #6772e5 !important;
}

html body .text-megna {
    color: #56c0d8;
}

html body .text-dark {
    color: #67757c;
}

html body .text-themecolor {
    color: #20aee3;
}

/*******************
Background Colors
*******************/
.bg-primary {
    background-color: #6772e5 !important;
}

.bg-success {
    background-color: #24d2b5 !important;
}

.bg-info {
    background-color: #20aee3 !important;
}

.bg-warning {
    background-color: #ff9041 !important;
}

.bg-danger {
    background-color: #ff5c6c !important;
}

html body .bg-megna {
    background-color: #56c0d8;
}

html body .bg-theme {
    background-color: #20aee3;
}

html body .bg-inverse {
    background-color: #2f3d4a;
}

html body .bg-purple {
    background-color: #7460ee;
}

html body .bg-light-part {
    background-color: rgba(0, 0, 0, 0.02);
}

html body .bg-light-primary {
    background-color: #f1effd;
}

html body .bg-light-success {
    background-color: #e8fdeb;
}

html body .bg-light-info {
    background-color: #cfecfe;
}

html body .bg-light-extra {
    background-color: #ebf3f5;
}

html body .bg-light-warning {
    background-color: #fff8ec;
}

html body .bg-light-danger {
    background-color: #f9e7eb;
}

html body .bg-light-inverse {
    background-color: #f6f6f6;
}

html body .bg-light {
    background-color: #e9edf2;
}

html body .bg-white {
    background-color: #ffffff;
}

/*******************
Rounds
*******************/
.round {
    line-height: 48px;
    color: #ffffff;
    width: 50px;
    height: 50px;
    display: inline-block;
    font-weight: 400;
    text-align: center;
    border-radius: 100%;
    background: #20aee3;
}
.round img {
    border-radius: 100%;
}

.round-lg {
    line-height: 65px;
    width: 60px;
    height: 60px;
    font-size: 30px;
}

.round.round-info {
    background: #20aee3;
}

.round.round-warning {
    background: #ff9041;
}

.round.round-danger {
    background: #ff5c6c;
}

.round.round-success {
    background: #24d2b5;
}

.round.round-primary {
    background: #6772e5;
}

/*******************
Labels
*******************/
.label {
    padding: 3px 10px;
    line-height: 13px;
    color: #ffffff;
    font-weight: 400;
    border-radius: 4px;
    font-size: 75%;
}

.label-rounded {
    border-radius: 60px;
}

.label-custom {
    background-color: #56c0d8;
}

.label-success {
    background-color: #24d2b5;
}

.label-info {
    background-color: #20aee3;
}

.label-warning {
    background-color: #ff9041;
}

.label-danger {
    background-color: #ff5c6c;
}

.label-megna {
    background-color: #56c0d8;
}

.label-primary {
    background-color: #6772e5;
}

.label-purple {
    background-color: #7460ee;
}

.label-red {
    background-color: #fb3a3a;
}

.label-inverse {
    background-color: #2f3d4a;
}

.label-default {
    background-color: #e9edf2;
}

.label-white {
    background-color: #ffffff;
}

.label-light-success {
    background-color: #e8fdeb;
    color: #24d2b5;
}

.label-light-info {
    background-color: #cfecfe;
    color: #20aee3;
}

.label-light-warning {
    background-color: #fff8ec;
    color: #ff9041;
}

.label-light-danger {
    background-color: #f9e7eb;
    color: #ff5c6c;
}

.label-light-megna {
    background-color: #e0f2f4;
    color: #56c0d8;
}

.label-light-primary {
    background-color: #f1effd;
    color: #6772e5;
}

.label-light-inverse {
    background-color: #f6f6f6;
    color: #2f3d4a;
}

/*******************
 Badge
******************/
.badge {
    font-weight: 400;
}

.badge-xs {
    font-size: 9px;
}

.badge-xs,
.badge-sm {
    -webkit-transform: translate(0, -2px);
    -ms-transform: translate(0, -2px);
    -o-transform: translate(0, -2px);
    transform: translate(0, -2px);
}

.badge-success {
    background-color: #24d2b5;
}

.badge-info {
    background-color: #20aee3;
}

.badge-primary {
    background-color: #6772e5;
}

.badge-warning {
    background-color: #ff9041;
    color: #ffffff;
}

.badge-danger {
    background-color: #ff5c6c;
}

.badge-purple {
    background-color: #7460ee;
}

.badge-red {
    background-color: #fb3a3a;
}

.badge-inverse {
    background-color: #2f3d4a;
}

/*******************
List-style-none
******************/
ul.list-style-none {
    margin: 0px;
    padding: 0px;
}
ul.list-style-none li {
    list-style: none;
}
ul.list-style-none li a {
    color: #67757c;
    padding: 8px 0px;
    display: block;
    text-decoration: none;
}
ul.list-style-none li a:hover {
    color: #20aee3;
}

/*******************
dropdown-item
******************/
.dropdown-item {
    padding: 8px 1rem;
    color: #67757c;
}

/*******************
Layouts
******************/
.card-no-border .card {
    border: 0px;
    border-radius: 0px;
    box-shadow: none;
}

.card-no-border .shadow-none {
    box-shadow: none;
}

.card-outline-danger,
.card-outline-info,
.card-outline-warning,
.card-outline-success,
.card-outline-primary {
    background: #ffffff;
}

.card-fullscreen {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    z-index: 9999;
    overflow: auto;
}

/*******************/
/*single column*/
/*******************/
.single-column .left-sidebar {
    display: none;
}

.single-column .page-wrapper {
    margin-left: 0px;
}

.fix-width {
    width: 100%;
    max-width: 1170px;
    margin: 0 auto;
}

.left-sidebar {
    position: relative;
    width: 240px;
    height: 100%;
    top: 0px;
    z-index: 20;
    background: #fff;
    box-shadow: 1px 0px 20px rgba(0, 0, 0, 0.08);
}
/* .modal-dialog{
    max-width:800px !important;
} */
.fix-sidebar .left-sidebar {
    position: fixed;
}

.fix-header.fix-sidebar .left-sidebar,
.fix-header .left-sidebar {
    padding-top: 100px;
}

.sidebar-nav .user-profile > a img {
    width: 30px;
    border-radius: 100%;
    margin-right: 10px;
}

.sidebar-nav .user-profile > ul {
    padding-left: 40px;
}

.scroll-sidebar {
    height: calc(100% - 30px);
}
.scroll-sidebar.ps .ps__scrollbar-y-rail {
    left: 2px;
    right: auto;
    background: none;
    width: 6px;
}

.collapse.in {
    display: block;
}

.sidebar-nav {
    background: #fff;
    padding: 0px;
}
.sidebar-nav ul {
    margin: 0px;
    padding: 0px;
}
.sidebar-nav ul li {
    list-style: none;
}
.sidebar-nav ul li a {
    color: #ffffff;
    padding: 8px 35px 8px 15px;
    display: block;
    font-size: 14px;
    font-weight: 400;
}
.sidebar-nav ul li a.active,
.sidebar-nav ul li a:hover {
    color: #20aee3;
}
.sidebar-nav ul li a.active i,
.sidebar-nav ul li a:hover i {
    color: #20aee3;
}
.sidebar-nav ul li a.active {
    font-weight: 500;
    color: #263238;
}
.sidebar-nav ul li ul {
    padding-left: 28px;
}
.sidebar-nav ul li ul li a {
    padding: 7px 35px 7px 15px;
}
.sidebar-nav ul li ul ul {
    padding-left: 15px;
}
.sidebar-nav ul li.nav-small-cap {
    font-size: 12px;
    margin-bottom: 0px;
    padding: 14px 14px 14px 20px;
    color: #263238;
    font-weight: 500;
    display: none;
}
.sidebar-nav ul li.nav-devider {
    height: 1px;
    background: rgba(120, 130, 140, 0.13);
    display: none;
    margin: 15px 0;
}
.sidebar-nav > ul > li > a {
    line-height: 35px;
    border-radius: 4px 4px 0 0;
}
.sidebar-nav > ul > li > a i {
    width: 31px;
    font-size: 22px;
    display: inline-block;
    vertical-align: middle;
    color: #787f91;
}
.sidebar-nav > ul > li > a .label {
    float: right;
    margin-top: 6px;
}
.sidebar-nav > ul > li > a.active {
    font-weight: 400;
    background: #242933;
    color: #26c6da;
}
.sidebar-nav > ul > li {
    margin-bottom: 0px;
}
.sidebar-nav > ul > li.active > a {
    color: #20aee3;
    font-weight: 500;
}
.sidebar-nav > ul > li.active > a i {
    color: #20aee3;
}
.sidebar-nav > ul > li.active:hover > a {
    color: #20aee3;
}
.sidebar-nav > ul > li.active:hover > a i {
    color: #20aee3;
}
.sidebar-nav .waves-effect {
    transition: none;
    -webkit-transition: none;
    -o-transition: none;
}

.sidebar-nav .has-arrow {
    position: relative;
}
.sidebar-nav .has-arrow::after {
    position: absolute;
    content: "";
    width: 7px;
    height: 7px;
    border-width: 1px 0 0 1px;
    border-style: solid;
    border-color: #8d97ad;
    right: 1em;
    -webkit-transform: rotate(135deg) translate(0, -50%);
    -ms-transform: rotate(135deg) translate(0, -50%);
    -o-transform: rotate(135deg) translate(0, -50%);
    transform: rotate(135deg) translate(0, -50%);
    -webkit-transform-origin: top;
    -ms-transform-origin: top;
    -o-transform-origin: top;
    transform-origin: top;
    top: 47%;
    -webkit-transition: all 0.3s ease-out;
    -o-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;
}

.sidebar-nav .active > .has-arrow::after,
.sidebar-nav li > .has-arrow.active::after,
.sidebar-nav .has-arrow[aria-expanded="true"]::after {
    -webkit-transform: rotate(-135deg) translate(0, -50%);
    -ms-transform: rotate(-135deg) translate(0, -50%);
    -o-transform: rotate(-135deg) translate(0, -50%);
    top: 45%;
    width: 7px;
    transform: rotate(-135deg) translate(0, -50%);
}

/****************
When click on sidebar toggler and also for tablet
*****************/
@media (min-width: 768px) {
    .left-sidebar {
        width: 100%;
        height: auto;
    }
    .mini-sidebar .sidebar-nav #sidebarnav li {
        position: relative;
    }
    .mini-sidebar .sidebar-nav #sidebarnav li.nav-small-cap {
        display: none;
    }
    .mini-sidebar .sidebar-nav #sidebarnav li.two-column > ul {
        width: 400px;
    }
    .mini-sidebar .sidebar-nav #sidebarnav li.two-column > ul > li {
        width: 49%;
        vertical-align: top;
        display: inline-block;
    }
    .mini-sidebar .sidebar-nav #sidebarnav li.three-column > ul {
        width: 550px;
    }
    .mini-sidebar .sidebar-nav #sidebarnav li.three-column > ul > li {
        width: 32.5%;
        vertical-align: top;
        display: inline-block;
    }
    .mini-sidebar .sidebar-nav #sidebarnav > li > ul {
        position: absolute;
        top: 70px;
        width: 220px;
        box-shadow: 0px 7px 20px rgba(0, 0, 0, 0.08);
        padding-bottom: 10px;
        z-index: 1001;
        background: #edf0f5;
        display: none;
        padding-left: 1px;
    }
    .mini-sidebar .sidebar-nav #sidebarnav > li:hover > ul {
        height: auto !important;
        overflow: auto;
    }
    .mini-sidebar .sidebar-nav #sidebarnav > li:hover > ul,
    .mini-sidebar .sidebar-nav #sidebarnav > li:hover > ul.collapse {
        display: block;
        max-height: 400px;
    }
    .mini-sidebar .sidebar-nav #sidebarnav > li > a.has-arrow:after {
        display: none;
    }
    .mini-sidebar .scroll-sidebar {
        padding-bottom: 0px;
        position: relative;
        margin: 0 auto;
        max-width: 1280px;
        padding: 0 15px;
    }
    .mini-sidebar .sidebar-nav {
        background: transparent;
        min-height: 55px;
    }
    .mini-sidebar .sidebar-nav > ul > li {
        margin-Top: 0px;
        float: left;
        margin-right: 10px;
    }
    .mini-sidebar .sidebar-nav > ul > li ul li a {
        padding: 10px 15px;
        color:#000;
    }
    .mini-sidebar .sidebar-nav > ul li:last-child ul {
        right: 0px;
    }
    .mini-sidebar .sidebar-nav #sidebarnav > li > a {
        padding: 10px 15px;
    }
    .mini-sidebar .sidebar-nav #sidebarnav > li:hover > a {
        background: #edf0f5;
    }
    .mini-sidebar .sidebar-nav #sidebarnav > li:hover > a .hide-menu {
        display: inline;
    }
    .mini-sidebar .sidebar-nav #sidebarnav > li:hover > a .label {
        display: none;
    }
}

@media (min-width: 768px) and (max-width: 1024px) {
    .sidebar-nav > ul > li > a i {
        display: none;
    }
    .mini-sidebar .sidebar-nav #sidebarnav > li > a {
        padding: 20px 15px;
    }
}

@media (max-width: 767px) {
    .mini-sidebar .left-sidebar {
        position: fixed;
        padding-top: 70px;
    }
    .mini-sidebar .left-sidebar {
        left: -240px;
    }
    .mini-sidebar.show-sidebar .left-sidebar {
        left: 0px;
    }
    .topbar .top-navbar {
        padding: 0px;
    }
    .sidebar-nav ul li.nav-small-cap {
        display: block;
    }
}

#main-wrapper {
    width: 100%;
    overflow: hidden;
}

.boxed #main-wrapper {
    width: 100%;
    max-width: 1300px;
    margin: 0 auto;
    box-shadow: 0 0 60px rgba(0, 0, 0, 0.1);
}
.boxed #main-wrapper .sidebar-footer {
    position: absolute;
}
.boxed #main-wrapper .topbar {
    max-width: 1300px;
}
.boxed #main-wrapper .footer {
    display: none;
}

.page-wrapper {
    background: #f6f9fa;
    padding-bottom: 60px;
    position: relative;
    padding-top: 30px;
}

.container-fluid {

    max-width: 1280px;
    margin: 0 auto;
    position: relative;
}
.OneClr_apply{
background-color: #f3f4f7;
padding-top: 40px;
}
.HeadingTop {
    margin-top: 28px;
    height: 82px;
    align-items: center;
    display: grid;
    align-content: baseline;
}
.HeadingTop h3 {
    font-size: 30px !important;
    font-weight: 400;
}
  
.card .card-subtitle {
    font-weight: 300;
    margin-bottom: 15px;
    color: #bcc3d3;
}
.card .card-title {
    position: relative;
}

/*******************
 Topbar
*******************/
.fix-header .topbar {
    position: fixed;
    width: 100%;
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.05);
}

.fix-header .page-wrapper {
    padding-top: 0px;
}

.fix-header.fix-sidebar .page-wrapper {
    padding-top: 175px;
}

.fix-header .right-side-panel {
    top: 0;
    height: 100%;
}

.topbar {
    position: relative;
    z-index: 50;
}
.topbar .navbar-collapse {
    padding: 0 10px;
}
.topbar .top-navbar {
    min-height: 100px;
    max-width: 1280px;
    margin: 0 auto;
    padding: 0px 0px 0px 16px;
}
.topbar .top-navbar .dropdown-toggle::after {
    display: none;
}
.topbar .top-navbar .navbar-header {
    line-height: 55px;
    padding-left: 10px;
}
.topbar .top-navbar .navbar-header .navbar-brand {
    margin-right: 0px;
    padding-bottom: 0px;
    padding-top: 0px;
}

.topbar .top-navbar .navbar-header .navbar-brand b {
    line-height: 70px;
    display: inline-block;
}
.topbar .top-navbar .navbar-nav > .nav-item > .nav-link {
    padding-left: 15px;
    padding-right: 15px;
    font-size: 17px;
    line-height: 54px;
}
.topbar .top-navbar .navbar-nav > .nav-item > span {
    line-height: 65px;
    font-size: 24px;
    font-weight: 500;
    color: #ffffff;
    padding: 0 10px;
}
.topbar .top-navbar .navbar-nav > .nav-item.show {
    background: rgba(0, 0, 0, 0.05);
}
.topbar .profile-pic {
    width: 30px;
    border-radius: 100%;
}
.topbar .dropdown-menu {
    box-shadow: 0 3px 12px rgba(0, 0, 0, 0.05);
    -webkit-box-shadow: 0 3px 12px rgba(0, 0, 0, 0.05);
    -moz-box-shadow: 0 3px 12px rgba(0, 0, 0, 0.05);
    border-color: rgba(120, 130, 140, 0.13);
}
.topbar .dropdown-menu .dropdown-item {
    padding: 7px 0.5rem;
}
.topbar ul.dropdown-user {
    padding: 0px;
    min-width: 270px;
}
.topbar ul.dropdown-user li {
    list-style: none;
    padding: 0px;
    margin: 0px;
}
.topbar ul.dropdown-user li.divider {
    height: 1px;
    margin: 9px 0;
    overflow: hidden;
    background-color: rgba(120, 130, 140, 0.13);
}
.topbar ul.dropdown-user li .dw-user-box {
    padding: 10px 15px;
}
.topbar ul.dropdown-user li .dw-user-box .u-img {
    width: 70px;
    display: inline-block;
    vertical-align: top;
}
.topbar ul.dropdown-user li .dw-user-box .u-img img {
    width: 100%;
    border-radius: 5px;
}
.topbar ul.dropdown-user li .dw-user-box .u-text {
    display: inline-block;
    padding-left: 10px;
}
.topbar ul.dropdown-user li .dw-user-box .u-text h4 {
    margin: 0px;
    font-size: 15px;
}
.topbar ul.dropdown-user li .dw-user-box .u-text p {
    margin-bottom: 2px;
    font-size: 12px;
}
.topbar ul.dropdown-user li .dw-user-box .u-text .btn {
    color: #ffffff;
    padding: 5px 10px;
    display: inline-block;
}
.topbar ul.dropdown-user li .dw-user-box .u-text .btn:hover {
    background: #e6294b;
}
.topbar ul.dropdown-user li a {
    padding: 9px 15px;
    display: block;
    color: #67757c;
}
.topbar ul.dropdown-user li a:hover {
    background: #e9edf2;
    color: #20aee3;
    text-decoration: none;
}

.search-box .app-search {
    position: absolute;
    margin: 0px;
    display: block;
    z-index: 110;
    width: 100%;
    top: -1px;
    box-shadow: 2px 0px 10px rgba(0, 0, 0, 0.2);
    display: block;
    left: 0px;
}
.search-box .app-search input {
    width: 100.5%;
    padding: 25px 40px 25px 20px;
    border-radius: 0px;
    font-size: 17px;
    transition: 0.5s ease-in;
}
.search-box .app-search input:focus {
    border-color: #ffffff;
}
.search-box .app-search .srh-btn {
    position: absolute;
    top: 23px;
    cursor: pointer;
    background: #ffffff;
    width: 15px;
    height: 15px;
    right: 20px;
    font-size: 14px;
}

.mini-sidebar .top-navbar .navbar-header {
    width: 70px;
    text-align: center;
    -webkit-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-left: 0px;
}

.logo-center .top-navbar .navbar-header {
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
}

/*******************
Notify
*******************/
.notify {
    position: relative;
    top: -28px;
    right: 6px;
}
.notify .heartbit {
    position: absolute;
    top: -20px;
    right: -4px;
    height: 25px;
    width: 25px;
    z-index: 10;
    border: 5px solid #ff5c6c;
    border-radius: 70px;
    -moz-animation: heartbit 1s ease-out;
    -moz-animation-iteration-count: infinite;
    -o-animation: heartbit 1s ease-out;
    -o-animation-iteration-count: infinite;
    -webkit-animation: heartbit 1s ease-out;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
   
}


  @keyframes heartbit {
    0% {
        -webkit-transform: scale(0);
        opacity: 0;
    }
    25% {
        -webkit-transform: scale(0.1);
        opacity: 0.1;
    }
    50% {
        -webkit-transform: scale(0.5);
        opacity: 0.3;
    }
    75% {
        -webkit-transform: scale(0.8);
        opacity: 0.5;
    }
    100% {
        -webkit-transform: scale(1);
        opacity: 0;
    }
}

.notify .point {
    width: 6px;
    height: 6px;
    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    border-radius: 30px;
    background-color: #ff5c6c;
    position: absolute;
    right: 6px;
    top: -10px;
}

/* @-moz-keyframes heartbit {
    0% {
        -moz-transform: scale(0);
        opacity: 0;
    }
    25% {
        -moz-transform: scale(0.1);
        opacity: 0.1;
    }
    50% {
        -moz-transform: scale(0.5);
        opacity: 0.3;
    }
    75% {
        -moz-transform: scale(0.8);
        opacity: 0.5;
    }
    100% {
        -moz-transform: scale(1);
        opacity: 0;
    }
} */

@-webkit-keyframes heartbit {
    0% {
        -webkit-transform: scale(0);
        opacity: 0;
    }
    25% {
        -webkit-transform: scale(0.1);
        opacity: 0.1;
    }
    50% {
        -webkit-transform: scale(0.5);
        opacity: 0.3;
    }
    75% {
        -webkit-transform: scale(0.8);
        opacity: 0.5;
    }
    100% {
        -webkit-transform: scale(1);
        opacity: 0;
    }
}

/*******************
 Horizontal-navabar
*******************/
.hdr-nav-bar .navbar {
    background: #ffffff;
    border-top: 1px solid rgba(120, 130, 140, 0.13);
    padding: 0 15px;
}
.hdr-nav-bar .navbar .navbar-nav > li {
    padding: 0 10px;
}
.hdr-nav-bar .navbar .navbar-nav > li > a {
    padding: 25px 5px;
    transition: 0.2s ease-in;
    border-bottom: 2px solid transparent;
}
.hdr-nav-bar .navbar .navbar-nav > li.active > a,
.hdr-nav-bar .navbar .navbar-nav > li:hover > a,
.hdr-nav-bar .navbar .navbar-nav > li > a:focus {
    border-color: #20aee3;
}
.hdr-nav-bar .navbar .navbar-nav .dropdown-toggle:after {
    display: none;
}
.hdr-nav-bar .navbar .navbar-brand {
    padding: 20px 5px;
}
.hdr-nav-bar .navbar .navbar-toggler {
    border: 0px;
    cursor: pointer;
}
.hdr-nav-bar .navbar .custom-select {
    border-radius: 60px;
    font-size: 14px;
    padding: 0.375rem 40px 0.375rem 18px;
}
.hdr-nav-bar .navbar .call-to-act {
    padding: 15px 0;
}

/*******************
 Breadcrumb and page title
*******************/
.page-titles {
    /* background: #f6f9fa; */
    margin-bottom: 20px;
    padding: 0px;
}
.page-titles h3 {
    margin-bottom: 0px;
    margin-top: 0px;
}
.page-titles .breadcrumb {
    padding: 0px;
    margin-bottom: 0px;
    background: transparent;
    font-size: 12px;
    font-weight: 500;
}
.page-titles .breadcrumb li {
    margin-top: 0px;
    margin-bottom: 0px;
}
.page-titles .breadcrumb .breadcrumb-item + .breadcrumb-item::before {
    content: "\e649";
    font-family: themify;
    color: #a6b7bf;
    font-size: 11px;
}
.page-titles .breadcrumb .breadcrumb-item.active {
    color: #263238;
}

.p-relative {
    position: relative;
}

.lstick {
    width: 2px;
    background: #20aee3;
    height: 30px;
    margin-left: -20px;
    margin-right: 18px;
    display: inline-block;
    vertical-align: middle;
}

/*******************
 Right side toggle
*******************/
@-webkit-keyframes rotate {
    from {
        -webkit-transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(360deg);
    }
}

@-moz-keyframes rotate {
    from {
        -moz-transform: rotate(0deg);
    }
    to {
        -moz-transform: rotate(360deg);
    }
}

@keyframes rotate {
    from {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

.right-side-toggle {
    position: relative;
}

.right-side-toggle i {
    -webkit-transition-property: -webkit-transform;
    -webkit-transition-duration: 1s;
    -moz-transition-property: -moz-transform;
    -moz-transition-duration: 1s;
    transition-property: -webkit-transform;
    transition-property: transform;
    transition-property: transform, -webkit-transform;
    transition-duration: 1s;
    -webkit-animation-name: rotate;
    -webkit-animation-duration: 2s;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    -moz-animation-name: rotate;
    -moz-animation-duration: 2s;
    -moz-animation-iteration-count: infinite;
    -moz-animation-timing-function: linear;
    animation-name: rotate;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    position: absolute;
    top: 18px;
    left: 18px;
}

.right-sidebar {
    position: fixed;
    right: -240px;
    width: 240px;
    display: none;
    z-index: 1100;
    background: #ffffff;
    top: 0px;
    padding-bottom: 20px;
    height: 100%;
    box-shadow: 5px 1px 40px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;
}
.right-sidebar .rpanel-title {
    display: block;
    padding: 24px 20px;
    color: #ffffff;
    text-transform: uppercase;
    font-size: 15px;
    background: #20aee3;
}
.right-sidebar .rpanel-title span {
    float: right;
    cursor: pointer;
    font-size: 11px;
}
.right-sidebar .rpanel-title span:hover {
    color: #ffffff;
}
.right-sidebar .r-panel-body {
    padding: 20px;
}
.right-sidebar .r-panel-body ul {
    margin: 0px;
    padding: 0px;
}
.right-sidebar .r-panel-body ul li {
    list-style: none;
    padding: 5px 0;
}

.shw-rside {
    right: 0px;
    width: 240px;
    display: block;
}

.chatonline img {
    margin-right: 10px;
    float: left;
    width: 30px;
}

.chatonline li a {
    padding: 13px 0;
    float: left;
    width: 100%;
}
.chatonline li a span {
    color: #67757c;
}
.chatonline li a span small {
    display: block;
    font-size: 10px;
}

/*******************
 Right side toggle
*******************/
ul#themecolors {
    display: block;
}
ul#themecolors li {
    display: inline-block;
}
ul#themecolors li:first-child {
    display: block;
}
ul#themecolors li a {
    width: 50px;
    height: 50px;
    display: inline-block;
    margin: 5px;
    color: transparent;
    position: relative;
}
ul#themecolors li a.working:before {
    content: "\f00c";
    font-family: "FontAwesome";
    font-size: 18px;
    line-height: 50px;
    width: 50px;
    height: 50px;
    position: absolute;
    top: 0;
    left: 0;
    color: #ffffff;
    text-align: center;
}

.default-theme {
    background: #bcc3d3;
}

.green-theme {
    background: #24d2b5;
}

.yellow-theme {
    background: #ff9041;
}

.red-theme {
    background: #ff5c6c;
}

.blue-theme {
    background: #20aee3;
}

.purple-theme {
    background: #7460ee;
}

.megna-theme {
    background: #56c0d8;
}

.default-dark-theme {
    background: #263238;
    /* Old browsers */
    background: -moz-linear-gradient(left, #263238 0%, #263238 23%, #bcc3d3 23%, #bcc3d3 99%);
    /* FF3.6-15 */
    background: -webkit-linear-gradient(left, #263238 0%, #263238 23%, #bcc3d3 23%, #bcc3d3 99%);
    /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to right, #263238 0%, #263238 23%, #bcc3d3 23%, #bcc3d3 99%);
}

.green-dark-theme {
    background: #263238;
    /* Old browsers */
    background: -moz-linear-gradient(left, #263238 0%, #263238 23%, #24d2b5 23%, #24d2b5 99%);
    /* FF3.6-15 */
    background: -webkit-linear-gradient(left, #263238 0%, #263238 23%, #00c292 23%, #24d2b5 99%);
    /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to right, #263238 0%, #263238 23%, #24d2b5 23%, #24d2b5 99%);
}

.yellow-dark-theme {
    background: #263238;
    /* Old browsers */
    background: -moz-linear-gradient(left, #263238 0%, #263238 23%, #ff5c6c 23%, #ff5c6c 99%);
    /* FF3.6-15 */
    background: -webkit-linear-gradient(left, #263238 0%, #263238 23%, #ff5c6c 23%, #ff5c6c 99%);
    /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to right, #263238 0%, #263238 23%, #ff5c6c 23%, #ff5c6c 99%);
}

.blue-dark-theme {
    background: #263238;
    /* Old browsers */
    background: -moz-linear-gradient(left, #263238 0%, #263238 23%, #20aee3 23%, #20aee3 99%);
    /* FF3.6-15 */
    background: -webkit-linear-gradient(left, #263238 0%, #263238 23%, #20aee3 23%, #20aee3 99%);
    /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to right, #263238 0%, #263238 23%, #20aee3 23%, #20aee3 99%);
}

.purple-dark-theme {
    background: #263238;
    /* Old browsers */
    background: -moz-linear-gradient(left, #263238 0%, #263238 23%, #7460ee 23%, #7460ee 99%);
    /* FF3.6-15 */
    background: -webkit-linear-gradient(left, #263238 0%, #263238 23%, #7460ee 23%, #7460ee 99%);
    /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to right, #263238 0%, #263238 23%, #7460ee 23%, #7460ee 99%);
}

.megna-dark-theme {
    background: #263238;
    /* Old browsers */
    background: -moz-linear-gradient(left, #263238 0%, #263238 23%, #56c0d8 23%, #56c0d8 99%);
    /* FF3.6-15 */
    background: -webkit-linear-gradient(left, #263238 0%, #263238 23%, #56c0d8 23%, #56c0d8 99%);
    /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to right, #263238 0%, #263238 23%, #56c0d8 23%, #56c0d8 99%);
}

.red-dark-theme {
    background: #263238;
    /* Old browsers */
    background: -moz-linear-gradient(left, #263238 0%, #263238 23%, #ff5c6c 23%, #ff5c6c 99%);
    /* FF3.6-15 */
    background: -webkit-linear-gradient(left, #263238 0%, #263238 23%, #ff5c6c 23%, #ff5c6c 99%);
    /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to right, #263238 0%, #263238 23%, #ff5c6c 23%, #ff5c6c 99%);
}

/*******************
 Footer
*******************/
.footer {
    bottom: 0;
    color: #67757c;
    left: 0px;
    padding: 17px 15px;
    position: absolute;
    right: 0;
    border-top: 1px solid rgba(120, 130, 140, 0.13);
    background: #ffffff;
    text-align: center;
}

/*******************
 scrollbar
*******************/
.slimScrollBar {
    z-index: 10 !important;
}

/*******************
Megamenu
******************/
.mega-dropdown {
    position: static;
    width: 100%;
}
.mega-dropdown .dropdown-menu {
    width: 100%;
    padding: 30px;
    margin-top: 0px;
}
.mega-dropdown ul {
    padding: 0px;
}
.mega-dropdown ul li {
    list-style: none;
}
.mega-dropdown .carousel-item .container {
    padding: 0px;
}
.mega-dropdown .nav-accordion .card {
    margin-bottom: 1px;
}
.mega-dropdown .nav-accordion .card-header {
    background: #ffffff;
}
.mega-dropdown .nav-accordion .card-header h5 {
    margin: 0px;
}
.mega-dropdown .nav-accordion .card-header h5 a {
    text-decoration: none;
    color: #67757c;
}

/*******************
Wave Effects
*******************/
.waves-effect {
    position: relative;
    cursor: pointer;
    display: inline-block;
    overflow: hidden;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-tap-highlight-color: transparent;
    vertical-align: middle;
    z-index: 1;
    will-change: opacity, transform;
    -webkit-transition: all 0.1s ease-out;
    -moz-transition: all 0.1s ease-out;
    -o-transition: all 0.1s ease-out;
    -ms-transition: all 0.1s ease-out;
    transition: all 0.1s ease-out;
}
.waves-effect .waves-ripple {
    position: absolute;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    margin-top: -10px;
    margin-left: -10px;
    opacity: 0;
    background: rgba(0, 0, 0, 0.2);
    -webkit-transition: all 0.7s ease-out;
    -moz-transition: all 0.7s ease-out;
    -o-transition: all 0.7s ease-out;
    -ms-transition: all 0.7s ease-out;
    transition: all 0.7s ease-out;
    -webkit-transition-property: -webkit-transform, opacity;
    -moz-transition-property: -moz-transform, opacity;
    -o-transition-property: -o-transform, opacity;
    transition-property: opacity, -webkit-transform;
    transition-property: transform, opacity;
    transition-property: transform, opacity, -webkit-transform;
    -webkit-transform: scale(0);
    -moz-transform: scale(0);
    -ms-transform: scale(0);
    -o-transform: scale(0);
    transform: scale(0);
    pointer-events: none;
}

.waves-effect.waves-light .waves-ripple {
    background-color: rgba(255, 255, 255, 0.45);
}

.waves-effect.waves-red .waves-ripple {
    background-color: rgba(244, 67, 54, 0.7);
}

.waves-effect.waves-yellow .waves-ripple {
    background-color: rgba(255, 235, 59, 0.7);
}

.waves-effect.waves-orange .waves-ripple {
    background-color: rgba(255, 152, 0, 0.7);
}

.waves-effect.waves-purple .waves-ripple {
    background-color: rgba(156, 39, 176, 0.7);
}

.waves-effect.waves-green .waves-ripple {
    background-color: rgba(76, 175, 80, 0.7);
}

.waves-effect.waves-teal .waves-ripple {
    background-color: rgba(0, 150, 136, 0.7);
}

html body .waves-notransition {
    -webkit-transition: none;
    -moz-transition: none;
    -o-transition: none;
    -ms-transition: none;
    transition: none;
}

.waves-circle {
    -webkit-transform: translateZ(0);
    -moz-transform: translateZ(0);
    -ms-transform: translateZ(0);
    -o-transform: translateZ(0);
    transform: translateZ(0);
    text-align: center;
    width: 2.5em;
    height: 2.5em;
    line-height: 2.5em;
    border-radius: 50%;
    -webkit-mask-image: none;
}

.waves-input-wrapper {
    border-radius: 0.2em;
    vertical-align: bottom;
}
.waves-input-wrapper .waves-button-input {
    position: relative;
    top: 0;
    left: 0;
    z-index: 1;
}

.waves-block {
    display: block;
}

/*******************
List inline
*******************/
ul.list-inline li {
    display: inline-block;
    padding: 0 8px;
}

/*******************
Table td vertical middle
*******************/
.vm.table td,
.vm.table th {
    vertical-align: middle;
}

.no-th-brd.table th {
    border: 0px;
}

.table.no-border tbody td {
    border: 0px;
}

/*******************
Other comon js for all pages
*******************/
.calendar-events {
    padding: 8px 10px;
    border: 1px solid #ffffff;
    cursor: move;
}
.calendar-events:hover {
    border: 1px dashed rgba(120, 130, 140, 0.13);
}
.calendar-events i {
    margin-right: 8px;
}

.table-responsive {
    display: block;
    width: 100%;
    overflow-x: auto;
    -ms-overflow-style: -ms-autohiding-scrollbar;
}

.single-column .left-sidebar {
    display: none;
}

.single-column .page-wrapper {
    margin-left: 0px !important;
    padding-top: 70px !important;
}

.profile-tab li a.nav-link,
.customtab li a.nav-link {
    border: 0px;
    padding: 15px 20px;
    color: #67757c;
}
.profile-tab li a.nav-link.active,
.customtab li a.nav-link.active {
    border-bottom: 2px solid #20aee3;
    color: #20aee3;
}
.profile-tab li a.nav-link:hover,
.customtab li a.nav-link:hover {
    color: #20aee3;
}

html body .jqstooltip,
html body .flotTip {
    width: auto !important;
    height: auto !important;
    background: #263238;
    color: #ffffff;
    padding: 5px 10px;
}

body .jqstooltip {
    border-color: transparent;
    border-radius: 60px;
}

/*******************
Notification page Jquery toaster
******************/
.jq-icon-info {
    background-color: #20aee3;
    color: #ffffff;
}

.jq-icon-success {
    background-color: #24d2b5;
    color: #ffffff;
}

.jq-icon-error {
    background-color: #ff5c6c;
    color: #ffffff;
}

.jq-icon-warning {
    background-color: #ff9041;
    color: #ffffff;
}

.up-img {
    background-size: cover;
    background-position: center center;
    min-height: 340px;
}

/*
Template Name: Admin pro Admin
Author: Wrappixel
Email: niravjoshi87@gmail.com
File: scss
*/
/*
Template Name: Admin Pro Admin
Author: Wrappixel
Email: niravjoshi87@gmail.com
File: scss
*/
/*Theme Colors*/
/*bootstrap Color*/
/*Light colors*/
/*Normal Color*/
/*Extra Variable*/
/*******************
Pagination
*******************/
.pagination > li:first-child > a,
.pagination > li:first-child > span {
    border-bottom-left-radius: 4px;
    border-top-left-radius: 4px;
}

.pagination > li:last-child > a,
.pagination > li:last-child > span {
    border-bottom-right-radius: 4px;
    border-top-right-radius: 4px;
}

.pagination > li > a,
.pagination > li > span {
    color: #263238;
}

.pagination > li > a:hover,
.pagination > li > span:hover,
.pagination > li > a:focus,
.pagination > li > span:focus {
    background-color: #e9edf2;
}

.pagination-split li {
    margin-left: 5px;
    display: inline-block;
    float: left;
}

.pagination-split li:first-child {
    margin-left: 0;
}

.pagination-split li a {
    -moz-border-radius: 4px;
    -webkit-border-radius: 4px;
    border-radius: 4px;
}

.pagination > .active > a,
.pagination > .active > span,
.pagination > .active > a:hover,
.pagination > .active > span:hover,
.pagination > .active > a:focus,
.pagination > .active > span:focus {
    background-color: #20aee3;
    border-color: #20aee3;
}

.pager li > a,
.pager li > span {
    -moz-border-radius: 4px;
    -webkit-border-radius: 4px;
    border-radius: 4px;
    color: #263238;
}

/*******************
Pagination
******************/
.pagination-circle li.active a {
    background: #24d2b5;
}

.pagination-circle li a {
    width: 40px;
    height: 40px;
    background: #e9edf2;
    border: 0px;
    text-align: center;
    border-radius: 100%;
}
.pagination-circle li a:first-child,
.pagination-circle li a:last-child {
    border-radius: 100%;
}
.pagination-circle li a:hover {
    background: #24d2b5;
    color: #ffffff;
}

.pagination-circle li.disabled a {
    background: #e9edf2;
    color: rgba(120, 130, 140, 0.13);
}

/*
Template Name: Admin pro Admin
Author: Wrappixel
Email: niravjoshi87@gmail.com
File: scss
*/
/*
Template Name: Admin Pro Admin
Author: Wrappixel
Email: niravjoshi87@gmail.com
File: scss
*/
/*Theme Colors*/
/*bootstrap Color*/
/*Light colors*/
/*Normal Color*/
/*Extra Variable*/
/*******************
Buttons
******************/
.btn {
    padding: 7px 12px;
    cursor: pointer;
}
.btn:hover {
    opacity: 0.8;
}
.btn:focus {
    box-shadow: none;
}

.btn-group label {
    color: #ffffff !important;
    margin-bottom: 0px;
}
.btn-group label.btn-secondary {
    color: #67757c !important;
}

.btn-lg {
    padding: 0.75rem 1.5rem;
    font-size: 1.25rem;
}

.btn-md {
    padding: 12px 55px;
    font-size: 16px;
}

.btn-circle {
    border-radius: 100%;
    width: 40px;
    height: 40px;
    padding: 10px;
}

.btn-circle.btn-sm {
    width: 35px;
    height: 35px;
    padding: 8px 10px;
    font-size: 14px;
}

.btn-circle.btn-lg {
    width: 50px;
    height: 50px;
    padding: 14px 15px;
    font-size: 18px;
    line-height: 23px;
}

.btn-circle.btn-xl {
    width: 70px;
    height: 70px;
    padding: 14px 15px;
    font-size: 24px;
}

.btn-sm {
    padding: 0.25rem 0.5rem;
    font-size: 12px;
}

.btn-xs {
    padding: 0.25rem 0.5rem;
    font-size: 10px;
}

.button-list button,
.button-list a {
    margin: 5px 12px 5px 0;
}

.btn-outline {
    color: inherit;
    background-color: transparent;
    transition: all 0.5s;
}

.btn-rounded {
    border-radius: 60px;
    padding: 7px 18px;
}
.btn-rounded.btn-lg {
    padding: 0.75rem 1.5rem;
}
.btn-rounded.btn-sm {
    padding: 0.25rem 0.5rem;
    font-size: 12px;
}
.btn-rounded.btn-xs {
    padding: 0.25rem 0.5rem;
    font-size: 10px;
}
.btn-rounded.btn-md {
    padding: 12px 35px;
    font-size: 16px;
}

.btn-secondary,
.btn-secondary.disabled {
    box-shadow: 0 2px 2px 0 rgba(169, 169, 169, 0.14), 0 3px 1px -2px rgba(169, 169, 169, 0.2), 0 1px 5px 0 rgba(169, 169, 169, 0.12);
    transition: 0.2s ease-in;
    background-color: #ffffff;
    color: #67757c;
    border-color: #cccccc;
}
.btn-secondary:hover,
.btn-secondary.disabled:hover {
    box-shadow: 0 14px 26px -12px rgba(169, 169, 169, 0.42), 0 4px 23px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(169, 169, 169, 0.2);
    color: #ffffff !important;
}
.btn-secondary.active,
.btn-secondary:active,
.btn-secondary:focus,
.btn-secondary.disabled.active,
.btn-secondary.disabled:active,
.btn-secondary.disabled:focus {
    box-shadow: 0 14px 26px -12px rgba(169, 169, 169, 0.42), 0 4px 23px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(169, 169, 169, 0.2);
    color: #ffffff !important;
    background: #263238;
    border-color: #263238;
}

.btn-secondary.disabled:hover,
.btn-secondary.disabled:focus,
.btn-secondary.disabled:active {
    color: #67757c !important;
    background-color: #ffffff;
    border-color: #cccccc;
    cursor: default;
}

.btn-primary,
.btn-primary.disabled {
    background: #6772e5;
    border: 1px solid #6772e5;
    /*box-shadow: $primary-shadow;*/
    transition: 0.2s ease-in;
}
.btn-primary:hover,
.btn-primary.disabled:hover {
    background: #6772e5;
    box-shadow: 0 14px 26px -12px rgba(116, 96, 238, 0.42), 0 4px 23px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(116, 96, 238, 0.2);
    border: 1px solid #6772e5;
}
.btn-primary.active,
.btn-primary:active,
.btn-primary:focus,
.btn-primary.disabled.active,
.btn-primary.disabled:active,
.btn-primary.disabled:focus {
    background: #6352ce;
    /*box-shadow: $primary-shadow-hover;*/
    border-color: transparent;
}

.btn-themecolor,
.btn-themecolor.disabled {
    background: #20aee3;
    color: #ffffff;
    border: 1px solid #20aee3;
}
.btn-themecolor:hover,
.btn-themecolor.disabled:hover {
    background: #20aee3;
    opacity: 0.7;
    border: 1px solid #20aee3;
}
.btn-themecolor.active,
.btn-themecolor:active,
.btn-themecolor:focus,
.btn-themecolor.disabled.active,
.btn-themecolor.disabled:active,
.btn-themecolor.disabled:focus {
    background: #028ee1;
}

.btn-success,
.btn-success.disabled {
    background: #24d2b5;
    border: 1px solid #24d2b5;
    /*box-shadow: $success-shadow;*/
    transition: 0.2s ease-in;
}
.btn-success:hover,
.btn-success.disabled:hover {
    background: #24d2b5;
    /*box-shadow: $success-shadow-hover;*/
    border: 1px solid #24d2b5;
}
.btn-success.active,
.btn-success:active,
.btn-success:focus,
.btn-success.disabled.active,
.btn-success.disabled:active,
.btn-success.disabled:focus {
    background: #04b381;
    /*box-shadow: $success-shadow-hover;*/
    border-color: transparent;
}

.btn-info,
.btn-info.disabled {
    background: #20aee3;
    border: 1px solid #20aee3;
    /*box-shadow: $info-shadow;*/
    transition: 0.2s ease-in;
}
.btn-info:hover,
.btn-info.disabled:hover {
    background: #20aee3;
    border: 1px solid #20aee3;
    /*box-shadow: $info-shadow-hover;*/
}
.btn-info.active,
.btn-info:active,
.btn-info:focus,
.btn-info.disabled.active,
.btn-info.disabled:active,
.btn-info.disabled:focus {
    background: #1d96c3;
    /*box-shadow: $info-shadow-hover;*/
    border-color: transparent;
}

.btn-warning,
.btn-warning.disabled {
    background: #ff9041;
    color: #ffffff;
    /*box-shadow: $warning-shadow;*/
    border: 1px solid #ff9041;
    transition: 0.2s ease-in;
}
.btn-warning:hover,
.btn-warning.disabled:hover {
    background: #ff9041;
    color: #ffffff;
    /*box-shadow: $warning-shadow-hover;*/
    border: 1px solid #ff9041;
}
.btn-warning.active,
.btn-warning:active,
.btn-warning:focus,
.btn-warning.disabled.active,
.btn-warning.disabled:active,
.btn-warning.disabled:focus {
    background: #e9ab2e;
    color: #ffffff;
    /*box-shadow: $warning-shadow-hover;*/
    border-color: transparent;
}

.btn-danger,
.btn-danger.disabled {
    background: #ff5c6c;
    border: 1px solid #ff5c6c;
    /*box-shadow: $danger-shadow;*/
    transition: 0.2s ease-in;
}
.btn-danger:hover,
.btn-danger.disabled:hover {
    background: #ff5c6c;
    /*box-shadow: $danger-shadow-hover;*/
    border: 1px solid #ff5c6c;
}
.btn-danger.active,
.btn-danger:active,
.btn-danger:focus,
.btn-danger.disabled.active,
.btn-danger.disabled:active,
.btn-danger.disabled:focus {
    background: #e6294b;
    /*box-shadow: $danger-shadow-hover;*/
    border-color: transparent;
}

.btn-inverse,
.btn-inverse.disabled {
    background: #2f3d4a;
    border: 1px solid #2f3d4a;
    color: #ffffff;
}
.btn-inverse:hover,
.btn-inverse.disabled:hover {
    background: #2f3d4a;
    opacity: 0.7;
    color: #ffffff;
    border: 1px solid #2f3d4a;
}
.btn-inverse.active,
.btn-inverse:active,
.btn-inverse:focus,
.btn-inverse.disabled.active,
.btn-inverse.disabled:active,
.btn-inverse.disabled:focus {
    background: #232a37;
    color: #ffffff;
}

.btn-red,
.btn-red.disabled {
    background: #fb3a3a;
    border: 1px solid #fb3a3a;
    color: #ffffff;
}
.btn-red:hover,
.btn-red.disabled:hover {
    opacity: 0.7;
    border: 1px solid #fb3a3a;
    background: #fb3a3a;
}
.btn-red.active,
.btn-red:active,
.btn-red:focus,
.btn-red.disabled.active,
.btn-red.disabled:active,
.btn-red.disabled:focus {
    background: #e6294b;
}

.btn-outline-secondary {
    background-color: #ffffff;
    /*box-shadow: $default-shadow;*/
    transition: 0.2s ease-in;
    border-color: #cccccc;
}
.btn-outline-secondary:hover,
.btn-outline-secondary:focus,
.btn-outline-secondary.focus {
    /*box-shadow: $default-shadow-hover;*/
}
.btn-outline-secondary.active,
.btn-outline-secondary:active,
.btn-outline-secondary:focus {
    /*box-shadow: $default-shadow-hover;*/
}

.btn-outline-primary {
    color: #6772e5;
    background-color: #ffffff;
    border-color: #6772e5;
    /*box-shadow: $primary-shadow;*/
    transition: 0.2s ease-in;
}
.btn-outline-primary:hover,
.btn-outline-primary:focus,
.btn-outline-primary.focus {
    background: #6772e5;
    /*box-shadow: $primary-shadow-hover;*/
    color: #ffffff;
    border-color: #6772e5;
}
.btn-outline-primary.active,
.btn-outline-primary:active,
.btn-outline-primary:focus {
    /*box-shadow: $primary-shadow-hover;*/
    background-color: #6352ce;
    border-color: #6352ce;
}

.btn-outline-success {
    color: #24d2b5;
    background-color: transparent;
    border-color: #24d2b5;
    /*box-shadow: $success-shadow;*/
    transition: 0.2s ease-in;
}
.btn-outline-success:hover,
.btn-outline-success:focus,
.btn-outline-success.focus {
    background: #24d2b5;
    border-color: #24d2b5;
    color: #ffffff;
    /*box-shadow: $success-shadow-hover;*/
}
.btn-outline-success.active,
.btn-outline-success:active,
.btn-outline-success:focus {
    /*box-shadow: $success-shadow-hover;*/
    background-color: #04b381;
    border-color: #04b381;
}

.btn-outline-info {
    color: #20aee3;
    background-color: transparent;
    border-color: #20aee3;
    /*box-shadow: $info-shadow;*/
    transition: 0.2s ease-in;
}
.btn-outline-info:hover,
.btn-outline-info:focus,
.btn-outline-info.focus {
    background: #20aee3;
    border-color: #20aee3;
    color: #ffffff;
    /*box-shadow: $info-shadow-hover;*/
}
.btn-outline-info.active,
.btn-outline-info:active,
.btn-outline-info:focus {
    /*box-shadow: $info-shadow-hover;*/
    background-color: #1d96c3;
    border-color: #1d96c3;
}

.btn-outline-warning {
    color: #ff9041;
    background-color: transparent;
    border-color: #ff9041;
    /*box-shadow: $warning-shadow;*/
    transition: 0.2s ease-in;
}
.btn-outline-warning:hover,
.btn-outline-warning:focus,
.btn-outline-warning.focus {
    background: #ff9041;
    border-color: #ff9041;
    color: #ffffff;
    /*box-shadow: $warning-shadow-hover;*/
}
.btn-outline-warning.active,
.btn-outline-warning:active,
.btn-outline-warning:focus {
    /*box-shadow: $warning-shadow-hover;*/
    background-color: #e9ab2e;
    border-color: #e9ab2e;
}

.btn-outline-danger {
    color: #ff5c6c;
    background-color: transparent;
    border-color: #ff5c6c;
    /*box-shadow: $danger-shadow;*/
    transition: 0.2s ease-in;
}
.btn-outline-danger:hover,
.btn-outline-danger:focus,
.btn-outline-danger.focus {
    background: #ff5c6c;
    border-color: #ff5c6c;
    color: #ffffff;
    /*box-shadow: $danger-shadow-hover;*/
}
.btn-outline-danger.active,
.btn-outline-danger:active,
.btn-outline-danger:focus {
    /*box-shadow: $danger-shadow-hover;*/
    background-color: #e6294b;
}

.btn-outline-red {
    color: #fb3a3a;
    background-color: transparent;
    border-color: #fb3a3a;
}
.btn-outline-red:hover,
.btn-outline-red:focus,
.btn-outline-red.focus {
    background: #fb3a3a;
    border-color: #fb3a3a;
    color: #ffffff;
    /*box-shadow: $danger-shadow-hover;*/
}
.btn-outline-red.active,
.btn-outline-red:active,
.btn-outline-red:focus {
    /*box-shadow: $danger-shadow-hover;*/
    background-color: #d61f1f;
}

.btn-outline-inverse {
    color: #2f3d4a;
    background-color: transparent;
    border-color: #2f3d4a;
}
.btn-outline-inverse:hover,
.btn-outline-inverse:focus,
.btn-outline-inverse.focus {
    background: #2f3d4a;
    border-color: #2f3d4a;
    color: #ffffff;
}

.btn-primary.active.focus,
.btn-primary.active:focus,
.btn-primary.active:hover,
.btn-primary.focus:active,
.btn-primary:active:focus,
.btn-primary:active:hover,
.open > .dropdown-toggle.btn-primary.focus,
.open > .dropdown-toggle.btn-primary:focus,
.open > .dropdown-toggle.btn-primary:hover,
.btn-primary.focus,
.btn-primary:focus,
.show > .btn-primary.dropdown-toggle {
    background-color: #6352ce;
    border: 1px solid #6352ce;
}

.btn-success.active.focus,
.btn-success.active:focus,
.btn-success.active:hover,
.btn-success.focus:active,
.btn-success:active:focus,
.btn-success:active:hover,
.open > .dropdown-toggle.btn-success.focus,
.open > .dropdown-toggle.btn-success:focus,
.open > .dropdown-toggle.btn-success:hover,
.btn-success.focus,
.btn-success:focus,
.show > .btn-success.dropdown-toggle {
    background-color: #04b381;
    border: 1px solid #04b381;
}

.btn-info.active.focus,
.btn-info.active:focus,
.btn-info.active:hover,
.btn-info.focus:active,
.btn-info:active:focus,
.btn-info:active:hover,
.open > .dropdown-toggle.btn-info.focus,
.open > .dropdown-toggle.btn-info:focus,
.open > .dropdown-toggle.btn-info:hover,
.btn-info.focus,
.btn-info:focus,
.show > .btn-info.dropdown-toggle {
    background-color: #1d96c3;
    border: 1px solid #1d96c3;
}

.btn-warning.active.focus,
.btn-warning.active:focus,
.btn-warning.active:hover,
.btn-warning.focus:active,
.btn-warning:active:focus,
.btn-warning:active:hover,
.open > .dropdown-toggle.btn-warning.focus,
.open > .dropdown-toggle.btn-warning:focus,
.open > .dropdown-toggle.btn-warning:hover,
.btn-warning.focus,
.btn-warning:focus,
.show > .btn-warning.dropdown-toggle {
    background-color: #e9ab2e;
    border: 1px solid #e9ab2e;
}

.btn-danger.active.focus,
.btn-danger.active:focus,
.btn-danger.active:hover,
.btn-danger.focus:active,
.btn-danger:active:focus,
.btn-danger:active:hover,
.open > .dropdown-toggle.btn-danger.focus,
.open > .dropdown-toggle.btn-danger:focus,
.open > .dropdown-toggle.btn-danger:hover,
.btn-danger.focus,
.btn-danger:focus,
.show > .btn-danger.dropdown-toggle {
    background-color: #e6294b;
    border: 1px solid #e6294b;
}

.btn-inverse:hover,
.btn-inverse:focus,
.btn-inverse:active,
.btn-inverse.active,
.btn-inverse.focus,
.btn-inverse:active,
.btn-inverse:focus,
.btn-inverse:hover,
.open > .dropdown-toggle.btn-inverse {
    background-color: #232a37;
    border: 1px solid #232a37;
}

.btn-secondary.active.focus,
.btn-secondary.active:focus,
.btn-secondary.active:hover,
.btn-secondary.focus:active,
.btn-secondary:active:focus,
.btn-secondary:active:hover,
.open > .dropdown-toggle.btn-secondary.focus,
.open > .dropdown-toggle.btn-secondary:focus,
.open > .dropdown-toggle.btn-secondary:hover,
.btn-secondary.focus,
.btn-secondary:focus,
.show > .btn-secondary.dropdown-toggle {
    color: #ffffff;
}

.btn-red:hover,
.btn-red:focus,
.btn-red:active,
.btn-red.active,
.btn-red.focus,
.btn-red:active,
.btn-red:focus,
.btn-red:hover,
.open > .dropdown-toggle.btn-red {
    background-color: #d61f1f;
    border: 1px solid #d61f1f;
    color: #ffffff;
}

.button-box .btn {
    margin: 0 8px 8px 0px;
}

.btn-label {
    background: rgba(0, 0, 0, 0.05);
    display: inline-block;
    margin: -6px 12px -6px -14px;
    padding: 7px 15px;
}

.btn-facebook {
    color: #ffffff;
    background-color: #3b5998;
}
.btn-facebook:hover {
    color: #ffffff;
}

.btn-twitter {
    color: #ffffff;
    background-color: #55acee;
}
.btn-twitter:hover {
    color: #ffffff;
}

.btn-linkedin {
    color: #ffffff;
    background-color: #007bb6;
}
.btn-linkedin:hover {
    color: #ffffff;
}

.btn-dribbble {
    color: #ffffff;
    background-color: #ea4c89;
}
.btn-dribbble:hover {
    color: #ffffff;
}

.btn-googleplus {
    color: #ffffff;
    background-color: #dd4b39;
}
.btn-googleplus:hover {
    color: #ffffff;
}

.btn-instagram {
    color: #ffffff;
    background-color: #3f729b;
}

.btn-pinterest {
    color: #ffffff;
    background-color: #cb2027;
}

.btn-dropbox {
    color: #ffffff;
    background-color: #007ee5;
}

.btn-flickr {
    color: #ffffff;
    background-color: #ff0084;
}

.btn-tumblr {
    color: #ffffff;
    background-color: #32506d;
}

.btn-skype {
    color: #ffffff;
    background-color: #00aff0;
}

.btn-youtube {
    color: #ffffff;
    background-color: #bb0000;
}

.btn-github {
    color: #ffffff;
    background-color: #171515;
}

/*============================================================== 
 Buttons page
 ============================================================== */
.button-group .btn {
    margin-bottom: 5px;
    margin-right: 5px;
}

.no-button-group .btn {
    margin-bottom: 5px;
    margin-right: 0px;
}

.btn .text-active {
    display: none;
}

.btn.active .text-active {
    display: inline-block;
}

.btn.active .text {
    display: none;
}

/*
Template Name: Admin pro Admin
Author: Wrappixel
Email: niravjoshi87@gmail.com
File: scss
*/
/*
Template Name: Admin Pro Admin
Author: Wrappixel
Email: niravjoshi87@gmail.com
File: scss
*/
/*Theme Colors*/
/*bootstrap Color*/
/*Light colors*/
/*Normal Color*/
/*Extra Variable*/
/*******************
Custom-select
******************/

/*******************
textarea
******************/
textarea {
    resize: none;
}

/*******************
Form-control
******************/
.form-control {
    color: #67757c;
    min-height: 30px !important;
    display: initial;
    padding: 5px 5px !important;
    font-size: 12px !important;
    height: 30px !important;
}

.form-control-sm {
    min-height: 20px;
}

.form-control:disabled,
.form-control[readonly] {
    opacity: 0.7;
}

.custom-control-input:focus ~ .custom-control-indicator {
    box-shadow: none;
}

.custom-control-input:checked ~ .custom-control-indicator {
    background-color: #24d2b5;
}

form label { 
    font-size: 12px;
}
.border_area {
    border-right: 1px solid rgba(0, 0, 0, .15);
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    background-color: #fff;
}
.headerMDL{
    /* background-image: url('../img/modalBG.jpg'); */
    color: #ffffff;
    border-radius: 0px;
    background-size: cover;
    background-position: bottom;
    background-color: #2e333f;
}
.btn-close{
    background-color:#ffffff !important;
    opacity: .9 !important;
}
.form-group {
    margin-bottom: 25px;
}

.form-horizontal label {
    margin-bottom: 0px;
}

.form-control-static {
    padding-top: 0px;
}

.form-bordered .form-group {
    border-bottom: 1px solid rgba(120, 130, 140, 0.13);
    padding-bottom: 20px;
}

/*******************
Form Dropzone
******************/
.dropzone {
    border: 1px dashed #b1b8bb;
}
.dropzone .dz-message {
    padding: 5% 0;
    margin: 0px;
}

/*******************
Form Pickers
******************/
.asColorPicker-dropdown {
    max-width: 260px;
}

.asColorPicker-trigger {
    position: absolute;
    top: 0;
    right: -35px;
    height: 38px;
    width: 37px;
    border: 0;
}

.asColorPicker-clear {
    display: none;
    position: absolute;
    top: 5px;
    right: 10px;
    text-decoration: none;
}

table th {
    font-weight: 400;
}

.daterangepicker td.active,
.daterangepicker td.active:hover {
    background-color: #20aee3;
}

.datepicker table tr td.today,
.datepicker table tr td.today.disabled,
.datepicker table tr td.today.disabled:hover,
.datepicker table tr td.today:hover {
    background: #20aee3;
    color: #ffffff;
}

.datepicker td,
.datepicker th {
    padding: 5px 10px;
}

/*******************
Form summernote
******************/
.note-popover,
.note-icon-caret {
    display: none;
}

.note-editor.note-frame {
    border: 1px solid #b1b8bb;
}
.note-editor.note-frame .panel-heading {
    padding: 6px 10px 10px;
    border-bottom: 1px solid rgba(120, 130, 140, 0.13);
}

.label {
    display: inline-block;
}

/*============================================================== 
Form addons page
============================================================== */
/*Bootstrap select*/
.bootstrap-select:not([class*="col-"]):not([class*="form-control"]):not(.input-group-btn) {
    width: 100%;
}

.bootstrap-select .dropdown-menu li a {
    display: block;
    padding: 7px 20px;
    clear: both;
    font-weight: 400;
    line-height: 1.42857143;
    color: #67757c;
    white-space: nowrap;
}
.bootstrap-select .dropdown-menu li a:hover,
.bootstrap-select .dropdown-menu li a:focus {
    color: #20aee3;
    background: #e9edf2;
}

.bootstrap-select .show > .dropdown-menu {
    display: block;
}

.bootstrap-touchspin .input-group-btn-vertical > .btn {
    padding: 9px 10px;
}

.select2-container--default .select2-selection--single {
    border-color: #b1b8bb;
    height: 38px;
}
.select2-container--default .select2-selection--single .select2-selection__rendered {
    line-height: 38px;
}
.select2-container--default .select2-selection--single .select2-selection__arrow {
    height: 33px;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice__remove {
    float: right;
    color: #ffffff;
    margin-right: 0px;
    margin-left: 4px;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice {
    background: #20aee3;
    color: #ffffff;
    border-color: #20aee3;
}

.input-form .btn {
    padding: 8px 12px;
}

/*============================================================== 
Form Material page
 ============================================================== */
/*Material inputs*/
.form-material .form-group {
    overflow: hidden;
}

.form-material .form-control {
    background-color: transparent;
    background-position: center bottom, center calc(100% - 1px);
    background-repeat: no-repeat;
    background-size: 0 2px, 100% 1px;
    padding: 0;
}

.form-material .form-control,
.form-material .form-control.focus,
.form-material .form-control:focus {
    background-image: linear-gradient(#20aee3, #20aee3), linear-gradient(#e9edf2, #e9edf2);
    border: 0 none;
    border-radius: 0;
    box-shadow: none;
    float: none;
}

.form-material .form-control.focus,
.form-material .form-control:focus {
    background-size: 100% 2px, 100% 1px;
    outline: 0 none;
    transition-duration: 0.3s;
}

.form-control-line .form-group {
    overflow: hidden;
}

.form-control-line .form-control {
    border: 0px;
    border-radius: 0px;
    padding-left: 0px;
    border-bottom: 1px solid #f6f9ff;
}
.form-control-line .form-control:focus {
    border-bottom: 1px solid #20aee3;
}

.form-control:focus {
 
    box-shadow: 0 0 0 0.1px #ccc;
}
/*******************
Form validation error 
******************/
.error .help-block {
    color: #fb3a3a;
}

/*******************
File Upload 
******************/
.fileupload {
    overflow: hidden;
    position: relative;
}
.fileupload input.upload {
    cursor: pointer;
    filter: alpha(opacity=0);
    font-size: 20px;
    margin: 0;
    opacity: 0;
    padding: 0;
    position: absolute;
    right: 0;
    top: 0;
}

/*******************
This is for the checkbox radio button and switch 
******************/
/* Radio Buttons
   ========================================================================== */
[type="radio"]:not(:checked),
[type="radio"]:checked {
    position: absolute;
    left: -9999px;
    opacity: 0;
}

[type="radio"]:not(:checked) + label,
[type="radio"]:checked + label {
    position: relative;
    padding-left: 35px;
    cursor: pointer;
    display: inline-block;
    height: 25px;
    line-height: 25px;
    font-size: 1rem;
    transition: 0.28s ease;
    /* webkit (konqueror) browsers */
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

[type="radio"] + label:before,
[type="radio"] + label:after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    margin: 4px;
    width: 16px;
    height: 16px;
    z-index: 0;
    transition: 0.28s ease;
}

/* Unchecked styles */
[type="radio"]:not(:checked) + label:before,
[type="radio"]:not(:checked) + label:after,
[type="radio"]:checked + label:before,
[type="radio"]:checked + label:after,
[type="radio"].with-gap:checked + label:before,
[type="radio"].with-gap:checked + label:after {
    border-radius: 50%;
}

[type="radio"]:not(:checked) + label:before,
[type="radio"]:not(:checked) + label:after {
    border: 1px solid #b1b8bb;
}

[type="radio"]:not(:checked) + label:after {
    z-index: -1;
    -webkit-transform: scale(0);
    -ms-transform: scale(0);
    transform: scale(0);
}

/* Checked styles */
[type="radio"]:checked + label:before {
    border: 2px solid transparent;
    -webkit-animation: ripple 0.2s linear forwards;
    animation: ripple 0.2s linear forwards;
}

[type="radio"]:checked + label:after,
[type="radio"].with-gap:checked + label:before,
[type="radio"].with-gap:checked + label:after {
    border: 2px solid #26a69a;
}

[type="radio"]:checked + label:after,
[type="radio"].with-gap:checked + label:after {
    background-color: #26a69a;
    z-index: 0;
}

[type="radio"]:checked + label:after {
    -webkit-transform: scale(1.02);
    -ms-transform: scale(1.02);
    transform: scale(1.02);
}

/* Radio With gap */
[type="radio"].with-gap:checked + label:after {
    -webkit-transform: scale(0.5);
    -ms-transform: scale(0.5);
    transform: scale(0.5);
}

/* Focused styles */
[type="radio"].tabbed:focus + label:before {
    box-shadow: 0 0 0 10px rgba(0, 0, 0, 0.1);
    -webkit-animation: ripple 0.2s linear forwards;
    animation: ripple 0.2s linear forwards;
}

/* Disabled Radio With gap */
[type="radio"].with-gap:disabled:checked + label:before {
    border: 2px solid rgba(0, 0, 0, 0.26);
    -webkit-animation: ripple 0.2s linear forwards;
    animation: ripple 0.2s linear forwards;
}

[type="radio"].with-gap:disabled:checked + label:after {
    border: none;
    background-color: rgba(0, 0, 0, 0.26);
}

/* Disabled style */
[type="radio"]:disabled:not(:checked) + label:before,
[type="radio"]:disabled:checked + label:before {
    background-color: transparent;
    border-color: rgba(0, 0, 0, 0.26);
    -webkit-animation: ripple 0.2s linear forwards;
    animation: ripple 0.2s linear forwards;
}

[type="radio"]:disabled + label {
    color: rgba(0, 0, 0, 0.26);
}

[type="radio"]:disabled:not(:checked) + label:before {
    border-color: rgba(0, 0, 0, 0.26);
}

[type="radio"]:disabled:checked + label:after {
    background-color: rgba(0, 0, 0, 0.26);
    border-color: #bdbdbd;
}

/* Checkboxes
   ========================================================================== */
/* CUSTOM CSS CHECKBOXES */
form p {
    margin-bottom: 10px;
    text-align: left;
}

form p:last-child {
    margin-bottom: 0;
}

/* Remove default checkbox */
[type="checkbox"]:not(:checked),
[type="checkbox"]:checked {
    position: absolute;
    left: -9999px;
    opacity: 0;
}

[type="checkbox"] {
    /* checkbox aspect */
}

[type="checkbox"] + label {
    position: relative;
    padding-left: 35px;
    cursor: pointer;
    display: inline-block;
    height: 25px;
    line-height: 25px;
    font-size: 1rem;
    -webkit-user-select: none;
    /* webkit (safari, chrome) browsers */
    -moz-user-select: none;
    /* mozilla browsers */
    -khtml-user-select: none;
    /* webkit (konqueror) browsers */
    -ms-user-select: none;
    /* IE10+ */
}

[type="checkbox"] + label:before,
[type="checkbox"]:not(.filled-in) + label:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 18px;
    height: 18px;
    z-index: 0;
    border: 1px solid #b1b8bb;
    border-radius: 1px;
    margin-top: 2px;
    transition: 0.2s;
}

[type="checkbox"]:not(.filled-in) + label:after {
    border: 0;
    -webkit-transform: scale(0);
    -ms-transform: scale(0);
    transform: scale(0);
}

[type="checkbox"]:not(:checked):disabled + label:before {
    border: none;
    background-color: rgba(0, 0, 0, 0.26);
}

[type="checkbox"].tabbed:focus + label:after {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
    border: 0;
    border-radius: 50%;
    box-shadow: 0 0 0 10px rgba(0, 0, 0, 0.1);
    background-color: rgba(0, 0, 0, 0.1);
}

[type="checkbox"]:checked + label:before {
    top: -4px;
    left: -5px;
    width: 12px;
    height: 22px;
    border-top: 2px solid transparent;
    border-left: 2px solid transparent;
    border-right: 2px solid #26a69a;
    border-bottom: 2px solid #26a69a;
    -webkit-transform: rotate(40deg);
    -ms-transform: rotate(40deg);
    transform: rotate(40deg);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-transform-origin: 100% 100%;
    -ms-transform-origin: 100% 100%;
    transform-origin: 100% 100%;
}

[type="checkbox"]:checked:disabled + label:before {
    border-right: 2px solid rgba(0, 0, 0, 0.26);
    border-bottom: 2px solid rgba(0, 0, 0, 0.26);
}

/* Indeterminate checkbox */
[type="checkbox"]:indeterminate + label:before {
    top: -11px;
    left: -12px;
    width: 10px;
    height: 22px;
    border-top: none;
    border-left: none;
    border-right: 2px solid #26a69a;
    border-bottom: none;
    -webkit-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-transform-origin: 100% 100%;
    -ms-transform-origin: 100% 100%;
    transform-origin: 100% 100%;
}

[type="checkbox"]:indeterminate:disabled + label:before {
    border-right: 2px solid rgba(0, 0, 0, 0.26);
    background-color: transparent;
}

[type="checkbox"].filled-in + label:after {
    border-radius: 2px;
}

[type="checkbox"].filled-in + label:before,
[type="checkbox"].filled-in + label:after {
    content: "";
    left: 0;
    position: absolute;
    /* .1s delay is for check animation */
    transition: border 0.25s, background-color 0.25s, width 0.2s 0.1s, height 0.2s 0.1s, top 0.2s 0.1s, left 0.2s 0.1s;
    z-index: 1;
}

[type="checkbox"].filled-in:not(:checked) + label:before {
    width: 0;
    height: 0;
    border: 3px solid transparent;
    left: 6px;
    top: 10px;
    -webkit-transform: rotateZ(37deg);
    -ms-transform: rotate(37deg);
    transform: rotateZ(37deg);
    -webkit-transform-origin: 20% 40%;
    -ms-transform-origin: 100% 100%;
    transform-origin: 100% 100%;
}

[type="checkbox"].filled-in:not(:checked) + label:after {
    height: 20px;
    width: 20px;
    background-color: transparent;
    border: 1px solid #b1b8bb;
    top: 0px;
    z-index: 0;
}

[type="checkbox"].filled-in:checked + label:before {
    top: 0;
    left: 1px;
    width: 8px;
    height: 13px;
    border-top: 2px solid transparent;
    border-left: 2px solid transparent;
    border-right: 2px solid #fff;
    border-bottom: 2px solid #fff;
    -webkit-transform: rotateZ(37deg);
    -ms-transform: rotate(37deg);
    transform: rotateZ(37deg);
    -webkit-transform-origin: 100% 100%;
    -ms-transform-origin: 100% 100%;
    transform-origin: 100% 100%;
}

[type="checkbox"].filled-in:checked + label:after {
    top: 0;
    width: 20px;
    height: 20px;
    border: 2px solid #26a69a;
    background-color: #26a69a;
    z-index: 0;
}

[type="checkbox"].filled-in.tabbed:focus + label:after {
    border-radius: 2px;
    border-color: #5a5a5a;
    background-color: rgba(0, 0, 0, 0.1);
}

[type="checkbox"].filled-in.tabbed:checked:focus + label:after {
    border-radius: 2px;
    background-color: #26a69a;
    border-color: #26a69a;
}

[type="checkbox"].filled-in:disabled:not(:checked) + label:before {
    background-color: transparent;
    border: 2px solid transparent;
}

[type="checkbox"].filled-in:disabled:not(:checked) + label:after {
    border-color: transparent;
    background-color: #bdbdbd;
}

[type="checkbox"].filled-in:disabled:checked + label:before {
    background-color: transparent;
}

[type="checkbox"].filled-in:disabled:checked + label:after {
    background-color: #bdbdbd;
    border-color: #bdbdbd;
}

/* Switch
   ========================================================================== */
.switch,
.switch * {
    -webkit-user-select: none;
    -moz-user-select: none;
    -khtml-user-select: none;
    -ms-user-select: none;
}

.switch label {
    cursor: pointer;
}

.switch label input[type="checkbox"] {
    opacity: 0;
    width: 0;
    height: 0;
}

.switch label input[type="checkbox"]:checked + .lever {
    background-color: #84c7c1;
}

.switch label input[type="checkbox"]:checked + .lever:after {
    background-color: #26a69a;
    left: 24px;
}

.switch label .lever {
    content: "";
    display: inline-block;
    position: relative;
    width: 40px;
    height: 15px;
    background-color: #818181;
    border-radius: 15px;
    margin-right: 10px;

    vertical-align: middle;
    margin: 0 16px;
}

.switch label .lever:after {
    content: "";
    position: absolute;
    display: inline-block;
    width: 21px;
    height: 21px;
    background-color: #f1f1f1;
    border-radius: 21px;
    box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4);
    left: -5px;
    top: -3px;
}

input[type="checkbox"]:checked:not(:disabled) ~ .lever:active::after,
input[type="checkbox"]:checked:not(:disabled).tabbed:focus ~ .lever::after {
    box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(38, 166, 154, 0.1);
}

input[type="checkbox"]:not(:disabled) ~ .lever:active:after,
input[type="checkbox"]:not(:disabled).tabbed:focus ~ .lever::after {
    box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(0, 0, 0, 0.08);
}

.switch input[type="checkbox"][disabled] + .lever {
    cursor: default;
}

.switch label input[type="checkbox"][disabled] + .lever:after,
.switch label input[type="checkbox"][disabled]:checked + .lever:after {
    background-color: #bdbdbd;
}

.scale-up {
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
    -webkit-transform: scale(0);
    -ms-transform: scale(0);
    transform: scale(0);
    display: inline-block;
    -webkit-transform-origin: right 0px;
    -ms-transform-origin: right 0px;
    transform-origin: right 0px;
}

.scale-up-left {
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
    -webkit-transform: scale(0);
    -ms-transform: scale(0);
    transform: scale(0);
    display: inline-block;
    -webkit-transform-origin: left 0px;
    -ms-transform-origin: left 0px;
    transform-origin: left 0px;
}

.show > .scale-up {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: right 0px;
    -ms-transform-origin: right 0px;
    transform-origin: right 0px;
}

.show > .scale-up-left {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: left 0px;
    -ms-transform-origin: left 0px;
    transform-origin: left 0px;
}

/*panels*/
.card {
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1);
    border-radius: 4px;
}

.well,
pre {
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1);
}

.page-titles .justify-content-end:last-child .d-flex {
    margin-right: 10px;
}

.btn-circle.right-side-toggle {
    position: fixed;
    bottom: 20px;
    right: 20px;
    padding: 25px;
    z-index: 10;
}

/*Radio button*/
@-webkit-keyframes ripple {
    0% {
        box-shadow: 0px 0px 0px 1px transparent;
    }
    50% {
        box-shadow: 0px 0px 0px 15px rgba(0, 0, 0, 0.1);
    }
    100% {
        box-shadow: 0px 0px 0px 15px transparent;
    }
}
@keyframes ripple {
    0% {
        box-shadow: 0px 0px 0px 1px transparent;
    }
    50% {
        box-shadow: 0px 0px 0px 15px rgba(0, 0, 0, 0.1);
    }
    100% {
        box-shadow: 0px 0px 0px 15px transparent;
    }
}

/*Floating label*/
/*Bootstrap Select*/
.bootstrap-select.btn-group .dropdown-menu {
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1);
}

.demo-checkbox label,
.demo-radio-button label {
    min-width: 200px;
    margin-bottom: 20px;
}

.demo-swtich .demo-switch-title,
.demo-swtich .switch {
    width: 150px;
    margin-bottom: 10px;
    display: inline-block;
}

[type="checkbox"] + label {
    padding-left: 26px;
    height: 25px;
    line-height: 21px;
    font-weight: normal;
}

[type="checkbox"]:checked + label:before {
    top: -4px;
    left: -2px;
    width: 11px;
    height: 19px;
}

[type="checkbox"]:checked.chk-col-red + label:before {
    border-right: 2px solid #fb3a3a;
    border-bottom: 2px solid #fb3a3a;
}

[type="checkbox"]:checked.chk-col-pink + label:before {
    border-right: 2px solid #e91e63;
    border-bottom: 2px solid #e91e63;
}

[type="checkbox"]:checked.chk-col-purple + label:before {
    border-right: 2px solid #7460ee;
    border-bottom: 2px solid #7460ee;
}

[type="checkbox"]:checked.chk-col-deep-purple + label:before {
    border-right: 2px solid #673ab7;
    border-bottom: 2px solid #673ab7;
}

[type="checkbox"]:checked.chk-col-indigo + label:before {
    border-right: 2px solid #3f51b5;
    border-bottom: 2px solid #3f51b5;
}

[type="checkbox"]:checked.chk-col-blue + label:before {
    border-right: 2px solid #02bec9;
    border-bottom: 2px solid #02bec9;
}

[type="checkbox"]:checked.chk-col-light-blue + label:before {
    border-right: 2px solid #03a9f4;
    border-bottom: 2px solid #03a9f4;
}

[type="checkbox"]:checked.chk-col-cyan + label:before {
    border-right: 2px solid #00bcd4;
    border-bottom: 2px solid #00bcd4;
}

[type="checkbox"]:checked.chk-col-teal + label:before {
    border-right: 2px solid #009688;
    border-bottom: 2px solid #009688;
}

[type="checkbox"]:checked.chk-col-green + label:before {
    border-right: 2px solid #26c6da;
    border-bottom: 2px solid #26c6da;
}

[type="checkbox"]:checked.chk-col-light-green + label:before {
    border-right: 2px solid #8bc34a;
    border-bottom: 2px solid #8bc34a;
}

[type="checkbox"]:checked.chk-col-lime + label:before {
    border-right: 2px solid #cddc39;
    border-bottom: 2px solid #cddc39;
}

[type="checkbox"]:checked.chk-col-yellow + label:before {
    border-right: 2px solid #ffe821;
    border-bottom: 2px solid #ffe821;
}

[type="checkbox"]:checked.chk-col-amber + label:before {
    border-right: 2px solid #ffc107;
    border-bottom: 2px solid #ffc107;
}

[type="checkbox"]:checked.chk-col-orange + label:before {
    border-right: 2px solid #ff9800;
    border-bottom: 2px solid #ff9800;
}

[type="checkbox"]:checked.chk-col-deep-orange + label:before {
    border-right: 2px solid #ff5722;
    border-bottom: 2px solid #ff5722;
}

[type="checkbox"]:checked.chk-col-brown + label:before {
    border-right: 2px solid #795548;
    border-bottom: 2px solid #795548;
}

[type="checkbox"]:checked.chk-col-grey + label:before {
    border-right: 2px solid #9e9e9e;
    border-bottom: 2px solid #9e9e9e;
}

[type="checkbox"]:checked.chk-col-blue-grey + label:before {
    border-right: 2px solid #607d8b;
    border-bottom: 2px solid #607d8b;
}

[type="checkbox"]:checked.chk-col-black + label:before {
    border-right: 2px solid #000000;
    border-bottom: 2px solid #000000;
}

[type="checkbox"]:checked.chk-col-white + label:before {
    border-right: 2px solid #ffffff;
    border-bottom: 2px solid #ffffff;
}

[type="checkbox"].filled-in:checked + label:after {
    top: 0;
    width: 20px;
    height: 20px;
    border: 2px solid #26a69a;
    background-color: #26a69a;
    z-index: 0;
}

[type="checkbox"].filled-in:checked + label:before {
    border-right: 2px solid #fff !important;
    border-bottom: 2px solid #fff !important;
}

[type="checkbox"].filled-in:checked.chk-col-red + label:after {
    border: 2px solid #fb3a3a;
    background-color: #fb3a3a;
}

[type="checkbox"].filled-in:checked.chk-col-pink + label:after {
    border: 2px solid #e91e63;
    background-color: #e91e63;
}

[type="checkbox"].filled-in:checked.chk-col-purple + label:after {
    border: 2px solid #7460ee;
    background-color: #7460ee;
}

[type="checkbox"].filled-in:checked.chk-col-deep-purple + label:after {
    border: 2px solid #673ab7;
    background-color: #673ab7;
}

[type="checkbox"].filled-in:checked.chk-col-indigo + label:after {
    border: 2px solid #3f51b5;
    background-color: #3f51b5;
}

[type="checkbox"].filled-in:checked.chk-col-blue + label:after {
    border: 2px solid #02bec9;
    background-color: #02bec9;
}

[type="checkbox"].filled-in:checked.chk-col-light-blue + label:after {
    border: 2px solid #03a9f4;
    background-color: #03a9f4;
}

[type="checkbox"].filled-in:checked.chk-col-cyan + label:after {
    border: 2px solid #00bcd4;
    background-color: #00bcd4;
}

[type="checkbox"].filled-in:checked.chk-col-teal + label:after {
    border: 2px solid #009688;
    background-color: #009688;
}

[type="checkbox"].filled-in:checked.chk-col-green + label:after {
    border: 2px solid #26c6da;
    background-color: #26c6da;
}

[type="checkbox"].filled-in:checked.chk-col-light-green + label:after {
    border: 2px solid #8bc34a;
    background-color: #8bc34a;
}

[type="checkbox"].filled-in:checked.chk-col-lime + label:after {
    border: 2px solid #cddc39;
    background-color: #cddc39;
}

[type="checkbox"].filled-in:checked.chk-col-yellow + label:after {
    border: 2px solid #ffe821;
    background-color: #ffe821;
}

[type="checkbox"].filled-in:checked.chk-col-amber + label:after {
    border: 2px solid #ffc107;
    background-color: #ffc107;
}

[type="checkbox"].filled-in:checked.chk-col-orange + label:after {
    border: 2px solid #ff9800;
    background-color: #ff9800;
}

[type="checkbox"].filled-in:checked.chk-col-deep-orange + label:after {
    border: 2px solid #ff5722;
    background-color: #ff5722;
}

[type="checkbox"].filled-in:checked.chk-col-brown + label:after {
    border: 2px solid #795548;
    background-color: #795548;
}

[type="checkbox"].filled-in:checked.chk-col-grey + label:after {
    border: 2px solid #9e9e9e;
    background-color: #9e9e9e;
}

[type="checkbox"].filled-in:checked.chk-col-blue-grey + label:after {
    border: 2px solid #607d8b;
    background-color: #607d8b;
}

[type="checkbox"].filled-in:checked.chk-col-black + label:after {
    border: 2px solid #000000;
    background-color: #000000;
}

[type="checkbox"].filled-in:checked.chk-col-white + label:after {
    border: 2px solid #ffffff;
    background-color: #ffffff;
}

[type="radio"]:not(:checked) + label {
    padding-left: 26px;
    height: 25px;
    line-height: 25px;
    font-weight: normal;
}

[type="radio"]:checked + label {
    padding-left: 26px;
    height: 25px;
    line-height: 25px;
    font-weight: normal;
}

[type="radio"].radio-col-red:checked + label:after {
    background-color: #fb3a3a;
    border-color: #fb3a3a;
    -webkit-animation: ripple 0.2s linear forwards;
    animation: ripple 0.2s linear forwards;
}

[type="radio"].radio-col-pink:checked + label:after {
    background-color: #e91e63;
    border-color: #e91e63;
    -webkit-animation: ripple 0.2s linear forwards;
    animation: ripple 0.2s linear forwards;
}

[type="radio"].radio-col-purple:checked + label:after {
    background-color: #7460ee;
    border-color: #7460ee;
    -webkit-animation: ripple 0.2s linear forwards;
    animation: ripple 0.2s linear forwards;
}

[type="radio"].radio-col-deep-purple:checked + label:after {
    background-color: #673ab7;
    border-color: #673ab7;
    -webkit-animation: ripple 0.2s linear forwards;
    animation: ripple 0.2s linear forwards;
}

[type="radio"].radio-col-indigo:checked + label:after {
    background-color: #3f51b5;
    border-color: #3f51b5;
    -webkit-animation: ripple 0.2s linear forwards;
    animation: ripple 0.2s linear forwards;
}

[type="radio"].radio-col-blue:checked + label:after {
    background-color: #02bec9;
    border-color: #02bec9;
    -webkit-animation: ripple 0.2s linear forwards;
    animation: ripple 0.2s linear forwards;
}

[type="radio"].radio-col-light-blue:checked + label:after {
    background-color: #03a9f4;
    border-color: #03a9f4;
    -webkit-animation: ripple 0.2s linear forwards;
    animation: ripple 0.2s linear forwards;
}

[type="radio"].radio-col-cyan:checked + label:after {
    background-color: #00bcd4;
    border-color: #00bcd4;
    -webkit-animation: ripple 0.2s linear forwards;
    animation: ripple 0.2s linear forwards;
}

[type="radio"].radio-col-teal:checked + label:after {
    background-color: #009688;
    border-color: #009688;
    -webkit-animation: ripple 0.2s linear forwards;
    animation: ripple 0.2s linear forwards;
}

[type="radio"].radio-col-green:checked + label:after {
    background-color: #26c6da;
    border-color: #26c6da;
    -webkit-animation: ripple 0.2s linear forwards;
    animation: ripple 0.2s linear forwards;
}

[type="radio"].radio-col-light-green:checked + label:after {
    background-color: #8bc34a;
    border-color: #8bc34a;
    -webkit-animation: ripple 0.2s linear forwards;
    animation: ripple 0.2s linear forwards;
}

[type="radio"].radio-col-lime:checked + label:after {
    background-color: #cddc39;
    border-color: #cddc39;
    -webkit-animation: ripple 0.2s linear forwards;
    animation: ripple 0.2s linear forwards;
}

[type="radio"].radio-col-yellow:checked + label:after {
    background-color: #ffe821;
    border-color: #ffe821;
    -webkit-animation: ripple 0.2s linear forwards;
    animation: ripple 0.2s linear forwards;
}

[type="radio"].radio-col-amber:checked + label:after {
    background-color: #ffc107;
    border-color: #ffc107;
    -webkit-animation: ripple 0.2s linear forwards;
    animation: ripple 0.2s linear forwards;
}

[type="radio"].radio-col-orange:checked + label:after {
    background-color: #ff9800;
    border-color: #ff9800;
    -webkit-animation: ripple 0.2s linear forwards;
    animation: ripple 0.2s linear forwards;
}

[type="radio"].radio-col-deep-orange:checked + label:after {
    background-color: #ff5722;
    border-color: #ff5722;
    -webkit-animation: ripple 0.2s linear forwards;
    animation: ripple 0.2s linear forwards;
}

[type="radio"].radio-col-brown:checked + label:after {
    background-color: #795548;
    border-color: #795548;
    -webkit-animation: ripple 0.2s linear forwards;
    animation: ripple 0.2s linear forwards;
}

[type="radio"].radio-col-grey:checked + label:after {
    background-color: #9e9e9e;
    border-color: #9e9e9e;
    -webkit-animation: ripple 0.2s linear forwards;
    animation: ripple 0.2s linear forwards;
}

[type="radio"].radio-col-blue-grey:checked + label:after {
    background-color: #607d8b;
    border-color: #607d8b;
    -webkit-animation: ripple 0.2s linear forwards;
    animation: ripple 0.2s linear forwards;
}

[type="radio"].radio-col-black:checked + label:after {
    background-color: #000000;
    border-color: #000000;
    -webkit-animation: ripple 0.2s linear forwards;
    animation: ripple 0.2s linear forwards;
}

[type="radio"].radio-col-white:checked + label:after {
    background-color: #ffffff;
    border-color: #ffffff;
    -webkit-animation: ripple 0.2s linear forwards;
    animation: ripple 0.2s linear forwards;
}

[type="radio"].with-gap.radio-col-red:checked + label:before {
    border: 2px solid #fb3a3a;
    -webkit-animation: ripple 0.2s linear forwards;
    animation: ripple 0.2s linear forwards;
}

[type="radio"].with-gap.radio-col-red:checked + label:after {
    background-color: #fb3a3a;
    border: 2px solid #fb3a3a;
    -webkit-animation: ripple 0.2s linear forwards;
    animation: ripple 0.2s linear forwards;
}

[type="radio"].with-gap.radio-col-pink:checked + label:before {
    border: 2px solid #e91e63;
    -webkit-animation: ripple 0.2s linear forwards;
    animation: ripple 0.2s linear forwards;
}

[type="radio"].with-gap.radio-col-pink:checked + label:after {
    background-color: #e91e63;
    border: 2px solid #e91e63;
    -webkit-animation: ripple 0.2s linear forwards;
    animation: ripple 0.2s linear forwards;
}

[type="radio"].with-gap.radio-col-purple:checked + label:before {
    border: 2px solid #7460ee;
    -webkit-animation: ripple 0.2s linear forwards;
    animation: ripple 0.2s linear forwards;
}

[type="radio"].with-gap.radio-col-purple:checked + label:after {
    background-color: #7460ee;
    border: 2px solid #7460ee;
    -webkit-animation: ripple 0.2s linear forwards;
    animation: ripple 0.2s linear forwards;
}

[type="radio"].with-gap.radio-col-deep-purple:checked + label:before {
    border: 2px solid #673ab7;
    -webkit-animation: ripple 0.2s linear forwards;
    animation: ripple 0.2s linear forwards;
}

[type="radio"].with-gap.radio-col-deep-purple:checked + label:after {
    background-color: #673ab7;
    border: 2px solid #673ab7;
    -webkit-animation: ripple 0.2s linear forwards;
    animation: ripple 0.2s linear forwards;
}

[type="radio"].with-gap.radio-col-indigo:checked + label:before {
    border: 2px solid #3f51b5;
    -webkit-animation: ripple 0.2s linear forwards;
    animation: ripple 0.2s linear forwards;
}

[type="radio"].with-gap.radio-col-indigo:checked + label:after {
    background-color: #3f51b5;
    border: 2px solid #3f51b5;
    -webkit-animation: ripple 0.2s linear forwards;
    animation: ripple 0.2s linear forwards;
}

[type="radio"].with-gap.radio-col-blue:checked + label:before {
    border: 2px solid #02bec9;
    -webkit-animation: ripple 0.2s linear forwards;
    animation: ripple 0.2s linear forwards;
}

[type="radio"].with-gap.radio-col-blue:checked + label:after {
    background-color: #02bec9;
    border: 2px solid #02bec9;
    -webkit-animation: ripple 0.2s linear forwards;
    animation: ripple 0.2s linear forwards;
}

[type="radio"].with-gap.radio-col-light-blue:checked + label:before {
    border: 2px solid #03a9f4;
    -webkit-animation: ripple 0.2s linear forwards;
    animation: ripple 0.2s linear forwards;
}

[type="radio"].with-gap.radio-col-light-blue:checked + label:after {
    background-color: #03a9f4;
    border: 2px solid #03a9f4;
    -webkit-animation: ripple 0.2s linear forwards;
    animation: ripple 0.2s linear forwards;
}

[type="radio"].with-gap.radio-col-cyan:checked + label:before {
    border: 2px solid #00bcd4;
    -webkit-animation: ripple 0.2s linear forwards;
    animation: ripple 0.2s linear forwards;
}

[type="radio"].with-gap.radio-col-cyan:checked + label:after {
    background-color: #00bcd4;
    border: 2px solid #00bcd4;
    -webkit-animation: ripple 0.2s linear forwards;
    animation: ripple 0.2s linear forwards;
}

[type="radio"].with-gap.radio-col-teal:checked + label:before {
    border: 2px solid #009688;
    -webkit-animation: ripple 0.2s linear forwards;
    animation: ripple 0.2s linear forwards;
}

[type="radio"].with-gap.radio-col-teal:checked + label:after {
    background-color: #009688;
    border: 2px solid #009688;
    -webkit-animation: ripple 0.2s linear forwards;
    animation: ripple 0.2s linear forwards;
}

[type="radio"].with-gap.radio-col-green:checked + label:before {
    border: 2px solid #26c6da;
    -webkit-animation: ripple 0.2s linear forwards;
    animation: ripple 0.2s linear forwards;
}

[type="radio"].with-gap.radio-col-green:checked + label:after {
    background-color: #26c6da;
    border: 2px solid #26c6da;
    -webkit-animation: ripple 0.2s linear forwards;
    animation: ripple 0.2s linear forwards;
}

[type="radio"].with-gap.radio-col-light-green:checked + label:before {
    border: 2px solid #8bc34a;
    -webkit-animation: ripple 0.2s linear forwards;
    animation: ripple 0.2s linear forwards;
}

[type="radio"].with-gap.radio-col-light-green:checked + label:after {
    background-color: #8bc34a;
    border: 2px solid #8bc34a;
    -webkit-animation: ripple 0.2s linear forwards;
    animation: ripple 0.2s linear forwards;
}

[type="radio"].with-gap.radio-col-lime:checked + label:before {
    border: 2px solid #cddc39;
    -webkit-animation: ripple 0.2s linear forwards;
    animation: ripple 0.2s linear forwards;
}

[type="radio"].with-gap.radio-col-lime:checked + label:after {
    background-color: #cddc39;
    border: 2px solid #cddc39;
    -webkit-animation: ripple 0.2s linear forwards;
    animation: ripple 0.2s linear forwards;
}

[type="radio"].with-gap.radio-col-yellow:checked + label:before {
    border: 2px solid #ffe821;
    -webkit-animation: ripple 0.2s linear forwards;
    animation: ripple 0.2s linear forwards;
}

[type="radio"].with-gap.radio-col-yellow:checked + label:after {
    background-color: #ffe821;
    border: 2px solid #ffe821;
    -webkit-animation: ripple 0.2s linear forwards;
    animation: ripple 0.2s linear forwards;
}

[type="radio"].with-gap.radio-col-amber:checked + label:before {
    border: 2px solid #ffc107;
    -webkit-animation: ripple 0.2s linear forwards;
    animation: ripple 0.2s linear forwards;
}

[type="radio"].with-gap.radio-col-amber:checked + label:after {
    background-color: #ffc107;
    border: 2px solid #ffc107;
    -webkit-animation: ripple 0.2s linear forwards;
    animation: ripple 0.2s linear forwards;
}

[type="radio"].with-gap.radio-col-orange:checked + label:before {
    border: 2px solid #ff9800;
    -webkit-animation: ripple 0.2s linear forwards;
    animation: ripple 0.2s linear forwards;
}

[type="radio"].with-gap.radio-col-orange:checked + label:after {
    background-color: #ff9800;
    border: 2px solid #ff9800;
    -webkit-animation: ripple 0.2s linear forwards;
    animation: ripple 0.2s linear forwards;
}

[type="radio"].with-gap.radio-col-deep-orange:checked + label:before {
    border: 2px solid #ff5722;
    -webkit-animation: ripple 0.2s linear forwards;
    animation: ripple 0.2s linear forwards;
}

[type="radio"].with-gap.radio-col-deep-orange:checked + label:after {
    background-color: #ff5722;
    border: 2px solid #ff5722;
    -webkit-animation: ripple 0.2s linear forwards;
    animation: ripple 0.2s linear forwards;
}

[type="radio"].with-gap.radio-col-brown:checked + label:before {
    border: 2px solid #795548;
    -webkit-animation: ripple 0.2s linear forwards;
    animation: ripple 0.2s linear forwards;
}

[type="radio"].with-gap.radio-col-brown:checked + label:after {
    background-color: #795548;
    border: 2px solid #795548;
    -webkit-animation: ripple 0.2s linear forwards;
    animation: ripple 0.2s linear forwards;
}

[type="radio"].with-gap.radio-col-grey:checked + label:before {
    border: 2px solid #9e9e9e;
    -webkit-animation: ripple 0.2s linear forwards;
    animation: ripple 0.2s linear forwards;
}

[type="radio"].with-gap.radio-col-grey:checked + label:after {
    background-color: #9e9e9e;
    border: 2px solid #9e9e9e;
    -webkit-animation: ripple 0.2s linear forwards;
    animation: ripple 0.2s linear forwards;
}

[type="radio"].with-gap.radio-col-blue-grey:checked + label:before {
    border: 2px solid #607d8b;
    -webkit-animation: ripple 0.2s linear forwards;
    animation: ripple 0.2s linear forwards;
}

[type="radio"].with-gap.radio-col-blue-grey:checked + label:after {
    background-color: #607d8b;
    border: 2px solid #607d8b;
    -webkit-animation: ripple 0.2s linear forwards;
    animation: ripple 0.2s linear forwards;
}

[type="radio"].with-gap.radio-col-black:checked + label:before {
    border: 2px solid #000000;
    -webkit-animation: ripple 0.2s linear forwards;
    animation: ripple 0.2s linear forwards;
}

[type="radio"].with-gap.radio-col-black:checked + label:after {
    background-color: #000000;
    border: 2px solid #000000;
    -webkit-animation: ripple 0.2s linear forwards;
    animation: ripple 0.2s linear forwards;
}

[type="radio"].with-gap.radio-col-white:checked + label:before {
    border: 2px solid #ffffff;
    -webkit-animation: ripple 0.2s linear forwards;
    animation: ripple 0.2s linear forwards;
}

[type="radio"].with-gap.radio-col-white:checked + label:after {
    background-color: #ffffff;
    border: 2px solid #ffffff;
    -webkit-animation: ripple 0.2s linear forwards;
    animation: ripple 0.2s linear forwards;
}

.switch label {
    font-weight: normal;
    font-size: 13px;
}
.switch label .lever {
    margin: 0 14px;
}
.switch label input[type="checkbox"]:checked:not(:disabled) ~ .lever.switch-col-red:active:after {
    box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(251, 58, 58, 0.1);
}
.switch label input[type="checkbox"]:checked + .lever.switch-col-red {
    background-color: rgba(251, 58, 58, 0.5);
}
.switch label input[type="checkbox"]:checked + .lever.switch-col-red:after {
    background-color: #fb3a3a;
}
.switch label input[type="checkbox"]:checked:not(:disabled) ~ .lever.switch-col-pink:active:after {
    box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(233, 30, 99, 0.1);
}
.switch label input[type="checkbox"]:checked + .lever.switch-col-pink {
    background-color: rgba(233, 30, 99, 0.5);
}
.switch label input[type="checkbox"]:checked + .lever.switch-col-pink:after {
    background-color: #e91e63;
}
.switch label input[type="checkbox"]:checked:not(:disabled) ~ .lever.switch-col-purple:active:after {
    box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(116, 96, 238, 0.1);
}
.switch label input[type="checkbox"]:checked + .lever.switch-col-purple {
    background-color: rgba(116, 96, 238, 0.5);
}
.switch label input[type="checkbox"]:checked + .lever.switch-col-purple:after {
    background-color: #7460ee;
}
.switch label input[type="checkbox"]:checked:not(:disabled) ~ .lever.switch-col-deep-purple:active:after {
    box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(103, 58, 183, 0.1);
}
.switch label input[type="checkbox"]:checked + .lever.switch-col-deep-purple {
    background-color: rgba(103, 58, 183, 0.5);
}
.switch label input[type="checkbox"]:checked + .lever.switch-col-deep-purple:after {
    background-color: #673ab7;
}
.switch label input[type="checkbox"]:checked:not(:disabled) ~ .lever.switch-col-indigo:active:after {
    box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(63, 81, 181, 0.1);
}
.switch label input[type="checkbox"]:checked + .lever.switch-col-indigo {
    background-color: rgba(63, 81, 181, 0.5);
}
.switch label input[type="checkbox"]:checked + .lever.switch-col-indigo:after {
    background-color: #3f51b5;
}
.switch label input[type="checkbox"]:checked:not(:disabled) ~ .lever.switch-col-blue:active:after {
    box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(2, 190, 201, 0.1);
}
.switch label input[type="checkbox"]:checked + .lever.switch-col-blue {
    background-color: rgba(2, 190, 201, 0.5);
}
.switch label input[type="checkbox"]:checked + .lever.switch-col-blue:after {
    background-color: #02bec9;
}
.switch label input[type="checkbox"]:checked:not(:disabled) ~ .lever.switch-col-light-blue:active:after {
    box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(3, 169, 244, 0.1);
}
.switch label input[type="checkbox"]:checked + .lever.switch-col-light-blue {
    background-color: rgba(3, 169, 244, 0.5);
}
.switch label input[type="checkbox"]:checked + .lever.switch-col-light-blue:after {
    background-color: #03a9f4;
}
.switch label input[type="checkbox"]:checked:not(:disabled) ~ .lever.switch-col-cyan:active:after {
    box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(0, 188, 212, 0.1);
}
.switch label input[type="checkbox"]:checked + .lever.switch-col-cyan {
    background-color: rgba(0, 188, 212, 0.5);
}
.switch label input[type="checkbox"]:checked + .lever.switch-col-cyan:after {
    background-color: #00bcd4;
}
.switch label input[type="checkbox"]:checked:not(:disabled) ~ .lever.switch-col-teal:active:after {
    box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(0, 150, 136, 0.1);
}
.switch label input[type="checkbox"]:checked + .lever.switch-col-teal {
    background-color: rgba(0, 150, 136, 0.5);
}
.switch label input[type="checkbox"]:checked + .lever.switch-col-teal:after {
    background-color: #009688;
}
.switch label input[type="checkbox"]:checked:not(:disabled) ~ .lever.switch-col-green:active:after {
    box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(38, 198, 218, 0.1);
}
.switch label input[type="checkbox"]:checked + .lever.switch-col-green {
    background-color: rgba(38, 198, 218, 0.5);
}
.switch label input[type="checkbox"]:checked + .lever.switch-col-green:after {
    background-color: #26c6da;
}
.switch label input[type="checkbox"]:checked:not(:disabled) ~ .lever.switch-col-light-green:active:after {
    box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(139, 195, 74, 0.1);
}
.switch label input[type="checkbox"]:checked + .lever.switch-col-light-green {
    background-color: rgba(139, 195, 74, 0.5);
}
.switch label input[type="checkbox"]:checked + .lever.switch-col-light-green:after {
    background-color: #8bc34a;
}
.switch label input[type="checkbox"]:checked:not(:disabled) ~ .lever.switch-col-lime:active:after {
    box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(205, 220, 57, 0.1);
}
.switch label input[type="checkbox"]:checked + .lever.switch-col-lime {
    background-color: rgba(205, 220, 57, 0.5);
}
.switch label input[type="checkbox"]:checked + .lever.switch-col-lime:after {
    background-color: #cddc39;
}
.switch label input[type="checkbox"]:checked:not(:disabled) ~ .lever.switch-col-yellow:active:after {
    box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(255, 232, 33, 0.1);
}
.switch label input[type="checkbox"]:checked + .lever.switch-col-yellow {
    background-color: rgba(255, 232, 33, 0.5);
}
.switch label input[type="checkbox"]:checked + .lever.switch-col-yellow:after {
    background-color: #ffe821;
}
.switch label input[type="checkbox"]:checked:not(:disabled) ~ .lever.switch-col-amber:active:after {
    box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(255, 193, 7, 0.1);
}
.switch label input[type="checkbox"]:checked + .lever.switch-col-amber {
    background-color: rgba(255, 193, 7, 0.5);
}
.switch label input[type="checkbox"]:checked + .lever.switch-col-amber:after {
    background-color: #ffc107;
}
.switch label input[type="checkbox"]:checked:not(:disabled) ~ .lever.switch-col-orange:active:after {
    box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(255, 152, 0, 0.1);
}
.switch label input[type="checkbox"]:checked + .lever.switch-col-orange {
    background-color: rgba(255, 152, 0, 0.5);
}
.switch label input[type="checkbox"]:checked + .lever.switch-col-orange:after {
    background-color: #ff9800;
}
.switch label input[type="checkbox"]:checked:not(:disabled) ~ .lever.switch-col-deep-orange:active:after {
    box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(255, 87, 34, 0.1);
}
.switch label input[type="checkbox"]:checked + .lever.switch-col-deep-orange {
    background-color: rgba(255, 87, 34, 0.5);
}
.switch label input[type="checkbox"]:checked + .lever.switch-col-deep-orange:after {
    background-color: #ff5722;
}
.switch label input[type="checkbox"]:checked:not(:disabled) ~ .lever.switch-col-brown:active:after {
    box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(121, 85, 72, 0.1);
}
.switch label input[type="checkbox"]:checked + .lever.switch-col-brown {
    background-color: rgba(121, 85, 72, 0.5);
}
.switch label input[type="checkbox"]:checked + .lever.switch-col-brown:after {
    background-color: #795548;
}
.switch label input[type="checkbox"]:checked:not(:disabled) ~ .lever.switch-col-grey:active:after {
    box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(158, 158, 158, 0.1);
}
.switch label input[type="checkbox"]:checked + .lever.switch-col-grey {
    background-color: rgba(158, 158, 158, 0.5);
}
.switch label input[type="checkbox"]:checked + .lever.switch-col-grey:after {
    background-color: #9e9e9e;
}
.switch label input[type="checkbox"]:checked:not(:disabled) ~ .lever.switch-col-blue-grey:active:after {
    box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(96, 125, 139, 0.1);
}
.switch label input[type="checkbox"]:checked + .lever.switch-col-blue-grey {
    background-color: rgba(96, 125, 139, 0.5);
}
.switch label input[type="checkbox"]:checked + .lever.switch-col-blue-grey:after {
    background-color: #607d8b;
}
.switch label input[type="checkbox"]:checked:not(:disabled) ~ .lever.switch-col-black:active:after {
    box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(0, 0, 0, 0.1);
}
.switch label input[type="checkbox"]:checked + .lever.switch-col-black {
    background-color: rgba(0, 0, 0, 0.5);
}
.switch label input[type="checkbox"]:checked + .lever.switch-col-black:after {
    background-color: #000000;
}
.switch label input[type="checkbox"]:checked:not(:disabled) ~ .lever.switch-col-white:active:after {
    box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(255, 255, 255, 0.1);
}
.switch label input[type="checkbox"]:checked + .lever.switch-col-white {
    background-color: rgba(255, 255, 255, 0.5);
}
.switch label input[type="checkbox"]:checked + .lever.switch-col-white:after {
    background-color: #ffffff;
}

/*Form Validation*/
.help-block ul {
    padding: 0px;
    margin: 0px;
}
.help-block ul li {
    list-style: none;
}

.error .form-control {
    border-color: #ff5c6c;
}

.validate .form-control {
    border-color: #24d2b5;
}

/*
Template Name: Admin pro Admin
Author: Wrappixel
Email: niravjoshi87@gmail.com
File: scss
*/
/*
Template Name: Admin Pro Admin
Author: Wrappixel
Email: niravjoshi87@gmail.com
File: scss
*/
/*Theme Colors*/
/*bootstrap Color*/
/*Light colors*/
/*Normal Color*/
/*Extra Variable*/
/*******************
Table Cell
*******************/
.table-box {
    display: table;
    width: 100%;
}

.table.no-border tbody td {
    border: 0px;
}

.cell {
    display: table-cell;
    vertical-align: middle;
}

.table td,
.table th {
    border-color: #f3f1f1;
}

.table thead th,
.table th {
    font-weight: 500;
}

.table-hover tbody tr:hover {
    background: rgba(0, 0, 0, 0.02);
}

.nowrap {
    white-space: nowrap;
}

.lite-padding td {
    padding: 5px;
}

.v-middle td,
.v-middle th {
    vertical-align: middle;
}

/*******************
Table-Layout
******************/
.table thead th,
.table th {
    border: 0px;
}

.color-table.primary-table thead th {
    background-color: #6772e5;
    color: #ffffff;
}

.table-striped tbody tr:nth-of-type(odd) {
    background: #e9edf2;  
}
.table-striped tbody tr{
    display: table-row;
}

.color-table.success-table thead th {
    background-color: #24d2b5;
    color: #ffffff;
}

.color-table.info-table thead th {
    background-color: #20aee3;
    color: #ffffff;
}

.color-table.warning-table thead th {
    background-color: #ff9041;
    color: #ffffff;
}

.color-table.danger-table thead th {
    background-color: #ff5c6c;
    color: #ffffff;
}

.color-table.inverse-table thead th {
    background-color: #2f3d4a;
    color: #ffffff;
}

.color-table.dark-table thead th {
    background-color: #263238;
    color: #ffffff;
}

.color-table.red-table thead th {
    background-color: #fb3a3a;
    color: #ffffff;
}

.color-table.purple-table thead th {
    background-color: #7460ee;
    color: #ffffff;
}

.color-table.muted-table thead th {
    background-color: #bcc3d3;
    color: #ffffff;
}

.color-bordered-table.primary-bordered-table {
    border: 2px solid #6772e5;
}
.color-bordered-table.primary-bordered-table thead th {
    background-color: #6772e5;
    color: #ffffff;
}

.color-bordered-table.success-bordered-table {
    border: 2px solid #24d2b5;
}
.color-bordered-table.success-bordered-table thead th {
    background-color: #24d2b5;
    color: #ffffff;
}

.color-bordered-table.info-bordered-table {
    border: 2px solid #20aee3;
}
.color-bordered-table.info-bordered-table thead th {
    background-color: #20aee3;
    color: #ffffff;
}

.color-bordered-table.warning-bordered-table {
    border: 2px solid #ff9041;
}
.color-bordered-table.warning-bordered-table thead th {
    background-color: #ff9041;
    color: #ffffff;
}

.color-bordered-table.danger-bordered-table {
    border: 2px solid #ff5c6c;
}
.color-bordered-table.danger-bordered-table thead th {
    background-color: #ff5c6c;
    color: #ffffff;
}

.color-bordered-table.inverse-bordered-table {
    border: 2px solid #2f3d4a;
}
.color-bordered-table.inverse-bordered-table thead th {
    background-color: #2f3d4a;
    color: #ffffff;
}

.color-bordered-table.dark-bordered-table {
    border: 2px solid #263238;
}
.color-bordered-table.dark-bordered-table thead th {
    background-color: #263238;
    color: #ffffff;
}

.color-bordered-table.red-bordered-table {
    border: 2px solid #fb3a3a;
}
.color-bordered-table.red-bordered-table thead th {
    background-color: #fb3a3a;
    color: #ffffff;
}

.color-bordered-table.purple-bordered-table {
    border: 2px solid #7460ee;
}
.color-bordered-table.purple-bordered-table thead th {
    background-color: #7460ee;
    color: #ffffff;
}

.color-bordered-table.muted-bordered-table {
    border: 2px solid #bcc3d3;
}
.color-bordered-table.muted-bordered-table thead th {
    background-color: #bcc3d3;
    color: #ffffff;
}

.full-color-table.full-primary-table {
    background-color: #f1effd;
}
.full-color-table.full-primary-table thead th {
    background-color: #6772e5;
    border: 0;
    color: #ffffff;
}
.full-color-table.full-primary-table tbody td {
    border: 0;
}
.full-color-table.full-primary-table tr:hover {
    background-color: #6772e5;
    color: #ffffff;
}

.full-color-table.full-success-table {
    background-color: #e8fdeb;
}
.full-color-table.full-success-table thead th {
    background-color: #24d2b5;
    border: 0;
    color: #ffffff;
}
.full-color-table.full-success-table tbody td {
    border: 0;
}
.full-color-table.full-success-table tr:hover {
    background-color: #24d2b5;
    color: #ffffff;
}

.full-color-table.full-info-table {
    background-color: #cfecfe;
}
.full-color-table.full-info-table thead th {
    background-color: #20aee3;
    border: 0;
    color: #ffffff;
}
.full-color-table.full-info-table tbody td {
    border: 0;
}
.full-color-table.full-info-table tr:hover {
    background-color: #20aee3;
    color: #ffffff;
}

.full-color-table.full-warning-table {
    background-color: #fff8ec;
}
.full-color-table.full-warning-table thead th {
    background-color: #ff9041;
    border: 0;
    color: #ffffff;
}
.full-color-table.full-warning-table tbody td {
    border: 0;
}
.full-color-table.full-warning-table tr:hover {
    background-color: #ff9041;
    color: #ffffff;
}

.full-color-table.full-danger-table {
    background-color: #f9e7eb;
}
.full-color-table.full-danger-table thead th {
    background-color: #ff5c6c;
    border: 0;
    color: #ffffff;
}
.full-color-table.full-danger-table tbody td {
    border: 0;
}
.full-color-table.full-danger-table tr:hover {
    background-color: #ff5c6c;
    color: #ffffff;
}

.full-color-table.full-inverse-table {
    background-color: #f6f6f6;
}
.full-color-table.full-inverse-table thead th {
    background-color: #2f3d4a;
    border: 0;
    color: #ffffff;
}
.full-color-table.full-inverse-table tbody td {
    border: 0;
}
.full-color-table.full-inverse-table tr:hover {
    background-color: #2f3d4a;
    color: #ffffff;
}

.full-color-table.full-dark-table {
    background-color: rgba(43, 43, 43, 0.8);
}
.full-color-table.full-dark-table thead th {
    background-color: #263238;
    border: 0;
    color: #ffffff;
}
.full-color-table.full-dark-table tbody td {
    border: 0;
    color: #ffffff;
}
.full-color-table.full-dark-table tr:hover {
    background-color: #263238;
    color: #ffffff;
}

.full-color-table.full-red-table {
    background-color: #f9e7eb;
}
.full-color-table.full-red-table thead th {
    background-color: #fb3a3a;
    border: 0;
    color: #ffffff;
}
.full-color-table.full-red-table tbody td {
    border: 0;
}
.full-color-table.full-red-table tr:hover {
    background-color: #fb3a3a;
    color: #ffffff;
}

.full-color-table.full-purple-table {
    background-color: #f1effd;
}
.full-color-table.full-purple-table thead th {
    background-color: #7460ee;
    border: 0;
    color: #ffffff;
}
.full-color-table.full-purple-table tbody td {
    border: 0;
}
.full-color-table.full-purple-table tr:hover {
    background-color: #7460ee;
    color: #ffffff;
}

.full-color-table.full-muted-table {
    background-color: rgba(152, 166, 173, 0.2);
}
.full-color-table.full-muted-table thead th {
    background-color: #bcc3d3;
    border: 0;
    color: #ffffff;
}
.full-color-table.full-muted-table tbody td {
    border: 0;
}
.full-color-table.full-muted-table tr:hover {
    background-color: #bcc3d3;
    color: #ffffff;
}

/*******************
Table-Data Table
******************/
.dataTables_wrapper {
    padding-top: 10px;
}

.dt-buttons {
    display: inline-block;
    padding-top: 5px;
    margin-bottom: 15px;
}
.dt-buttons .dt-button {
    padding: 8px 20px;
    border-radius: 4px;
    background: #17a2b8;
    color: #ffffff;
    margin-right: 3px;
    font-size: 13px;
    text-transform: capitalize;
    letter-spacing: .6px;
    transition:.3s;
}
.dt-buttons .dt-button {
  text-decoration: none;  
}
.dt-buttons .dt-button:hover {
    background: #2f3d4a;
}

.dataTables_info,
.dataTables_length {
    display: inline-block;
}

.dataTables_length {
    margin-top: 10px;
}
.dataTables_length select {
    border: 0;
    background-image: linear-gradient(#20aee3, #20aee3), linear-gradient(#b1b8bb, #b1b8bb);
    background-size: 0 2px, 100% 1px;
    background-repeat: no-repeat;
    background-position: center bottom, center calc(100% - 1px);
    background-color: transparent;
    transition: background 0s ease-out;
    padding-bottom: 5px;
}
.dataTables_length select:focus {
    outline: none;
    background-image: linear-gradient(#20aee3, #20aee3), linear-gradient(#b1b8bb, #b1b8bb);
    background-size: 100% 2px, 100% 1px;
    box-shadow: none;
    transition-duration: 0.3s;
}

.dataTables_filter {
    float: right;
    margin-top: 10px;
}
.dataTables_filter input {
    border: 0;
    background-image: linear-gradient(#20aee3, #20aee3), linear-gradient(#b1b8bb, #b1b8bb);
    background-size: 0 2px, 100% 1px;
    background-repeat: no-repeat;
    background-position: center bottom, center calc(100% - 1px);
    background-color: transparent;
    transition: background 0s ease-out;
    float: none;
    box-shadow: none;
    border-radius: 0;
    margin-left: 10px;
}
.dataTables_filter input:focus {
    outline: none;
    background-image: linear-gradient(#20aee3, #20aee3), linear-gradient(#b1b8bb, #b1b8bb);
    background-size: 100% 2px, 100% 1px;
    box-shadow: none;
    transition-duration: 0.3s;
}

table.dataTable thead .sorting,
table.dataTable thead .sorting_asc,
table.dataTable thead .sorting_desc,
table.dataTable thead .sorting_asc_disabled,
table.dataTable thead .sorting_desc_disabled {
    background: transparent;
}

/* table.dataTable thead .sorting_asc:after {
    content: "\f0de";
    margin-left: 10px;
    font-family: fontawesome;
    cursor: pointer;
} */

table.dataTable thead .sorting_desc:after {
    content: "\f0dd";
    margin-left: 10px;
    font-family: fontawesome;
    cursor: pointer;
}

/* table.dataTable thead .sorting:after {
    content: "\f0dc";
    margin-left: 10px;
    font-family: fontawesome !important;
    cursor: pointer;
    color: rgba(50, 50, 50, 0.5);
} */

.dataTables_wrapper .dataTables_paginate {
    float: right;
    text-align: right;
    padding-top: 0.25em;
}

.dataTables_wrapper .dataTables_paginate .paginate_button {
    box-sizing: border-box;
    display: inline-block;
    min-width: 1.5em;
    padding: 0.5em 1em;
    text-align: center;
    text-decoration: none;
    cursor: pointer;

    color: #67757c;
    border: 1px solid #ddd;
}

.dataTables_wrapper .dataTables_paginate .paginate_button.current,
.dataTables_wrapper .dataTables_paginate .paginate_button.current:hover {
    color: #ffffff !important;
    border: 1px solid #20aee3;
    background-color: #20aee3;
}

.dataTables_wrapper .dataTables_paginate .paginate_button.disabled,
.dataTables_wrapper .dataTables_paginate .paginate_button.disabled:hover,
.dataTables_wrapper .dataTables_paginate .paginate_button.disabled:active {
    cursor: default;
    color: #67757c;
    border: 1px solid #ddd;
    background: transparent;
    box-shadow: none;
}

.dataTables_wrapper .dataTables_paginate .paginate_button:hover {
    color: white;
    border: 1px solid #20aee3;
    background-color: #20aee3;
}

.dataTables_wrapper .dataTables_paginate .paginate_button:active {
    outline: none;
    background-color: #67757c;
}

.dataTables_wrapper .dataTables_paginate .ellipsis {
    padding: 0 1em;
}

/*******************
Table- responsive
******************/
.tablesaw-bar .btn-group label {
    color: #67757c !important;
}

/*******************
Table- editable table
******************/
.dt-bootstrap {
    display: block;
}

.paging_simple_numbers .pagination .paginate_button {
    padding: 0px;
    background: #ffffff;
}
.paging_simple_numbers .pagination .paginate_button:hover {
    background: #ffffff;
}
.paging_simple_numbers .pagination .paginate_button a {
    padding: 3px 10px;
    border: 0px;
}
.paging_simple_numbers .pagination .paginate_button.active a,
.paging_simple_numbers .pagination .paginate_button:hover a {
    background: #20aee3;
    color: #ffffff;
}

.stylish-table tbody tr {
    border-left: 3px solid transparent;
}
.stylish-table tbody tr:hover,
.stylish-table tbody tr.active {
    border-color: #20aee3;
}

/*******************
Table-Jsgrid table
******************/
.jsgrid-pager-page a,
.jsgrid-pager-current-page,
.jsgrid-pager-nav-button a {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    display: inline-block;
    min-width: 1.5em;
    padding: 0.5em 1em;
    text-align: center;
    text-decoration: none;
    cursor: pointer;
    color: #67757c;
    border: 1px solid #ddd;
}

.jsgrid-pager-page a:hover,
.jsgrid-pager-nav-button a:hover {
    background-color: #20aee3;
    color: #ffffff;
}

.jsgrid-pager-current-page {
    background-color: #20aee3;
    color: #ffffff;
}

.jsgrid-pager-page,
.jsgrid-pager-nav-button {
    padding: 0;
}

.jsgrid-pager-page.jsgrid-pager-current-page {
    padding: 0.5em 1em !important;
}

.ps {
    -ms-touch-action: auto;
    touch-action: auto;
    overflow: hidden !important;
    -ms-overflow-style: none;
}

@supports (-ms-overflow-style: none) {
    .ps {
        overflow: auto !important;
    }
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .ps {
        overflow: auto !important;
    }
}

.ps.ps--active-x > .ps__scrollbar-x-rail,
.ps.ps--active-y > .ps__scrollbar-y-rail {
    display: block;
    background-color: transparent;
}

.ps.ps--in-scrolling.ps--x > .ps__scrollbar-x-rail {
    background-color: #eee;
    opacity: 0.9;
}

.ps.ps--in-scrolling.ps--y > .ps__scrollbar-y-rail > .ps__scrollbar-y {
    background-color: transparent;
    width: 8px;
}

.ps > .ps__scrollbar-x-rail {
    display: none;
    position: absolute;
    /* please don't change 'position' */
    opacity: 0;
    bottom: 0px;
    /* there must be 'bottom' for ps__scrollbar-x-rail */
    height: 6px;
}

.ps > .ps__scrollbar-x-rail > .ps__scrollbar-x {
    position: absolute;
    /* please don't change 'position' */
    background-color: rgba(255, 255, 255, 0.2);
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    border-radius: 6px;
    bottom: 2px;
    /* there must be 'bottom' for ps__scrollbar-x */
    height: 6px;
}

.ps > .ps__scrollbar-x-rail:hover > .ps__scrollbar-x,
.ps > .ps__scrollbar-x-rail:active > .ps__scrollbar-x {
    height: 6px;
}

.ps > .ps__scrollbar-y-rail {
    display: none;
    position: absolute;
    /* please don't change 'position' */
    opacity: 0;
    right: 0;
    /* there must be 'right' for ps__scrollbar-y-rail */
    width: 6px;
}

.ps > .ps__scrollbar-y-rail > .ps__scrollbar-y {
    position: absolute;
    /* please don't change 'position' */
    background-color: rgba(0, 0, 0, 0.2);
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    border-radius: 6px;
    right: 2px;
    /* there must be 'right' for ps__scrollbar-y */
    width: 6px;
}

.ps > .ps__scrollbar-y-rail:hover > .ps__scrollbar-y,
.ps > .ps__scrollbar-y-rail:active > .ps__scrollbar-y {
    width: 6px;
}

.ps:hover.ps--in-scrolling.ps--x > .ps__scrollbar-x-rail {
    background-color: rgba(0, 0, 0, 0.2);
    opacity: 0.9;
}

.ps:hover.ps--in-scrolling.ps--x > .ps__scrollbar-x-rail > .ps__scrollbar-x {
    background-color: rgba(0, 0, 0, 0.2);
    height: 6px;
}

.ps:hover.ps--in-scrolling.ps--y > .ps__scrollbar-y-rail > .ps__scrollbar-y {
    background-color: rgba(0, 0, 0, 0.2);
    width: 6px;
}

.ps:hover > .ps__scrollbar-x-rail,
.ps:hover > .ps__scrollbar-y-rail {
    opacity: 0.6;
}

.ps:hover > .ps__scrollbar-x-rail:hover > .ps__scrollbar-x {
    background-color: rgba(0, 0, 0, 0.2);
}

.ps:hover > .ps__scrollbar-y-rail:hover > .ps__scrollbar-y {
    background-color: rgba(0, 0, 0, 0.2);
}

#slimtest3.ps .ps__scrollbar-y-rail {
    left: 3px;
    right: auto;
}

#slimtest4.ps > .ps__scrollbar-y-rail > .ps__scrollbar-y {
    background-color: #20aee3;
}

#slimtest4.ps:hover.ps--in-scrolling.ps--y > .ps__scrollbar-y-rail > .ps__scrollbar-y {
    background-color: #20aee3;
}

#slimtest4.ps:hover > .ps__scrollbar-y-rail:hover > .ps__scrollbar-y {
    background-color: #20aee3;
}

/*
Template Name: Admin Pro Admin
Author: Wrappixel
Email: niravjoshi87@gmail.com
File: scss
*/
/*
Template Name: Admin Pro Admin
Author: Wrappixel
Email: niravjoshi87@gmail.com
File: scss
*/
/*******************
/*User mail widgets*/
/*******************/
/* .topbar .top-navbar .mailbox {
    width: 300px;
} */
.topbar .top-navbar .mailbox ul {
    padding: 0px;
}
.topbar .top-navbar .mailbox ul li {
    list-style: none;
}

.mailbox ul li .drop-title {
    font-weight: 500;
    padding: 11px 20px 15px;
    border-bottom: 1px solid rgba(120, 130, 140, 0.13);
}

.mailbox ul li .nav-link {
    border-top: 1px solid rgba(120, 130, 140, 0.13);
    padding-top: 15px;
}

.mailbox .message-center {
    height: 317px;
    overflow: auto;
    position: relative;
}
.mailbox .message-center a {
    border-bottom: 1px solid rgba(120, 130, 140, 0.13);
    display: block;
    text-decoration: none;
    padding: 9px 15px;
}
.mailbox .message-center a:hover {
    background: #e9edf2;
}
.mailbox .message-center a div {
    white-space: normal;
}
.mailbox .message-center a .user-img {
    width: 40px;
    position: relative;
    display: inline-block;
    margin: 0 10px 15px 0;
}
.mailbox .message-center a .user-img img {
    width: 100%;
}
.mailbox .message-center a .user-img .profile-status {
    border: 2px solid #ffffff;
    border-radius: 50%;
    display: inline-block;
    height: 10px;
    left: 30px;
    position: absolute;
    top: 1px;
    width: 10px;
}
.mailbox .message-center a .user-img .online {
    background: #24d2b5;
}
.mailbox .message-center a .user-img .busy {
    background: #ff5c6c;
}
.mailbox .message-center a .user-img .away {
    background: #ff9041;
}
.mailbox .message-center a .user-img .offline {
    background: #ff9041;
}
.mailbox .message-center a .mail-contnet {
    display: inline-block;
    width: 75%;
    padding-left: 10px;
    vertical-align: middle;
}
.mailbox .message-center a .mail-contnet h5 {
    margin: 5px 0px 0;
}
.mailbox .message-center a .mail-contnet .mail-desc,
.mailbox .message-center a .mail-contnet .time {
    font-size: 12px;
    display: block;
    margin: 1px 0;
    text-overflow: ellipsis;
    overflow: hidden;
    color: #67757c;
    white-space: nowrap;
}

/*******************/
/*Weather*/
/*******************/
.city-weather-days {
    margin: 0px;
}
.city-weather-days li {
    text-align: center;
    padding: 15px 0;
}
.city-weather-days li span {
    display: block;
    padding: 10px 0 0;
    color: #bcc3d3;
}
.city-weather-days li i {
    display: block;
    font-size: 20px;
    color: #20aee3;
}
.city-weather-days li h3 {
    font-weight: 300;
    margin-top: 5px;
}

/*Weather small*/
.weather-small h1 {
    line-height: 30px;
}

.weather-small sup {
    font-size: 60%;
}

/*******************/
/*Comment widgets*/
/*******************/
.comment-widgets {
    position: relative;
    margin-bottom: 10px;
}
.comment-widgets .comment-row {
    border-bottom: 1px solid transparent;
    padding: 15px;
}
.comment-widgets .comment-row:last-child {
    border-bottom: 0px;
}
.comment-widgets .comment-row:hover,
.comment-widgets .comment-row.active {
    background: rgba(0, 0, 0, 0.02);
}

.comment-text {
    padding: 15px 15px 15px 20px;
    width: 80%;
}
.comment-text:hover .comment-footer .action-icons,
.comment-text.active .comment-footer .action-icons {
    visibility: visible;
}
.comment-text p {
    max-height: 65px;
    width: 100%;
    overflow: hidden;
}

.comment-footer .action-icons {
    visibility: hidden;
}
.comment-footer .action-icons a {
    padding-left: 7px;
    vertical-align: middle;
    color: #bcc3d3;
}
.comment-footer .action-icons a:hover,
.comment-footer .action-icons a.active {
    color: #20aee3;
}

/*******************/
/*To do widgets*/
/*******************/
.todo-list li {
    border: 0px;
    margin-bottom: 0px;
    padding: 20px 15px 15px 0px;
}
.todo-list li .checkbox {
    width: 100%;
}
.todo-list li .checkbox label {
    font-weight: 400;
    padding-left: 30px;
    width: 100%;
}
.todo-list li .checkbox label span:first-child {
    width: 80%;
    display: inline-block;
    line-height: 25px;
}
.todo-list li:last-child {
    border-bottom: 0px;
}
.todo-list li .assignedto {
    padding: 0px 0 0 30px;
    margin: 0px;
}
.todo-list li .assignedto li {
    list-style: none;
    padding: 0px;
    display: inline-block;
    border: 0px;
    margin-right: 2px;
}
.todo-list li .assignedto li img {
    width: 30px;
    border-radius: 100%;
}
.todo-list li .item-date {
    padding-left: 30px;
    font-size: 12px;
    margin-top: 5px;
    display: inline-block;
}

.list-task .task-done span {
    text-decoration: line-through;
}

/*******************/
/*Chat widget*/
/*******************/
.chat-list {
    margin: 0px;
    padding: 0px;
}
.chat-list li {
    list-style: none;
    margin-top: 30px;
}
.chat-list li .chat-img {
    display: inline-block;
    width: 45px;
    vertical-align: top;
}
.chat-list li .chat-img img {
    width: 45px;
    border-radius: 100%;
}
.chat-list li .chat-content {
    width: calc(100% - 140px);
    display: inline-block;
    padding-left: 15px;
}
.chat-list li .chat-content h5 {
    color: #bcc3d3;
}
.chat-list li .chat-content .box {
    display: inline-block;
    margin-bottom: 10px;
    color: #263238;
}
.chat-list li .chat-time {
    display: inline-block;
    text-align: right;
    width: 80px;
    font-size: 13px;
    color: #bcc3d3;
}
.chat-list li.odd .chat-content {
    text-align: right;
    width: calc(100% - 90px);
}
.chat-list li.odd .box {
    clear: both;
}
.chat-list li.odd + .odd {
    margin-top: 0px;
}
.chat-list li.reverse {
    text-align: right;
}
.chat-list li.reverse .chat-time {
    text-align: left;
}
.chat-list li.reverse .chat-content {
    padding-left: 0px;
    padding-right: 15px;
}

/*******************/
/*Chat widget*/
/*******************/
.message-box ul li .drop-title {
    font-weight: 500;
    padding: 11px 20px 15px;
    border-bottom: 1px solid rgba(120, 130, 140, 0.13);
}

.message-box ul li .nav-link {
    border-top: 1px solid rgba(120, 130, 140, 0.13);
    padding-top: 15px;
}

.message-box .message-widget {
    position: relative;
}
.message-box .message-widget a {
    border-bottom: 1px solid rgba(120, 130, 140, 0.13);
    display: block;
    text-decoration: none;
    padding: 9px 15px;
}
.message-box .message-widget a:hover {
    background: #e9edf2;
}
.message-box .message-widget a:last-child {
    border-bottom: 0px;
}
.message-box .message-widget a div {
    white-space: normal;
}
.message-box .message-widget a .user-img {
    width: 45px;
    position: relative;
    display: inline-block;
    margin: 0 10px 15px 0;
}
.message-box .message-widget a .user-img img {
    width: 100%;
}
.message-box .message-widget a .user-img .profile-status {
    border: 2px solid #ffffff;
    border-radius: 50%;
    display: inline-block;
    height: 10px;
    left: 33px;
    position: absolute;
    top: -1px;
    width: 10px;
}
.message-box .message-widget a .user-img .online {
    background: #24d2b5;
}
.message-box .message-widget a .user-img .busy {
    background: #ff5c6c;
}
.message-box .message-widget a .user-img .away {
    background: #ff9041;
}
.message-box .message-widget a .user-img .offline {
    background: #ff9041;
}
.message-box .message-widget a .mail-contnet {
    display: inline-block;
    width: 70%;
    vertical-align: middle;
}
.message-box .message-widget a .mail-contnet h5 {
    margin: 5px 0px 0;
}
.message-box .message-widget a .mail-contnet .mail-desc,
.message-box .message-widget a .mail-contnet .time {
    font-size: 12px;
    display: block;
    margin: 1px 0;
    text-overflow: ellipsis;
    overflow: hidden;
    color: #67757c;
    white-space: nowrap;
}

/*******************/
/*Calendar widget*/
/*******************/
.calendar {
    float: left;
    margin-bottom: 0px;
}

.fc-view {
    margin-top: 30px;
}

.none-border .modal-footer {
    border-top: none;
}

.fc-toolbar {
    margin-bottom: 5px;
    margin-top: 15px;
}
.fc-toolbar h2 {
    font-size: 18px;
    font-weight: 500;
    line-height: 30px;
    text-transform: uppercase;
}

.fc-day {
    background: #ffffff;
}

.fc-toolbar .fc-state-active,
.fc-toolbar .ui-state-active,
.fc-toolbar button:focus,
.fc-toolbar button:hover,
.fc-toolbar .ui-state-hover {
    z-index: 0;
}

.fc-widget-header {
    border: 0px !important;
}

.fc-widget-content {
    border-color: rgba(120, 130, 140, 0.13) !important;
}

.fc th.fc-widget-header {
    color: #67757c;
    font-size: 13px;
    font-weight: 300;
    line-height: 20px;
    padding: 7px 0px;
    text-transform: uppercase;
}

.fc th.fc-sun,
.fc th.fc-tue,
.fc th.fc-thu,
.fc th.fc-sat {
    background: #f2f7f8;
}

.fc th.fc-mon,
.fc th.fc-wed,
.fc th.fc-fri {
    background: #f2f7f8;
}

.fc-view {
    margin-top: 0px;
}

.fc-toolbar {
    margin: 0px;
    padding: 24px 0px;
}

.fc-button {
    background: #ffffff;
    border: 1px solid rgba(120, 130, 140, 0.13);
    color: #67757c;
    text-transform: capitalize;
}
.fc-button:hover {
    background: #e9edf2;
    opacity: 0.8;
}

.fc-text-arrow {
    font-family: inherit;
    font-size: 16px;
}

.fc-state-hover {
    background: #f5f5f5;
}

.fc-unthemed .fc-today {
    border: 1px solid #ff5c6c;
    background: #e9edf2 !important;
}

.fc-state-highlight {
    background: #f0f0f0;
}

.fc-cell-overlay {
    background: #f0f0f0;
}

.fc-unthemed .fc-today {
    background: #ffffff;
}

.fc-event {
    border-radius: 0px;
    border: none;
    cursor: move;
    color: #ffffff !important;
    font-size: 13px;
    margin: 1px -1px 0 -1px;
    padding: 5px 5px;
    text-align: center;
    background: #20aee3;
}

.calendar-event {
    cursor: move;
    margin: 10px 5px 0 0;
    padding: 6px 10px;
    display: inline-block;
    color: #ffffff;
    min-width: 140px;
    text-align: center;
    background: #20aee3;
}
.calendar-event a {
    float: right;
    opacity: 0.6;
    font-size: 10px;
    margin: 4px 0 0 10px;
    color: #ffffff;
}

.fc-basic-view td.fc-week-number span {
    padding-right: 5px;
}

.fc-basic-view .fc-day-number {
    padding: 10px 15px;
    display: inline-block;
}

/*******************/
/*Steam line widget*/
/*******************/
.steamline {
    position: relative;
    border-left: 1px solid rgba(120, 130, 140, 0.13);
    margin-left: 20px;
}
.steamline .sl-left {
    float: left;
    margin-left: -20px;
    z-index: 1;
    width: 40px;
    line-height: 40px;
    text-align: center;
    height: 40px;
    border-radius: 100%;
    color: #ffffff;
    margin-right: 15px;
}
.steamline .sl-left img {
    max-width: 40px;
}

.steamline .sl-right {
    padding-left: 50px;
}
.steamline .sl-right .desc,
.steamline .sl-right .inline-photos {
    margin-bottom: 30px;
}

.steamline .sl-item {
    border-bottom: 1px solid rgba(120, 130, 140, 0.13);
    margin: 20px 0;
}

.sl-date {
    font-size: 10px;
    color: #bcc3d3;
}

.time-item {
    border-color: rgba(120, 130, 140, 0.13);
    padding-bottom: 1px;
    position: relative;
}
.time-item:before {
    content: " ";
    display: table;
}
.time-item:after {
    background-color: #ffffff;
    border-color: rgba(120, 130, 140, 0.13);
    border-radius: 10px;
    border-style: solid;
    border-width: 2px;
    bottom: 0;
    content: "";
    height: 14px;
    left: 0;
    margin-left: -8px;
    position: absolute;
    top: 5px;
    width: 14px;
}

.time-item-item:after {
    content: " ";
    display: table;
}

.item-info {
    margin-bottom: 15px;
    margin-left: 15px;
}
.item-info p {
    margin-bottom: 10px !important;
}

/*******************/
/*Feed widget*/
/*******************/
.feeds {
    margin: 0px;
    padding: 0px;
}
.feeds li {
    list-style: none;
    padding: 10px;
    display: block;
}
.feeds li:hover {
    background: rgba(0, 0, 0, 0.02);
}
.feeds li > div {
    width: 40px;
    height: 40px;
    margin-right: 5px;
    display: inline-block;
    text-align: center;
    vertical-align: middle;
    border-radius: 100%;
}
.feeds li > div i {
    line-height: 40px;
}
.feeds li span {
    float: right;
    width: auto;
    font-size: 12px;
}

/*******************/
/*Vertical carousel*/
/*******************/
.vert .carousel-item-next.carousel-item-left,
.vert .carousel-item-prev.carousel-item-right {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
}

.vert .carousel-item-next,
.vert .active.carousel-item-right {
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100% 0);
}

.vert .carousel-item-prev,
.vert .active.carousel-item-left {
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
}

/*******************/
/*social-widgets*/
/*******************/
.social-widget .soc-header {
    padding: 15px;
    text-align: center;
    font-size: 36px;
    color: #fff;
}
.social-widget .soc-header.box-facebook {
    background: #3b5998;
}
.social-widget .soc-header.box-twitter {
    background: #00aced;
}
.social-widget .soc-header.box-google {
    background: #f86c6b;
}
.social-widget .soc-header.box-linkedin {
    background: #4875b4;
}

.social-widget .soc-content {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    text-align: center;
}
.social-widget .soc-content div {
    padding: 10px;
}
.social-widget .soc-content div h3 {
    margin-bottom: 0px;
}

/*******************/
/*social-profile-first*/
/*******************/
.social-profile-first {
    text-align: center;
    padding-top: 22%;
    margin-bottom: 96px;
}
.social-profile-first.bg-over {
    background: rgba(56, 83, 161, 0.7);
}
.social-profile-first .middle {
    vertical-align: middle;
}

/*******************/
/*profile timeline widget*/
/*******************/
.profiletimeline {
    position: relative;
    padding-left: 40px;
    margin-right: 10px;
    border-left: 1px solid rgba(120, 130, 140, 0.13);
    margin-left: 30px;
}
.profiletimeline .sl-left {
    float: left;
    margin-left: -60px;
    z-index: 1;
    margin-right: 15px;
}
.profiletimeline .sl-left img {
    max-width: 40px;
}

.profiletimeline .sl-item {
    margin-top: 8px;
    margin-bottom: 30px;
}

.profiletimeline .sl-date {
    font-size: 12px;
    color: #bcc3d3;
}

.profiletimeline .time-item {
    border-color: rgba(120, 130, 140, 0.13);
    padding-bottom: 1px;
    position: relative;
}
.profiletimeline .time-item:before {
    content: " ";
    display: table;
}
.profiletimeline .time-item:after {
    background-color: #ffffff;
    border-color: rgba(120, 130, 140, 0.13);
    border-radius: 10px;
    border-style: solid;
    border-width: 2px;
    bottom: 0;
    content: "";
    height: 14px;
    left: 0;
    margin-left: -8px;
    position: absolute;
    top: 5px;
    width: 14px;
}

.profiletimeline .time-item-item:after {
    content: " ";
    display: table;
}

.profiletimeline .item-info {
    margin-bottom: 15px;
    margin-left: 15px;
}
.profiletimeline .item-info p {
    margin-bottom: 10px !important;
}

/*little-profile*/
.little-profile .pro-img {
    margin-bottom: 20px;
}
.little-profile .pro-img img {
    width: 128px;
    height: 128px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
    border-radius: 100%;
}

.little-profile .soc-pro a {
    color: #bcc3d3;
}
.little-profile .soc-pro a:hover {
    color: #263238;
}

/*******************
/*States row*/
/*******************/
.stats-row {
    margin-bottom: 20px;
}
.stats-row .stat-item {
    display: inline-block;
    padding-right: 15px;
}
.stats-row .stat-item + .stat-item {
    padding-left: 15px;
    border-left: 1px solid rgba(120, 130, 140, 0.13);
}

/*******************/
/*Guage chart*/
/*******************/
.gaugejs-box {
    position: relative;
    margin: 0 auto;
}

.gaugejs-box canvas.gaugejs {
    width: 100% !important;
    height: auto !important;
}

/*******************/
/*country-state*/
/*******************/
.country-state {
    list-style: none;
    margin: 0;
    padding: 0 0 0 10px;
}
.country-state li {
    margin-top: 30px;
    margin-bottom: 10px;
}
.country-state h2 {
    margin-bottom: 0px;
    font-weight: 400;
}

/*contact widgets*/
.contact-box {
    position: relative;
}
.contact-box .add-ct-btn {
    position: absolute;
    right: 4px;
    top: -46px;
}
.contact-box .contact-widget > a {
    padding: 15px 10px;
}
.contact-box .contact-widget > a .user-img {
    margin-bottom: 0px !important;
}

/*Blog widgets*/
.blog-widget {
    margin-top: 30px;
}
.blog-widget .blog-image img {
    border-radius: 4px;
    margin-top: -45px;
    margin-bottom: 20px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
}

/*******************/
/*Msg box*/
/*******************/
.msg-item {
    margin-bottom: 20px;
}

.msg-body {
    background: #ffffff;
    padding: 15px;
    font-size: 14px;
    position: relative;
}
.msg-body .dwn-aro {
    width: 0;
    position: absolute;
    bottom: -8px;
    height: 0;
    left: 10px;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-top: 8px solid #ffffff;
}

.m-pic img {
    width: 40px;
    border-radius: 100%;
}

/*******************/
/*Activity widgets*/
/*******************/
.activity-box .date-devider {
    border-top: 2px solid rgba(120, 130, 140, 0.13);
    position: relative;
}
.activity-box .date-devider span {
    background: #e9edf2;
    padding: 5px 15px;
    border-radius: 60px;
    font-size: 14px;
    top: -15px;
    position: relative;
    margin-left: 20px;
}

.activity-box .activity-item {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-bottom: 30px;
}
.activity-box .activity-item .image-list > a {
    margin-left: -15px;
    position: relative;
    vertical-align: middle;
}
.activity-box .activity-item .image-list > a:first-child,
.activity-box .activity-item .image-list > a:last-child {
    margin-left: 0px;
}
.activity-box .activity-item .image-list > a:hover {
    z-index: 10;
}
.activity-box .activity-item .image-list > a:hover img {
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.2);
}

/*============================================================== 
widget-chart-page product review
 ============================================================== */
.product-review {
    margin: 0px;
    padding: 25px;
}
.product-review li {
    display: block;
    padding: 20px 0;
    list-style: none;
}
.product-review li .font,
.product-review li span {
    display: inline-block;
    margin-left: 10px;
}

.social-profile {
    text-align: center;
    background: rgba(7, 10, 43, 0.8);
}

.profile-tab li a.nav-link,
.customtab li a.nav-link {
    border: 0px;
    padding: 15px 20px;
    color: #67757c;
}
.profile-tab li a.nav-link.active,
.customtab li a.nav-link.active {
    border-bottom: 2px solid #20aee3;
    color: #20aee3;
}
.profile-tab li a.nav-link:hover,
.customtab li a.nav-link:hover {
    color: #20aee3;
}

/*
Template Name: Admin Pro Admin
Author: Wrappixel
Email: niravjoshi87@gmail.com
File: scss
*/
@media (min-width: 1600px) {
    .col-xlg-1,
    .col-xlg-10,
    .col-xlg-11,
    .col-xlg-12,
    .col-xlg-2,
    .col-xlg-3,
    .col-xlg-4,
    .col-xlg-5,
    .col-xlg-6,
    .col-xlg-7,
    .col-xlg-8,
    .col-xlg-9 {
        float: left;
    }
    .col-xlg-12 {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 100%;
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
    }
    .col-xlg-11 {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 91.66666667%;
        -ms-flex: 0 0 91.66666667%;
        flex: 0 0 91.66666667%;
        max-width: 91.66666667%;
    }
    .col-xlg-10 {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 83.33333333%;
        -ms-flex: 0 0 83.33333333%;
        flex: 0 0 83.33333333%;
        max-width: 83.33333333%;
    }
    .col-xlg-9 {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 75%;
        -ms-flex: 0 0 75%;
        flex: 0 0 75%;
        max-width: 75%;
    }
    .col-xlg-8 {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 66.66666667%;
        -ms-flex: 0 0 66.66666667%;
        flex: 0 0 66.66666667%;
        max-width: 66.66666667%;
    }
    .col-xlg-7 {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 58.33333333%;
        -ms-flex: 0 0 58.33333333%;
        flex: 0 0 58.33333333%;
        max-width: 58.33333333%;
    }
    .col-xlg-6 {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 50%;
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%;
    }
    .col-xlg-5 {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 41.66666667%;
        -ms-flex: 0 0 41.66666667%;
        flex: 0 0 41.66666667%;
        max-width: 41.66666667%;
    }
    .col-xlg-4 {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 33.33333333%;
        -ms-flex: 0 0 33.33333333%;
        flex: 0 0 33.33333333%;
        max-width: 33.33333333%;
    }
    .col-xlg-3 {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 25%;
        -ms-flex: 0 0 25%;
        flex: 0 0 25%;
        max-width: 25%;
    }
    .col-xlg-2 {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 16.66666667%;
        -ms-flex: 0 0 16.66666667%;
        flex: 0 0 16.66666667%;
        max-width: 16.66666667%;
    }
    .col-xlg-1 {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 8.33333333%;
        -ms-flex: 0 0 8.33333333%;
        flex: 0 0 8.33333333%;
        max-width: 8.33333333%;
    }
    .col-xlg-pull-12 {
        right: 100%;
    }
    .col-xlg-pull-11 {
        right: 91.66666667%;
    }
    .col-xlg-pull-10 {
        right: 83.33333333%;
    }
    .col-xlg-pull-9 {
        right: 75%;
    }
    .col-xlg-pull-8 {
        right: 66.66666667%;
    }
    .col-xlg-pull-7 {
        right: 58.33333333%;
    }
    .col-xlg-pull-6 {
        right: 50%;
    }
    .col-xlg-pull-5 {
        right: 41.66666667%;
    }
    .col-xlg-pull-4 {
        right: 33.33333333%;
    }
    .col-xlg-pull-3 {
        right: 25%;
    }
    .col-xlg-pull-2 {
        right: 16.66666667%;
    }
    .col-xlg-pull-1 {
        right: 8.33333333%;
    }
    .col-xlg-pull-0 {
        right: auto;
    }
    .col-xlg-push-12 {
        left: 100%;
    }
    .col-xlg-push-11 {
        left: 91.66666667%;
    }
    .col-xlg-push-10 {
        left: 83.33333333%;
    }
    .col-xlg-push-9 {
        left: 75%;
    }
    .col-xlg-push-8 {
        left: 66.66666667%;
    }
    .col-xlg-push-7 {
        left: 58.33333333%;
    }
    .col-xlg-push-6 {
        left: 50%;
    }
    .col-xlg-push-5 {
        left: 41.66666667%;
    }
    .col-xlg-push-4 {
        left: 33.33333333%;
    }
    .col-xlg-push-3 {
        left: 25%;
    }
    .col-xlg-push-2 {
        left: 16.66666667%;
    }
    .col-xlg-push-1 {
        left: 8.33333333%;
    }
    .col-xlg-push-0 {
        left: auto;
    }
    .offset-xlg-12 {
        margin-left: 100%;
    }
    .offset-xlg-11 {
        margin-left: 91.66666667%;
    }
    .offset-xlg-10 {
        margin-left: 83.33333333%;
    }
    .offset-xlg-9 {
        margin-left: 75%;
    }
    .offset-xlg-8 {
        margin-left: 66.66666667%;
    }
    .offset-xlg-7 {
        margin-left: 58.33333333%;
    }
    .offset-xlg-6 {
        margin-left: 50%;
    }
    .offset-xlg-5 {
        margin-left: 41.66666667%;
    }
    .offset-xlg-4 {
        margin-left: 33.33333333%;
    }
    .offset-xlg-3 {
        margin-left: 25%;
    }
    .offset-xlg-2 {
        margin-left: 16.66666667%;
    }
    .offset-xlg-1 {
        margin-left: 8.33333333%;
    }
    .offset-xlg-0 {
        margin-left: 0;
    }
}

.col-xlg-1,
.col-xlg-10,
.col-xlg-11,
.col-xlg-12,
.col-xlg-2,
.col-xlg-3,
.col-xlg-4,
.col-xlg-5,
.col-xlg-6,
.col-xlg-7,
.col-xlg-8,
.col-xlg-9 {
    position: relative;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
}

/*-------------------*/
/*Bootstrap 4 hack*/
/*-------------------*/
.bootstrap-touchspin .input-group-btn {
    -webkit-align-items: normal;
    -ms-flex-align: normal;
    align-items: normal;
}

.form-control-danger,
.form-control-success,
.form-control-warning {
    padding-right: 2.25rem;
    background-repeat: no-repeat;
    background-position: center right 0.5625rem;
    -webkit-background-size: 1.125rem 1.125rem;
    background-size: 1.125rem 1.125rem;
}

.has-success .col-form-label,
.has-success .custom-control,
.has-success .form-check-label,
.has-success .form-control-feedback,
.has-success .form-control-label {
    color: #24d2b5;
}

.has-success .form-control {
    border-color: #24d2b5;
}

.has-warning .col-form-label,
.has-warning .custom-control,
.has-warning .form-check-label,
.has-warning .form-control-feedback,
.has-warning .form-control-label {
    color: #ff9041;
}

.has-warning .form-control {
    border-color: #ff9041;
}

.has-danger .col-form-label,
.has-danger .custom-control,
.has-danger .form-check-label,
.has-danger .form-control-feedback,
.has-danger .form-control-label {
    color: #ff5c6c;
}

.has-danger .form-control {
    border-color: #ff5c6c;
}

.input-group-addon [type="radio"]:not(:checked),
.input-group-addon [type="radio"]:checked,
.input-group-addon [type="checkbox"]:not(:checked),
.input-group-addon [type="checkbox"]:checked {
    position: initial;
    opacity: 1;
}

.invisible {
    visibility: hidden !important;
}

.hidden-xs-up {
    display: none !important;
}

@media (max-width: 575px) {
    .hidden-xs-down {
        display: none !important;
    }
}

@media (min-width: 576px) {
    .hidden-sm-up {
        display: none !important;
    }
}

@media (max-width: 767px) {
    .hidden-sm-down {
        display: none !important;
    }
}

@media (min-width: 768px) {
    .hidden-md-up {
        display: none !important;
    }
}

@media (max-width: 991px) {
    .hidden-md-down {
        display: none !important;
    }
}

@media (min-width: 992px) {
    .hidden-lg-up {
        display: none !important;
    }
}

@media (max-width: 1199px) {
    .hidden-lg-down {
        display: none !important;
    }
}

@media (min-width: 1200px) {
    .hidden-xl-up {
        display: none !important;
    }
}

.hidden-xl-down {
    display: none !important;
}

.card-inverse .card-blockquote,
.card-inverse .card-footer,
.card-inverse .card-header,
.card-inverse .card-title {
    color: #ffffff;
}

/*
Template Name: Admin Pro Admin
Author: Wrappixel
Email: niravjoshi87@gmail.com
File: scss
*/
/*============================================================== 
 For Laptop & above all (1650px) 
 ============================================================== */
@media (min-width: 1650px) {
    .widget-app-columns {
        -webkit-column-count: 3;
        -moz-column-count: 3;
        column-count: 3;
    }
    .campaign {
        height: 365px !important;
    }
    .website-visitor {
        height: 445px;
    }
}

/*============================================================== 
 For Laptop & above all (1370px) 
 ============================================================== */
@media (max-width: 1370px) {
    .widget-app-columns {
        -webkit-column-count: 2;
        -moz-column-count: 2;
        column-count: 2;
    }
    .website-visitor {
        height: 353px;
    }
}

/*-- ============================================================== 
 Small Desktop & above all (1024px) 
 ============================================================== */
@media (max-width: 1023px) {
    .widget-app-columns {
        -webkit-column-count: 1;
        -moz-column-count: 1;
        column-count: 1;
    }
    .inbox-center a {
        width: 200px;
    }
    .hdr-nav-bar .navbar .navbar-nav > li a {
        padding: 12px 15px;
        border-bottom: 0px solid transparent;
        border-left: 2px solid transparent;
    }
    .hdr-nav-bar .navbar .navbar-nav > li:hover a {
        padding: 12px 20px;
    }
    .hdr-nav-bar .call-to-act .custom-select {
        margin-bottom: 10px;
    }
    .d-flex {
        display: block !important;
    }
    .d-flex.no-block {
        display: -webkit-flex !important;
        display: -ms-flexbox !important;
        display: flex !important;
    }
}

/*-- ============================================================== 
 Ipad & above all(768px) 
 ============================================================== */
@media (min-width: 768px) {
    .navbar-header {
        width: 240px;
        -webkit-flex-shrink: 0;
        -ms-flex-negative: 0;
        flex-shrink: 0;
    }
    .navbar-header .navbar-brand {
        padding-top: 0px;
    }
    /*This is for the breeadcrumd*/
    .page-titles .breadcrumb {
        text-transform: uppercase;
    }
    .card-group .card:first-child,
    .card-group .card:not(:first-child):not(:last-child) {
        border-right: 1px solid rgba(0, 0, 0, 0.03);
    }
    .material-icon-list-demo .icons div {
        width: 33%;
        padding: 15px;
        display: inline-block;
        line-height: 40px;
    }
    .flex-wrap {
        -ms-flex-wrap: nowrap !important;
        flex-wrap: nowrap !important;
        -webkit-flex-wrap: nowrap !important;
    }
}

/*-- ============================================================== 
 Phone and below ipad(767px) 
 ============================================================== */
@media (max-width: 767px) {
    .container-fluid {
        padding: 10px 15px 10px 15px;
    }
    .fix-header.fix-sidebar .page-wrapper {
        padding-top: 70px;
    }
    /*Header*/
    .topbar { 
      
            position: fixed;
            width: 100%;
            display: block !important;
        }
    .topbar .top-navbar {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -webkit-flex-direction: row;
        -ms-flex-direction: row;
        flex-direction: row;
        -webkit-flex-wrap: nowrap;
        -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
        min-height: 70px;
        -webkit-align-items: center;
        padding: 0px;
    }
    .topbar .top-navbar .navbar-collapse {
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        width: 100%;
    }
    .topbar .top-navbar .navbar-nav {
        -webkit-flex-direction: row;
        -ms-flex-direction: row;
        flex-direction: row;
    }
    .topbar .top-navbar .navbar-nav > .nav-item.show {
        position: static;
    }
    .topbar .top-navbar .navbar-nav > .nav-item.show .dropdown-menu {
        width: 100%;
        margin-top: 0px;
    }
    .topbar .top-navbar .navbar-nav > .nav-item > .nav-link {
        padding-left: 0.5rem;
        padding-right: 0.5rem;
    }
    .topbar .top-navbar .navbar-nav .dropdown-menu {
        position: absolute;
    }
    .mega-dropdown .dropdown-menu {
        height: 480px;
        overflow: auto;
    }
    /*Sidebar and wrapper*/
    .mini-sidebar .page-wrapper {
        margin-left: 0px;
        padding-top: 70px;
    }
    .comment-text .comment-footer .action-icons {
        display: block;
        padding: 10px 0;
    }
    .vtabs .tabs-vertical {
        width: auto;
    }
    /*Footer*/
    .material-icon-list-demo .icons div {
        width: 100%;
    }
    .error-page .footer {
        position: fixed;
        bottom: 0px;
        z-index: 10;
    }
    .error-box {
        position: relative;
        padding-bottom: 60px;
    }
    .error-body {
        padding-top: 10%;
    }
    .error-body h1 {
        font-size: 100px;
        font-weight: 600;
        line-height: 100px;
    }
    .login-register {
        position: relative;
        overflow: hidden;
    }
    .login-box {
        width: 90%;
    }
    .login-sidebar {
        padding: 10% 0;
    }
    .login-sidebar .login-box {
        position: relative;
    }
    /*This is for chat page*/
    .chat-main-box .chat-left-aside {
        left: -250px;
        position: absolute;
        transition: 0.5s ease-in;
        background: #ffffff;
    }
    .chat-main-box .chat-left-aside.open-pnl {
        left: 0px;
    }
    .chat-main-box .chat-left-aside .open-panel {
        display: block;
    }
    .chat-main-box .chat-right-aside {
        width: 100%;
    }
    /*Timeline*/
    ul.timeline:before {
        left: 40px;
    }
    ul.timeline > li > .timeline-panel {
        width: calc(100% - 90px);
    }
    ul.timeline > li > .timeline-badge {
        top: 16px;
        left: 15px;
        margin-left: 0;
    }
    ul.timeline > li > .timeline-panel {
        float: right;
    }
    ul.timeline > li > .timeline-panel:before {
        right: auto;
        left: -15px;
        border-right-width: 15px;
        border-left-width: 0;
    }
    ul.timeline > li > .timeline-panel:after {
        right: auto;
        left: -14px;
        border-right-width: 14px;
        border-left-width: 0;
    }
    /*Contact app page*/
    .left-aside {
        width: 100%;
        position: relative;
        border: 0px;
    }
    .right-aside {
        margin-left: 0px;
    }
    .flex-wrap {
        -ms-flex-wrap: wrap !important;
        flex-wrap: wrap !important;
        -webkit-flex-wrap: wrap !important;
    }
    .chat-list li .chat-content {
        width: calc(100% - 80px);
    }
    /*Calendar*/
    .fc-toolbar .fc-left,
    .fc-toolbar .fc-right,
    .fc-toolbar .fc-center {
        float: none;
        display: block;
        text-align: center;
        margin-top: 10px;
        overflow: hidden;
    }
}

@media (max-width: 768px) {
    .ReactModal__Content--after-open {
        inset: auto !important;
        transform: none !important;
        margin-top: 50px !important;
    }
}
.thumbnail_section {
    border: 1px solid #ccc;
    border-radius: 84px;
    padding: 18px 4px;
}
.btn_react {
    text-align: center;
}
.Toggler_btn {
    text-align: center;
}
.btn3d {
    position: relative;
    top: -6px;
    border: 0;
    transition: all 40ms linear;
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: 2px;
    margin-right: 2px;
    color: #ffffff;
    padding: 10px 40px !important;
}

.btn3d:active:focus,
.btn3d:focus:hover,
.btn3d:focus {
    -moz-outline-style: none;
    outline: medium none;
}

.btn3d:active,
.btn3d.active {
    top: 2px;
}

.btn3d.btn-default {
    color: #666666;
    box-shadow: 0 0 0 1px #ebebeb inset, 0 0 0 2px rgba(255, 255, 255, 0.1) inset, 0 8px 0 0 #bebebe, 0 8px 8px 1px rgba(0, 0, 0, 0.2);
    background-color: #f9f9f9;
}

.btn3d.btn-default:active,
.btn3d.btn-default.active {
    color: #666666;
    box-shadow: 0 0 0 1px #ebebeb inset, 0 0 0 1px rgba(255, 255, 255, 0.15) inset, 0 1px 3px 1px rgba(0, 0, 0, 0.1);
    background-color: #f9f9f9;
}

.btn3d.btn-primary {
    box-shadow: 0 0 0 1px #417fbd inset, 0 0 0 2px rgba(255, 255, 255, 0.15) inset, 0 8px 0 0 #4d5bbe, 0 8px 8px 1px rgba(0, 0, 0, 0.5);
    background-color: #4274d7;
    color: #ffffff;
}

.btn3d.btn-primary:active,
.btn3d.btn-primary.active {
    box-shadow: 0 0 0 1px #417fbd inset, 0 0 0 1px rgba(255, 255, 255, 0.15) inset, 0 1px 3px 1px rgba(0, 0, 0, 0.3);
    background-color: #4274d7;
}

#divTwo {
    background-color: #f1f1f1;
    color: #000;
    border: 1px solid darkgrey;
    text-transform: capitalize;
    letter-spacing: 0.4px;
    font-size: 15px;
}
#divThree {
    background-color: #f1f1f1;
    color: #000;
    border: 1px solid darkgrey;
    text-transform: capitalize;
    letter-spacing: 0.4px;
    font-size: 15px;
}
.login-register {
    background-image: url("../../assets/img/login-register.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-attachment: fixed;
}

.modal {
    position: fixed;
    top: 0;
    left: 0;
    width:100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    /* z-index: 9999999999 !important; */
  }
  
  .modal-main {
    position:fixed;
    background: white;
    width: 80%;
    height: auto;
    top:50%;
    left:50%;
    transform: translate(-50%,-50%);
  }
  
  .display-block {
    display: block;
  }
  
  .display-none {
    display: none;
  }
 

  .allinone {
    width: 38px;
    margin-right: 10px;
}

.dropdown, .dropup {
    position: static !important;
}

.txtareamodal {

    border-radius: 4px !important;
    border: 1px solid rgba(0, 0, 0, .15) !important;
    padding: 5px 0px !important;
  }
  .sltareamodal {
    width: 100%;
    height: 33px;
    margin-right :10px;
    border: 1px solid rgba(0, 0, 0, .15);
    border-radius: 4px;
    padding: 0px 10px;
}
 
.img_uploaded {
    border: 1px solid rgba(0, 0, 0, .15);
    margin-top: 15px;
    border-radius: 4px;
    margin-bottom: 5px;
}
.img_uploaded img {
    width: 100%;
    padding: 10px 10px 10px 10px;
    border-radius: 13px;
    /* height: 100px; */
}
.btn_trigger button{
    margin-right:20px;
}
.userclr{
    color:#28a745;
}
.weekclr{
    color:#17a2b8;
}
.emailclr{
    color:#007bff;
}

.pipeclr{
    color:#dc3545;
}

/* .loaderDiv{
    text-align: center;
    height: 100vh;
    background-color: #f7f7f7;
    position: absolute;
    right: auto;
    left: 0;
    width: 100%;
    z-index: 9;
    top: 0;
    bottom: 0;
}
 
.loader {
    border: 16px solid #fff;
    border-radius: 50%;
    border-top: 16px solid #3498db;
    width: 120px;
    height: 120px;
    -webkit-animation: spin 2s linear infinite; 
    animation: spin 2s linear infinite;
    margin-left: auto;
    margin-right: auto;
    margin-top: 200px;
  }



  
  
  @-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  } */

  .loader {
    width: 50px;
    aspect-ratio: 1;
    border-radius: 50%;
    background: #2e333f;
    -webkit-mask: radial-gradient(circle closest-side at 50% 40%,#0000 94%, #000);
    transform-origin: 50% 40%;
    animation: l25 1s infinite linear;
    position: relative;
    top: 290px;
    margin: 0px auto;
  }
  @keyframes l25 {
    100% {transform: rotate(1turn)}
  }
  .loaderDiv {
    background-color: #efefef;
    height: 100%;
    position: fixed;
    margin: 0px auto;
    width: 100%;
    z-index: 9999;
    opacity: .9;
    top: 111px;
    right: 0;
}




  
  .apptick {
    width: 20px;
    position: absolute;
    right: 0px;
    top: 0px;
}
.padding-none{
    padding:0px 0px !important;
}
.fontcircle {
    float: right;
    margin-right: 8px;
    margin-top: -25px;
    font-size: 20px;
    color: #17a2b8;
}
.srcbtn {
    width: 30%;
    display: inline-flex;
    margin-right: 10px;
}
.srcbtn select {
    margin-right: 10px;
}
.padding-none a {
    font-size: 14px;
    font-weight: 500;
    transition:.3s;
}
.padding-none a:hover {
 text-decoration: none;
}
.boxshdw {
    transition: .3s;
    outline: none;
}
.boxshdw:hover{
    box-shadow: rgba(0, 0, 0, 0.45) 0px 25px 20px -20px;
}
.mright{
    margin-right:10px;
}

.el-element-overlay .white-box {
    padding: 0px; }
  
  .el-element-overlay .el-card-item {
    position: relative;
    padding-bottom: 25px; }
    .el-element-overlay .el-card-item .el-card-avatar {
      margin-bottom: 15px; }
    .el-element-overlay .el-card-item .el-card-content {
      text-align: center; }
      .el-element-overlay .el-card-item .el-card-content h3 {
        margin: 0px; }
      .el-element-overlay .el-card-item .el-card-content a {
        color: #67757c; }
        .el-element-overlay .el-card-item .el-card-content a:hover {
          color: #398bf7; }
    .el-element-overlay .el-card-item .el-overlay-1 {
      width: 100%;
      overflow: hidden;
      position: relative;
      text-align: center;
      cursor: default; }
      .el-element-overlay .el-card-item .el-overlay-1 img {
        display: block;
        position: relative;
        -webkit-transition: all .4s linear;
        -o-transition: all .4s linear;
        transition: all .4s linear;
        width: 100%;
        height: auto; }
      .el-element-overlay .el-card-item .el-overlay-1:hover img {
        -ms-transform: scale(1.2) translateZ(0);
        -webkit-transform: scale(1.2) translateZ(0);
        /* transform: scale(1.2) translateZ(0); */ }
      .el-element-overlay .el-card-item .el-overlay-1 .el-info {
        text-decoration: none;
        display: inline-block;
        text-transform: uppercase;
        color: #ffffff;
        background-color: transparent;
        filter: alpha(opacity=0);
        -webkit-transition: all .2s ease-in-out;
        -o-transition: all .2s ease-in-out;
        transition: all .2s ease-in-out;
        padding: 0;
        margin: auto;
        position: absolute;
        top: 50%;
        left: 0;
        right: 0;
        transform: translateY(-50%) translateZ(0);
        -webkit-transform: translateY(-50%) translateZ(0);
        -ms-transform: translateY(-50%) translateZ(0); }
        .el-element-overlay .el-card-item .el-overlay-1 .el-info > li {
          list-style: none;
          display: inline-block;
          margin: 0 3px; }
          .el-element-overlay .el-card-item .el-overlay-1 .el-info > li a {
            border-color: #ffffff;
            color: #ffffff;
            padding: 12px 15px 10px; }
            .el-element-overlay .el-card-item .el-overlay-1 .el-info > li a:hover {
              background: #398bf7;
              border-color: #398bf7; }
    .el-element-overlay .el-card-item .el-overlay {
      width: 100%;
      height: 100%;
      position: absolute;
      overflow: hidden;
      top: 0;
      left: 0;
      opacity: 0;
      background-color: rgba(0, 0, 0, 0.7);
      -webkit-transition: all .4s ease-in-out;
      -o-transition: all .4s ease-in-out;
      transition: all .4s ease-in-out; }
    .el-element-overlay .el-card-item .el-overlay-1:hover .el-overlay {
      opacity: 1;
      filter: alpha(opacity=100);
      -webkit-transform: translateZ(0);
      -ms-transform: translateZ(0);
      transform: translateZ(0); }
    .el-element-overlay .el-card-item .el-overlay-1 .scrl-dwn {
      top: -100%; }
    .el-element-overlay .el-card-item .el-overlay-1 .scrl-up {
      top: 100%;
      height: 0px; }
    .el-element-overlay .el-card-item .el-overlay-1:hover .scrl-dwn {
      top: 0px; }
    .el-element-overlay .el-card-item .el-overlay-1:hover .scrl-up {
      top: 0px;
      height: 100%; }
  
      .editfamilymmbr {
        position: relative;
        display: flex;
        flex-direction: column;
        min-width: 0;
        word-wrap: break-word;
        background-clip: border-box;
        border-radius: 0.25rem;
        padding: 108px 20px;
        text-align: center;
        color: #000; 
        text-decoration: none;
    }
    .addv {
        background-image: url('../img/cbadd.jpg'); 
          background-repeat: no-repeat;
            background-size: cover;
            background-position: center;
        }
    .fmyadd{
        background-image: url('../img/family.jpg'); 
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;
        }
  .vehicldtl{
    font-weight:600;
  }
  .fxdsize_img {
    width: 100px;
}
.plus_marg{
    margin-bottom: 10px;;
}
.append_position{
    width: 30px;
    position: absolute;
    top: 8px;
    right: 8px;
    color: #ffffff;
    border: 2px solid #ffffff;
    padding: 5px 5px;
    border-radius: 6px;
    transition:.3s;
}
.append_position:hover{
background-color:#ffffff;
color:#17a2b8;
}
.bck {
    font-size: 14px;
}
.VehicleInTotalSection{
border:1px solid #000;
 
width:100%;
text-align: center;
}
.flexSec{
    display: inline-flex;
}
.vdetail{
    margin:0px 30px;
}
.Ontop h1 {
    font-size: 30px !important;
    font-weight: 900;
    letter-spacing: .5px;
}
.vdetail img{
    margin: 15px 40px 0px;
}
.vehicleAdjust{
    width:40px,
}
.boxshad {
    padding-top: 10px;
    box-shadow: rgba(0, 0, 0, 0.45) 0px 25px 20px -20px;
 
    border-radius: 10px;
    margin: 0px 10px 40px 10px;
    background: #4285f4; 
    padding-bottom: 18px;
    border-bottom: 10px solid lightgray;
}
.boxshad-2 {
    padding-top: 10px;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.45) 0px 25px 20px -20px;
 
    margin: 0px 10px 40px 10px;
    background: #fbbc05; 
    padding-bottom: 18px;
    border-bottom: 10px solid lightgray;
}
.boxshad-3 {
    padding-top: 30px;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.45) 0px 25px 20px -20px;
    margin: 0px 10px 40px 10px;
    background: #34a853; 
    padding-bottom: 0px;
    border-bottom: 10px solid lightgray;
}
.boxshad-4 {
    padding-top: 30px;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.45) 0px 25px 20px -20px;
    margin: 0px 10px 40px 10px;
    background: #ea4335;
    padding-bottom: 0px;
    border-bottom: 10px solid lightgray;
}
.titleArrow {
    margin-bottom: 15px;
}
.heading_v {
    background-color: #ffffff;
    padding: 10px 5px 1px;
    border-radius: 32px;
    position: absolute;
    top: -23px;
    border-bottom: 2px solid lightgray;
    width: 200px;
    text-align: center;
    left: 18%;
}
.heading_v h4 {
    font-weight: 600;
    text-transform: uppercase;
    font-size: 15px !important;
}

.heading_subcat {
    background-color: #ffffff;
    padding: 10px 5px 1px;
    border-radius: 32px;
    position: absolute;
    top: -23px;
    border-bottom: 2px solid lightgray;
    width: 200px;
    text-align: center;
    left: 17%;
}
.heading_subcat h4 {
    font-weight: 600;
    text-transform: uppercase;
    font-size: 15px !important;
}

.heading_ico {
    padding: 8px 15px;
    border-radius: 40px;
    background-color: #ffffff;
    position: absolute;
    right: 40%;
    bottom: 25px;
    z-index: 9;
}

.resp_detail{
    width:60px; 
    height:60px;
    margin-left: 10px;
}
.digits {
    margin-top: 0px;
    color: #000;
    font-weight: 400;
    font-size: 40px !important;
    background-color: #ffffff;
    padding: 14px 20px 13px;
    margin-right: 0px;
    box-shadow: rgba(0, 0, 0, 0.45) 0px 25px 20px -20px;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
}
.dashalign{
    padding: 20px 0px;
}
.dividerClr {
    border: 1px solid #ffffff;
    position: absolute;
    width: 135px;
    bottom: 50px;
    left: 25px;
}
.visitorShdow{
    box-shadow: rgba(0, 0, 0, 0.45) 0px 25px 20px -20px;
}
.visitorIn {
    background-color: #fbbc05;
    border-radius: 300px;
    width: 100px;
    height: 100px;
    padding: 15px 23px;
    display: inline-grid;
    align-items: center;
    justify-content: flex-start;
}
.visitorIn img{
    width: 60px !Important;
}
.fnfAct {
    text-align: center;
    padding: 15px 0px 0px;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    border-top-Left-Radius: 20px;
    border-top-right-radius: 20px;
    box-shadow: rgba(0, 0, 0, 0.45) 0px 25px 20px -20px;
    border: 1px solid #ccc;
}
.totalIn {
    display: inline-flex;
    margin: 30px 15px 0px;
}

.actNum {
    padding: 5px 20px 5px 18px;
    border-radius: 10px;
    background-color: #ffffff;
    border: 1px solid #d3d3d3;
}
.actText h3 {
    font-weight: 600;
    font-size: 15px !important;
}
.actNum img {
    position: relative;
    width: 50px;
    background-color: #bd8d00;
    padding: 5px;
    border-radius: 9px;
    top: 2px;
    right: -2%;
}
.actNum h2 {
    color: #000;
    font-size: 14px !important;
    margin-bottom: 0px;
}

.actNumMain h2 {
    color: #ffffff;
    font-size: 14px !important;
}
.actNumMain{
    padding: 0px 20px 0px 18px;
    border-radius: 10px;
    background-color: orange;
}
.marginBothDiv{
    margin: 30px 0px 0px;
}
.ttltext h5 {
    font-size: 14px !important;
}
.actTextMain h3{
    font-size: 14px !important;
}
.greaterthan img {
    width: 60px;
    height: 60px;
    padding: 3px 5px;
    border-radius: 10px;
    background-color: orange;
}
.bottomSection {
    display: flex;
}
.greaterthan {
    margin-right: 22px;
}
.greaterthan h4 {
    text-align: center;
    margin-top: 10px;
    font-size: 15px !important;
    text-transform: uppercase;
    letter-spacing: .4px;
}
.greaterthan span {
    text-align: center;
    display: block;
    font-size: 15px !important;
    font-weight: 800;
}

.divider_sec {
    border: 1px solid #ccc;
    width: 266px;
    position: absolute;
    left: 150px;
    transform: rotate(90deg);
    top: 126px;
}

.properDet{
    display:inline-flex;
}
.Bindall{
    display:inline-flex;
}
.userDet {
    text-align: center;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    padding: 15px 0px 30px 0px;
}
.userDet h2 {
    font-size: 17px !important;
    font-weight: 500;
    padding: 10px 20px;
    color: #000;
    display: inline-flex;
}

.userDet span {
    color: #000;
    font-weight: 600;
    letter-spacing: .5px;
    text-decoration: underline;
}
.proBtn {
    text-align: center;
    margin-top: 30px;
}
.proUpdate {
    border: 1px solid #ffffff;
    padding: 10px 10px;
    background-color: #00afef;
    color: #ffffff;
    font-weight: 500;
    letter-spacing: .5px;
    width: 135px;
    display: inline-block;
    border-radius: 8px;
    margin-right: 10px;
    transition:.3s;
}
.proUpdate:hover {
    background-color: #00afef;
    color: #ffffff;
    text-decoration: none;
}
.proEdit{
    border: 1px solid #ffffff;
    padding: 10px 10px;
    background-color: #4cab4f;
    color: #ffffff;
    font-weight: 500;
    letter-spacing: .5px;
    width: 135px;
    display: inline-block;
    border-radius: 8px;
    transition:.3s;
}
.proEdit:hover {
    background-color: #00afef;
    color: #ffffff;
    text-decoration: none;
}
.ficoprofile{
    background-color: #2e333f;
    padding: 13px 15px;
    border-radius: 24px;
    color: #ffffff;
    margin-right: 10px;
}
.bg-vehicle{
    background-image: url('../img/back1.jpg');
}
.tabular_intergration {
    background-color: #f7f7f7;
    margin-top: 65px;
    position: fixed;
    width: 100%;
    z-index: 9;
}

.navbar-expand-lg .navbar-nav .dropdown-menu{
    left: 1110px;
}
 
.tab-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start; 
}

.tabs {
    width: 100%;
    padding: 0px 0px 0px;
}
button{
    background-color: transparent;
    border: none;
    padding: 8px 20px 5px;
    margin:0 5px !important;
    cursor: pointer;
    font-size: 12px !important;
    color: black;
    text-transform: uppercase !important;
    letter-spacing: .5px;
    font-weight: 500;
}
.active-tab {
    color: #00afef;
    border-bottom: 2px solid #00afef;
}
.card-container {
    margin: 0px 0px 0px 0px;
    background: #f7f7f7;
}
.card{ 
    border: 1px solid red;
    border-radius: 8px;
    padding: 20px;
 
    box-shadow:0 0 10px rgba;
}

@media screen and (max-width:600px){
    .HeadingTop {
        margin-top: 30px;
    }
    .onboardFont{
        margin-left:183px !important;
    }
    .tabs {
        margin-top: 15px;
    }
    .OneTile {
        box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
        background: #ffffff;
        padding: 5px 10px 5px 30px;
        border-radius: 5px;
         width: 100% !Important;
        margin: 0px 21px 15px 0px;
    }
    .onboardFlex {
        display: inline-flex !important;
        line-height: 0 !important;
        width: 100% !important;
        justify-content: flex-start;
    }
.slide-but {
    padding-left: 32px !important;
    border: 2px solid #00afef !important;
    min-width: 200px;
    border-radius: 33px;
    display: flex !important;
    align-items: center !important;
}
.slide-but .slide-overlay .slide-overlay-txt {
    color: #000 !important;
    background-color: #ffffff !important;
    border: 1px solid #00afef !important;
    border-radius: 33px !important;
}
}
.clientTiles {
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    margin-bottom: 20px;
    background: #ffffff;
    padding: 15px 10px 20px 15px;
    border-radius: 5px;
    height: 210px;
}
.TitleTextClient{
    background-image: url('../img/clientr.png');
    display: inline-block;
    position: absolute;
    height: 60px;
    background-repeat: no-repeat;
    left: -12px;
    top: 8px;
}
.TitleTextBuild{
  background-image: url('../img/ribbon.png');
  display: inline-block;
  position: absolute;
  height: 60px;
  background-repeat: no-repeat;
  left: -12px;
  top: 8px;
}

.TitleTexttower{
    background-image: url('../img/towerr.png');
    display: inline-block;
    position: absolute;
    height: 60px;
    background-repeat: no-repeat;
    left: -12px;
    top: 8px;
  }
  .TitleTextFlat{
    background-image: url('../img/flatr.png');
    z-index: 99;
    display: inline-block;
    position: absolute;
    height: 130px;
    background-repeat: no-repeat;
    left: -4px;
    top: 8px;
  }

  .TitleTextGate{
    background-image: url('../img/gater.png');
    z-index: 99;
    display: inline-block;
    position: absolute;
    height: 130px;
    background-repeat: no-repeat;
    left: -12px;
    top: 8px;
  }
  .modal-title{
    font-weight:300 !important;
  }
.TitleText h3 {
    font-size: 30px !important;
    font-weight: 700;
    margin-top: 10px;
    position: absolute;
    background-color: #2e333f;
    top: -47px;
    color: #ffffff;
    padding: 12px 22px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}
.ClientText {
    padding: 5px 0px 10px;
    display: inline-flex;
    width: 100%;
}
 .l-one{
    width:30%
 }

.danger{
    color: #fa5f5f;
}

 .l-two {
    width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
}
 
.l-two h1 {
    margin-bottom: 0px !important;
    font-size: 20px !important;
    font-weight: 400 !important;
    margin-left: 0px;
    width: 300px;
    color: #2e333f;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}
.l-two h4 {
    font-size: 13px !important;
    font-weight: 400 !important;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    margin-bottom: 1px;
}
.l-two h3 {
    margin-bottom: 0px !important;
    font-size: 13px !important;
    font-weight: 500;
    align-items: center;
    display: flex;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}
.l-two h3 img{
    width: 18px !important;
    margin-right: 5px; 
}
    .ClientImg {
        display: inline-flex;
        justify-content: space-between;
        width: 100%;
        align-items: center;
    }
    .tabs_img {
        display: inline-block;
        height: 60px;
        align-content: center;
    }
    .tabs_img img {
        width: 60px;
        max-width: 60px;
    }
 .bars_font{
    /* position: relative;
    top: 0;
    align-items: center;
    display: inline-flex; */
}
.container {
    margin: 20px auto;
}

.custom-box {
    border: 1px solid #ddd;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
    background-color: #fff;
    margin-bottom: 20px;
    border-radius: 8px;
}

.heading-section {
    padding-bottom: 15px;
    border-bottom: 1px solid #ddd;
    margin-bottom: 15px;
}

.table {
    margin-bottom: 0;
}

.createClr {
    background-color: #007bff;
    color: #fff;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 4px;
}

.createClr:hover {
    background-color: #0056b3;
}

.headerMDL {
    background-color: #f8f9fa;
}

.subPopup select {
    width: 100%;
    padding: 5px;
    border-radius: 4px;
    border: 1px solid #ccc;
}

.documents-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
}

.documents-header h5 {
    margin: 0;
}

.documents-header span {
    font-size: 14px;
    margin-left: 10px;
    color: #777;
}

.documents-header a {
    color: #6c63ff;
    text-decoration: none;
}

.add-document {
    color: #6c63ff;
}

.document-grid {
    display: flex;
    flex-wrap: wrap;
    margin-top: 20px;
}

.document-card {
    background-color: white;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 20px;
    margin-bottom: 20px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    transition: box-shadow 0.3s ease;
}

.document-card:hover {
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
}

.document-card h6 {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 10px;
}

.document-card p {
    margin-bottom: 8px;
}

.view-btn {
    background-color: #28a745;
    border-color: #28a745;
    color: white;
}

.view-btn:hover {
    background-color: #218838;
    border-color: #1e7e34;
}

.departments-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 20px;
    padding: 20px;
  }
  
  .department-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    border-radius: 10px;
    color: white;
    text-align: center;
  }
  .parentUploadContainer{
    display: flex;
    justify-content: center;
}
.uploadcontainer{
    width: 221px;
    text-align: center;
    padding: 20px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    border-radius: 5px;
    margin-bottom: 10px;
    border-bottom: 1px solid #17a2b8 !important;
    cursor: pointer;
    font-size: 17px;
    cursor: pointer;
    display: block;

}
.uploadcontainer label{
    
    font-weight: normal ;
    margin: 0px;
}
.imgSizeLine{
    color: red;
}

.icon {
    width: 30px;
    height: 30px;
}

.card-header {
    background-color: #f8f9fa;
    font-weight: bold;
}

.text-danger {
    color: red;
}

.container {
    margin-top: 20px;
}

.ms-3 {
    margin-left: 1rem;
}
  
  .department-image {
    margin-bottom: 10px;
  }
  
  .department-info {
    margin-top: 10px;
  }
  
  .department-info h3 {
    font-size: 18px;
    margin: 0;
  }
  
  .department-info p {
    margin: 5px 0 0;
  }

.view-btn {
    color: green;
    border: 1px solid green;
    background-color: #fff;
    padding: 2px 10px;
    border-radius: 5px;
    font-size: 12px;
    text-align: center;
    cursor: pointer;
}

.modal-dialog{
margin:0 auto!important;
}
.font_trigger {
    font-size: 12px !important;
    margin-top: 0px;
    margin-bottom: 0;
    color: #2e333f;
    font-weight: 400;
    /* background-color: #f3f4f7; */
    padding: 0px 15px;
    border-radius: 5px;
    cursor: pointer;
    transition: .3s;
}
    .font_trigger:hover {
        background-color: #e7e7e7;
        color: #2e333f;
    }
.edtdel {
    position: absolute !important;
    width: 100px !important;
    z-index: 9999 !important;
    padding: 0px 0px;
    top: 34px !important;
    right: 15px !important;
    font-size: 14px;
    border: 1px solid #ccc;
    border-radius: 2px;
}
.edt {
    background-color: #ffffff;
    color: #000;
    transition: .3s;
    padding: 5px 10px;
    width: 100%;
    display: inline-block;
}
 .edt:hover{
    font-size:14px;
     padding: 5px 10px;
     background-color: #007afd ;
    color: #ffffff;
    width: 100%;
    display: inline-block;
}

.del {
    background-color: #ffffff;
    color: #000;
    transition: .3s;
    padding: 5px 10px;
    width: 100%;
    display: inline-block;
}
 .del:hover{
    font-size:14px;
     padding: 5px 10px;
     background-color: red ;
    color: #ffffff;
    width: 100%;
    display: inline-block;
}
.UnderBox-Shadow {
    box-shadow: rgba(33, 35, 38, 0.1) 0px 10px 10px -10px;
    width: 100%;
    padding: 5px 0px !important;
    padding-top: 0px !important;
    background-color: #fff;
}
.FnFItem {
    display: inline-flex;
    align-items: center;
    padding-bottom: 0px;
    width: 100%;
    justify-content: center;
}

.FnFItem h3 {
    font-size: 17px !important;
    font-weight: 300;
}
.FnFItem span {
    font-size: 50px;
    color: red;
    font-weight: bold;
}

.confirmation_btn {
    width: 100%;
    display: inline-flex;
    justify-content: flex-end;
}
.both_ConfrmBTN{
    margin-right: 10px;
}

.FnFimg img {
    width: 100px;
    border-radius: 34px;
    padding: 0px 0px;
    margin-bottom: 0px;
}
.both_ConfrmBTN button {
    width: 100%;
    margin: 15px 0px 0px 0px !important;
}
.modal-fullscreen {
    width: 100vw !important;
    max-width: none !important;
}
 
.fixed_props {
    width: 100%;
    box-shadow: rgba(14, 30, 37, 0.12) 0px 1px 0px 0px, rgba(14, 30, 37, 0.32) 0px 5px 16px 0px;
    padding: 15px 0px 15px 0px;
    justify-content: end;
    display: inline-flex;
}
.createClr {
    background-color: #00afef !important;
    border: none !important;
}

.fixed_props button {
    width: 120px;
    margin-right: 15px !important;
}

.modal-body{
    padding: 40px 15px !important;
    background-color: #f7f7f7;
}
.whole_bColor {
    background-color: #f7f7f7 !important;
 
}
.fx_head{
    background-color: #2e333f !important;
  }
  .navbar-light .navbar-nav .nav-link {
    color: #ffffff !important;
}
.fam_del{
   transition:.3s !important; 
}
.fam_del:hover{
    background-color: red !important;
    border: none;
 }
 .fam_vehicleBtn{
display: inline-flex;
 }
 .fam_vehicleBtnicon {
    border-bottom: 1px solid lightslategray;
    border-radius: 5px;
    background-color: #ffffff;
    padding: 25px 30px;
    margin-right: 30px;
    font-size: 30px;
}