.NoticeFeild{

}
.NoticeFeild h1 {
    font-size: 13px !important;
    font-weight: 500;
    line-height: 23px !important;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-bottom: 0px;
}
.NoticeFeild h2 {
    margin-bottom: 0px;
    font-size: 12px !important;
    font-weight: 500;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    line-height: 14px !important;
}
.NoticeFeild h4 {
    font-size: 11px !important;
    margin-bottom: 0px;
    font-weight: 400;
    margin-right: 5px;
 
}
 
.imp_high {
    position: absolute;
    top: 0px;
    left: -17px;
    padding: 2px 1px;
    border-radius: 5px;
    background-color: #cb2525;
    color: #ffffff;
    font-size: 10px;
    letter-spacing: .5px;
    height: 85px;
    width: 1px;
}
 .imp_med {
    position: absolute;
    top: 0px;
    left: -17px;
    padding: 2px 1px;
    border-radius: 5px;
    background-color: rgb(226, 163, 45);
    color: #ffffff;
    font-size: 10px;
    letter-spacing: .5px;
    height: 85px;
    width: 1px;
}
  .imp_Low {
    position: absolute;
    top: 0px;
    left: -17px;
    padding: 2px 1px;
    border-radius: 5px;
    background-color: rgb(33, 153, 33);
    color: #ffffff;
    font-size: 10px;
    letter-spacing: .5px;
    height: 85px;
    width: 1px;
}
.onboardFlex {
    display: inline-flex;
    line-height: 0;
}
.NoticeFeild span {
    font-size: 11px;
    font-weight: 600;
    line-height: 22px;
    overflow: hidden;
    text-overflow: ellipsis;
 
}
.onboardFont {
    position: relative !important;
    right: 0px;
    top: 0px;
}
.onboardFont img {
    width: 16px;
    position: absolute;
 
    right: 0px;
    top: -20px;
}

.editNotice {
    padding: 5px 5px;
    border-radius: 22px;
    color: dimgray;
    margin-right: 0px;
    font-size: 12px;
}
.deleteNotice{
    padding: 5px 5px;
    border-radius: 22px;
    color:dimgray;
    margin-right: 0px;
    font-size: 12px;
}
.OneTile {
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    background: #ffffff;
    padding: 5px 10px 5px 30px;
    border-radius: 5px;
    width: 229px;
    margin: 0px 21px 15px 0px;
}
.ex_feild{
    position:relative;
    top:0;
    left:0;
}
.flex_ptrn {
    display: flex;
    margin-top: 15px;
    float: right;
}
.clrptrn {
    display: flex;
    line-height: 9px;
    margin: 0px 8px;
}
 
.clrptrn span {
    font-size: 12px !important;
    display: inline-flex;
    margin-bottom: 0px;
    font-weight: 400;
    margin-left: 5px;
    letter-spacing: .5px;
}
    .red {
        background-color: red;
        border-radius: 50%;
        width: 8px;
        height: 8px;
    }
.orange{ 
    background-color: orange;
    border-radius: 50%;
    width: 8px;
    height: 8px;
}
.green{ 
    background-color: green;
    border-radius: 50%;
    width: 8px;
    height: 8px;
}
.border_areaFullwdth {
    border-radius: 5px;
    background-color: #2e333f;
    justify-content: center;
    display: inline-flex;
    align-items: center;
    text-align: center;
    height:500px;
    margin:15px 0px;
 
}
.border_areaFullwdth .form-group img {
    width: 60px;
    margin-bottom: 12px;
}
.uploadedfilesection {
    border-left: 1px solid darkgray; 
    padding: 0px 0px 0px 10px;
    background-color: #ffffff;
    overflow: scroll;
    height: 488px;
    overflow-x: hidden;
}
  .uploadedfilesection span {
 
    font-size: 12px;
 
}  
.uploadedfilesection ul {
    padding:0px;
    list-style: none;
}
.uploadedfilesection ul li {
    border: 1px solid lightgray;
    border-radius: 3px;
    padding: 5px 7px;
    margin-bottom: 15px;
}
.danger{
    color:rgb(255,0,0);
}
.RelativeDel{
    position: relative;
    top: 0px;
    right: 0;
}
.deleteupload {
    position: absolute;
    top: -15px;
    background-color: red;
    color: #ffffff;
    padding: 2px 3px;
    font-size: 12px;
    border-radius: 50%;
    right: -7px;
}
.border_areaFullwdth h6 {
    color: #ffffff;
    display: inline-flex;
    margin-left: 10px;
}

.absolute_change{
     
    position: absolute;
    bottom: 5px;
    left: 12px;
    width: 100%; 
    text-align: center;
    align-items: center;
    /* display: block; */
    justify-content: center;
 
}
.jodit-status-bar a.jodit-status-bar-link{
display:none !important;
}
.jodit-wysiwyg {
    min-height: 247px !important;
    overflow: scroll !important;
    overflow-x: hidden !important;
    height: 0px !important;
}
.jodit-ui-group:last-child{
    display:none;
}

.joditEdit{
    display: none !important;
}

.modalImageheader {
    width: 100%;
    position: absolute;
    background-color: #fff;
    border-bottom: 1px solid #dadada;
    margin-left: -10px;
    padding-left: 13px;
    padding-bottom: 0px;
    display: inline-block;
    font-size: 12px;
    margin-bottom: 0px;
    margin-right: 10px;
    line-height: 44px;
}
.notice-comment {
    border: none !important;
    margin-right: 10px !important;
}
.Notice_modal_comment {
    display: inline-flex;
    justify-content: space-between;
    width: 100%;
    border-top: 1px solid #f1f1f1;
}
.comment_btns {
    display: inline-flex;
    align-items: center;
    background-color: #f7f7f7;
    padding: 5px 0px 5px 20px;
    justify-content: flex-end;
}
.cmt-btn{
    display:inline-flex;
    width: 170px;
}
.cmt-font{
    padding: 0px 10px 0px 10px;
    align-items: center;
    display: grid;   
}
.comment_Sec{
    width: 100%;
}